/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Skeleton, Stack, Tooltip } from '@mui/material'
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import axiosInstance from '../../axios/axiosInstance';
import { setAlertDetails } from '../../redux/slices/AlertDetailsSlice';
import VerifiedIcon from "@mui/icons-material/Verified";
import { useTheme } from "@mui/material/styles";
import AvatarUI from './AvatarUI';
import { NotificationContext } from '../../context';
export const Ongoing = () => {
    const theme = useTheme();
    const { handleUpdateCount } = useContext(NotificationContext);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [Ongoings, setOngoings] = useState([])
    const [hiddenLoadMore, setHiddenLoadMore] = useState(false);
    const [updateNotification, setUpdateNotification] = useState(false)
    let [page, setPage] = useState(1);
    let limit = 10;
    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };
    // set notification as read 
    const setNotificationAsRead = (notificationId) => {
        axiosInstance
            .patch(`/notifications/markAsRead`, {
                ids: [notificationId]
            })
            .then((res) => {
                setAlertDetailsToRedux("success", res.data.message)
                handleUpdateCount(); // update notification count
                setUpdateNotification(!updateNotification)// update notification
            }).catch((err) => {
                setAlertDetailsToRedux("error", err.response.data.error)
            });
    };

    // fetch  notifications
    useEffect(() => {
        if (page === 1 && Ongoings.length === 0 ) { // this to prevent loading to show when fetching more notifications
            setLoading(true)
        }
        const getNotifications = (type) => {
            axiosInstance
                .get(`/notifications?type=${type}&isRead=${false}&page=${page}&limit=${limit}`
                )
                .then((res) => {
                    if (res.data.notifications.length === 0) {
                        setHiddenLoadMore(true);
                    }
                    setOngoings((prev) => [...prev, ...res.data.notifications]);
                }).finally(() => {
                    setLoading(false)
                })
        };
        getNotifications("transaction paid");

    }, [limit, page])
      // handle update notification when notification is updated
   useEffect(() => {
    if (Ongoings.length === 0) return;
    axiosInstance.get(`/notifications?type=transaction paid&isRead=${false}&page=${1}&limit=${limit}`).then((res) => {
        setOngoings(res.data.notifications)
        if (res.data.notifications.length === 0) {
            setHiddenLoadMore(true);
        }
    })
}, [ updateNotification])
    // fetch more notification
    const fetchMoreNotification = () => {
        setPage(page + 1);
    }
    return (

        <Stack spacing={1}>
            {

                loading && (
                    <Stack spacing={2}>
                        {
                            [1, 2, 3, 4].map((item) => {
                                return (
                                    <Skeleton key={item} variant="rectangular" height={100} sx={{ borderRadius: "5px" }} />
                                )
                            })
                        }
                    </Stack>
                )

            }
            {Ongoings.map((ongoing) => {
                return (
                    <Stack
                        key={ongoing._id}
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            p: 2,
                            position: "relative",
                        }}
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                cursor: "pointer",
                                backgroundColor: "transparent !important"
                            }}
                            onClick={() => setNotificationAsRead(ongoing?._id)}
                        >
                            <Tooltip title="Set As readed">
                                <VerifiedIcon sx={{ color: "primary.main" }} />
                            </Tooltip>
                        </Box>
                       <AvatarUI sender={ongoing?.sender} />
                        <Box direction="row" sx={{ alignItems: "center", backgroundColor: "transparent !important" }}>
                            <Link to="/transactions" style={{ fontSize: "12px", marginTop: "6px", color: theme.palette.secondary.main, textDecoration: "none", fontWeight: "400" }}>
                                {
                                    ongoing?.title
                                }
                            </Link>
                        </Box>
                    </Stack>
                );
            })}
            {
                !hiddenLoadMore ? (<Button
                    variant="contained"
                    sx={{ backgroundColor: "primary.main", color: "white", mt: 2 }}
                    onClick={fetchMoreNotification}
                >
                    Load More
                </Button>) : (
                    <Box sx={{ textAlign: "center", mt: 2, color: "gray" }}>
                        No more notifications
                    </Box>
                )
            }
        </Stack>
    )
}
