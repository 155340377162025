import { Box, Button, Container, Grid, Typography } from "@mui/material"
import listStyle from "../../assets/images/listStyle.png"
import aboutReferrals from "../../assets/images/about-referrals.png"
import { Link } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
export const AboutReferrals = () => {
    const theme = useTheme()
    return (
        <Container maxWidth="xl">
            <Grid container my={8} sx={{ alignItems: "center" }}>
                <Grid item xs={12} md={8} >
                    <Typography variant="h5" component="h2" sx={{ fontFamily: "Poppins-Bold", color: "primary.main", fontSize: ["20px", "45px"] }}>
                        Refer a friend. Get 10% OFF.
                    </Typography>
                    <ul style={{
                        padding: "0px",
                        listStyleType: "none",
                    }}>
                        <li >
                            <Box sx={{ display: "flex", }}>
                                <Box>
                                    <img src={listStyle} alt="listStyle" style={{ width: "7px", height: "7px", marginRight: "10px" }} />
                                </Box>
                                <Typography variant="body1" component="p" sx={{ color: "primary.main", fontFamily: "Poppins", fontWeight: "600", fontSize: "18px" }}>
                                    Introduce your friends to Direct Rent.
                                </Typography>
                            </Box>

                        </li>
                        <li style={{ marginTop: "15px" }}>
                            <Box sx={{ display: "flex", }}>
                                <Box>
                                    <img src={listStyle} alt="listStyle" style={{ width: "7px", height: "7px", marginRight: "10px" }} />
                                </Box>
                                <Typography variant="body1" component="p" sx={{ color: "primary.main", fontFamily: "Poppins", fontWeight: "600", fontSize: "18px" }}>
                                    Get 10% off your next purchase for every new friend
                                    <br />
                                    that joins Direct Rent.
                                </Typography>
                            </Box>
                        </li>
                    </ul>
                    <Box sx={{ my: 3 }}>
                        <Link to="/referrals" style={{ color: theme.palette.primary.main, fontWeight: "bolder", fontSize: "18px", fontFamily: "Poppins-Bold" }}>
                            Terms and conditionsapply.
                        </Link>
                    </Box>

                    <Button variant="contained" sx={{ backgroundColor: "primary.main", color: "white", fontFamily: "Poppins-Bold", fontSize: "18px", fontWeight: "600", borderRadius: "20px", padding: "15px 70px", marginTop: "20px" }}>
                        Refer a friend
                    </Button>
                </Grid>
                <Grid item xs={12} md={4}>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <img src={aboutReferrals} alt="listStyle" style={{ width: "100%", height: "100%" }} />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}
