import { Paper } from "@mui/material";
import GoogleLoginIcon from "../../../assets/images/googleSignIn.webp";
const GoogleLoginButton = () => {
  const handleGoogleLogin = () => {
    window.location.href = `${import.meta.env.VITE_VERCEL_API_URL}/user/auth/google`;

  };

  return (
    <Paper
      onClick={handleGoogleLogin}
      style={{
        textTransform: "capitalize",
        width: "content-fit",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "white",
        alignItems: "center",
        borderRadius: "5px",
        padding: "5px 10px 5px 10px",
        border: "none",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      Google
      <img src={GoogleLoginIcon} alt="facebook login icon" loading="lazy" style={{ width: "30px", height: "30px", marginLeft: "5px" }} />
    </Paper>
  );
};

export default GoogleLoginButton;
