import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material"
import EarningImage from "../../../assets/images/earning.webp"
import { useNavigate } from "react-router-dom"
export const Earning = () => {
    const navigat = useNavigate()
    // GO TO ADD Item page 
    const GoToAddItem = ()=>{
        navigat("/add-new-product")
    }
    return (
        <Box>
            <Container >
                <Grid container spacing={5} alignItems="center" >
                    <Grid item xs={12} sm={6} >
                        <Stack spacing={2} my={5}  >
                            <Typography variant="h6" sx={{ color: "primary.main",fontWeight:"bold",fontFamily:"Poppins-Bold" }}>
                                Share your stuff, earn cash
                            </Typography>
                            <Typography variant="body1" sx={{ color: "#003846", }}>
                                Pay back your purchases by lending them to people in your area when you’re not using them.
                            </Typography>
                            <Typography variant="body1" sx={{ color: "#003846", }}>
                                Do it all worry free with verified borrowers and lender protection up to £25,000 per item.
                            </Typography>

                            <Button variant="contained" sx={{ my: "50px", borderRadius: "20px", width: " fit-content", textTransform: "capitalize", px: 5 }} onClick={GoToAddItem}>
                                Start Earning
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Box sx={{width: '100%',height:"100%"}}>
                            <img src={EarningImage} alt="Earning" loading='lazy' style={{ width: '100%',height:"100%" }} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
