import {
  DialogContent,
  DialogTitle,
  Dialog,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { setAuthUser } from "../redux/slices/AuthUser";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { setAlertDetails } from "../redux/slices/AlertDetailsSlice";
const CustomDialog = styled(Dialog)(() => ({
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.5) !important",
    backdropFilter: "blur(8px)",
  },
}));
export function LoginCallBack() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  /*   const [errorLoginMessage, setErrorLoginMessage] = useState(""); */


  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }))
  };

  // const handle save user and token to redux if user successfully login
  const handleSaveUserAndTokenToRedux = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const userId = urlParams.get('userId');
    if (token && userId) {
      dispatch(setAuthUser({ id: userId }));
      localStorage.setItem("token", token);
      navigate("/");

    } else {
      setTimeout(() => {
        navigate("/login");
        setAlertDetailsToRedux("error", "Something went wrong, please try again later")
      }, 3000);
    }

  };
  // handle open dialog
  const handleClickOpen = () => {
    setOpen(true);
  };
  // handle close dialog
  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    handleClickOpen();
    handleSaveUserAndTokenToRedux()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <Box sx={{ height: "70vh" }}>
      <CustomDialog onClose={handleClose} open={open}>
        <DialogTitle>Prepare Your Account</DialogTitle>
        <DialogContent>

          <>
            <Typography variant="body1">
              Please wait a moment while we prepare your account.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <CircularProgress />

            </Box>
          </>

        </DialogContent>
      </CustomDialog>
    </Box>

  );
}
