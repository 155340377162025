import {
  Box,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
  Divider,
  Container,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import logo2 from "../../assets/images/logo2.svg";
import googleApp from "../../assets/images/googlePlay.png";
import appleApp from "../../assets/images/app-store.png"
import payment1 from "../../assets/images/payment1.svg"
import payment2 from "../../assets/images/payment2.svg"
import payment3 from "../../assets/images/payment3.svg"
import payment4 from "../../assets/images/payment4.svg"
import payment5 from "../../assets/images/payment5.svg"
export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const theme = useTheme();

  return (
    <Box sx={{ py: 4, backgroundColor: "primary.main" }}>
      <Container maxWidth="xl">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <img src={logo2} alt="logo" loading='lazy' style={{ objectFit: "contain", }} />
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={3} pt={4}>
              <Grid item xs={6} md={3} >
                <Typography variant="body1" sx={{ color: "white", fontWeight: "bolder" }}>
                  Services
                </Typography>
                <Stack >
                  <List >
                    <ListItem sx={{ paddingLeft: "0px" }}>
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.main,

                        }}
                      >
                        About us
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingLeft: "0px" }}>
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.main,
                         
                        }}
                      >
                        How it works
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingLeft: "0px" }}>
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.main,
                         
                        }}
                      >
                        Browse
                      </Link>
                    </ListItem>
                  </List>
                </Stack>
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={{ color: "white", fontWeight: "bolder" }}>
                  Contact
                </Typography>
                <Stack spacing={2}>
                  <List>
                    <ListItem sx={{ paddingLeft: "0px" }}>
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.main,
                        
                        }}
                      >
                        Email
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingLeft: "0px" }}>
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.main,
                         
                        }}
                      >
                        Facebook
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingLeft: "0px" }}>
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.main,
                        
                        }}
                      >
                        Instagram
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingLeft: "0px" }}>
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.main,
                         
                        }}
                      >
                        Twitter
                      </Link>
                    </ListItem>
                  </List>
                </Stack>
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={{ color: "white", fontWeight: "bolder" }}>
                  Legal
                </Typography>
                <Stack spacing={2}>
                  <List>
                    <ListItem sx={{ paddingLeft: "0px" }}>
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.main,
                       
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingLeft: "0px" }}>
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.main,
                         
                        }}
                      >
                        Terms of Service
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingLeft: "0px" }}>
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.main,
                      
                        }}
                      >
                        Guarantee Policy
                      </Link>
                    </ListItem>
                  </List>
                </Stack>
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography variant="body1" sx={{ color: "white", fontWeight: "bolder" }}>
                  Get Our app
                </Typography>
                <Stack spacing={1} mt={2}>
                  <Link to="/">
                    <img src={googleApp} alt="google app" loading='lazy' style={{ width: '120px', height: "100%", }} />
                  </Link>
                  <Link to="/">
                    <img src={appleApp} alt="apple app" loading='lazy' style={{ width: '120px', height: "100%" }} />
                  </Link>

                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Stack mt={5}>
          <Divider sx={{ backgroundColor: "white" }} />
        </Stack>
        <Stack sx={{ mt: 2, justifyContent: "space-between", flexDirection: ["column", "row"] }}>

          <Typography variant="body2" color="text.main" align="center" sx={{ order: [2, 1] }}>
            © {currentYear}{" "}
            <Link to="/" style={{ color: theme.palette.text.main }}>
              Direct Rent
            </Link>{" "}
            . All rights reserved.
          </Typography>
          <Stack direction="row" spacing={1} justifyContent="center" sx={{ order: [1, 2], mb: [2, 0] }}>
            <Box sx={{ backgroundColor: "text.main", p: "1px", width: "40px", height: "25px", borderRadius: "5px" }}>
              <img src={payment1} alt="payment" loading='lazy' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </Box>
            <Box sx={{ backgroundColor: "text.main", p: "1px", width: "40px", height: "25px", borderRadius: "5px" }}>
              <img src={payment2} alt="payment" loading='lazy' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </Box>
            <Box sx={{ backgroundColor: "text.main", p: "1px", width: "40px", height: "25px", borderRadius: "5px" }}>
              <img src={payment3} alt="payment" loading='lazy' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </Box>
            <Box sx={{ backgroundColor: "text.main", p: "1px", width: "40px", height: "25px", borderRadius: "5px" }}>
              <img src={payment4} alt="payment" loading='lazy' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </Box>
            <Box sx={{ backgroundColor: "text.main", p: "1px", width: "40px", height: "25px", borderRadius: "5px" }}>
              <img src={payment5} alt="payment" loading='lazy' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
