import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { SearchByName } from "../../components/admin/user/SearchByName";
import { UserContext } from "../../context";
import  GetAllUsers  from "../../components/admin/user/GetAllUsers";
export const AllUsers = () => {
  const [searchValue, setSearchValue] = useState("");
  // Change the search value in the context
  const changeSearchValue = (value) => {
    setSearchValue(value);
  };

  return (
    <Box>
      <Typography
        variant="h5"
        component="h4"
        sx={{ my: 3, color: "text.secoundry", fontWeight: "bold" }}
      >
        All Users
      </Typography>
      <UserContext.Provider
        value={{
          search: {
            searchValue: searchValue,
            changeSearchValue: changeSearchValue,
          },
        }}
      >
        <SearchByName />
        <GetAllUsers />
      </UserContext.Provider>
    </Box>
  );
};

