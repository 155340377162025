import {
    Box,
    Button,
    Container,
    Typography,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { setAlertDetails } from "../redux/slices/AlertDetailsSlice";
import axiosInstance from "../axios/axiosInstance";
import { ProductFields } from "../components/edit-product/ProductFields";

import { EditImages } from "../components/edit-product/EditImages";
export const EditProducts = () => {
    const [categories, setCategories] = useState([]);
    const { id } = useParams();
    const dispatch = useDispatch();
    const [editValues, setEditValue] = useState({
        name: "",
        price: "",
        description: "",
        per: "",
        condition: "",
        category: "",
        available: "",
        pictures: [],

    });
    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }));
    };
    useEffect(() => {
        // fetch product by id
        axiosInstance
            .get(
                `/products/${id}`
            )
            .then((res) => {
                setEditValue({
                    name: res.data.product.name,
                    price: res.data.product.price,
                    description: res.data.product.description,
                    per: res.data.product.per,
                    condition: res.data.product.condition,
                    category: res.data.product.category,
                    available: res.data.product.available,
                    pictures: res.data.product.pictures,
                });
            })
            .catch((err) => {
                setAlertDetailsToRedux("error", err.response.data.error);

            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // get all categories from server
    useEffect(() => {
        axiosInstance.get("/products/categories").then((res) => {
            setCategories(res.data);
        }).catch(() => {
            setCategories([]);
        });

    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditValue({ ...editValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // validate form fields before submitting to backend server for update product by id request 
        if (editValues.name === "" || editValues.per === "" || editValues.price === "" || editValues.description === "" || editValues.condition === "" || editValues.category === "" || editValues.available === "" || editValues.region === "") {
            setAlertDetailsToRedux("error", "Please fill all fields");

            return;
        }
        axiosInstance
            .put(
                `/products/${id}`,
                editValues

            )
            .then(() => {
                setAlertDetailsToRedux("success", "Product updated successfully");

            })
            .catch((err) => {
                setAlertDetailsToRedux("error", err.response.data.error);

            });
    };
    return (
        <Box sx={{ py: 5 }}>
            <Container maxWidth="md">
                <Typography variant="h4" sx={{ color: "primary.main", fontWeight: "bold",textAlign:"center",my:5 }}>
                    Edit  Product
                </Typography>
                <form>
                    <ProductFields editValues={editValues} handleChange={handleChange} categories={categories} />
                    <br />
                    <Button variant="contained" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </form>
                <Typography variant="h6" sx={{ mt: 3, color: "secondary.main", my: 3, fontWeight: "bold" }}>
                    Edit Images
                </Typography>

                <EditImages />
            </Container>

        </Box>
    );
};

