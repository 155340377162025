import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import axiosInstance from "../../axios/axiosInstance";
import { GeneralSkeletonLoading } from "../general/loading/GeneralSkeletonLoading";
import { useTheme } from "@mui/material/styles";
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

export const Items = ({ products, loading }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };

  const handleEdit = (id) => {
    navigate(`/editProduct/${id}`);
  };

  const handleDelete = (id) => {
    axiosInstance.delete(`/products/${id}`)
      .then(() => {
        setAlertDetailsToRedux("success", "Product deleted successfully");
      }).catch((err) => {
        if (err) {
          setAlertDetailsToRedux("error", err.response.data.error);
        }
      })
  };

  return (
    <TableContainer component={Box} sx={{ borderRadius: "10px", borderBottom: "1px solid rgb(247,247,247)", width: "100%" }}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "rgb(209,209,209)", }}>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>Name</TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>Updated</TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>Created</TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>Status</TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>Category</TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>edit</TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ width: "100%" }}>
          {
            loading ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Stack spacing={2} sx={{ width: "100%" }}>
                    <GeneralSkeletonLoading number={10} />
                  </Stack>
                </TableCell>
              </TableRow>
            ) : (
              products.length > 0 ? (
                products.map((product, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" sx={{ color: "primary.main" }}>
                      {
                        <Link to={`/product-detail/${product?._id}`} style={{ textDecoration: "none", color: theme.palette.primary.main, }}>
                          {product?.name}
                        </Link>
                      }
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"] }}>
                      {formatDate(product.updatedAt)}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"] }}>
                      {formatDate(product.createdAt)}
                    </TableCell>
                    <TableCell align="center" sx={{ color: product.available ? "green" : "red", fontSize: ["13px", "16px", "18px"] }}>
                      {product.available ? "Open" : "Close"}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: ["13px", "16px", "16px"] }}>{product.category}</TableCell>
                    <TableCell align="center">
                      <EditIcon
                        sx={{ color: "primary.main", cursor: "pointer", fontSize: ["16px", "16px", "18px"] }}
                        onClick={() => handleEdit(product._id)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <DeleteIcon
                        sx={{ color: "primary.main", cursor: "pointer", fontSize: ["16px", "16px", "18px"] }}
                        onClick={() => handleDelete(product._id)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Box
                      sx={{ textAlign: "center", color: "primary.main", width: "100%", fontWeight: "bold" }}
                    >
                      No Products Found
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
