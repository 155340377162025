
import Skeleton from '@mui/material/Skeleton';
export const GeneralSkeletonLoading = ({number}) => {
   // creat array size of number
    const numberOfBoxes = Array.from({ length: number }, (_, i) => i + 1);

    return (
        <>
            {
                numberOfBoxes.map((box) => {
                    return (

                        <Skeleton key={box} variant="text" sx={{ width: "100%",height:"40px" }} />
                    )
                }
                )
            }
        </>

    );
}