import { Box, Button, Container, FormControl, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from "@mui/material"
import { Form, Formik, Field } from "formik"
import { ResetPasswordValidationSchema } from "../validation/validationSchemas"
import { useDispatch } from "react-redux"
import { setAlertDetails } from "../redux/slices/AlertDetailsSlice"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import axiosInstance from "../axios/axiosInstance"
import { styled } from "@mui/material/styles";
// eslint-disable-next-line react-refresh/only-export-components
const CustomTextField = styled(TextField)({
  color: "secondary.main",
  '& .MuiOutlinedInput-root': { // To remove outline
    borderRadius: "10px",
    backgroundColor: "white",
    color: "secondary.main",
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiFormLabel-root': { // To change label color
    color: 'black', // Replace 'desiredColor' with the color you want
  },
});
export const ResetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    resetToken: "",
    newPassword: "",

  }
  // handle navigate to LOGIN page
  const handleNavigateToLoginPage = () => {
    navigate("/login")
  }
  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }))
  };
  const handleSubmit = (values) => {
    setLoading(true)
    axiosInstance.post("/user/reset-password", values
    ).then(() => {
      setAlertDetailsToRedux("success", "Password Reset Successfully")

      handleNavigateToLoginPage()
      setLoading(false)
    }).catch(err => {
      setAlertDetailsToRedux("error", err.response.data.error)

      setLoading(false)
    })

  }
  // handle toggle password

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const getPasswordInputAdornment = () => {
    return (
      <InputAdornment position="end">
        <IconButton onClick={handleTogglePassword} edge="end">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <Box sx={{ py: 8 }}>
      <Typography variant="h4" align="center" sx={{ mt: 2, color: "primary.main", fontWeight: "bold" }}>
        Reset Password
      </Typography>

      <Container maxWidth="sm" sx={{ height: "50vh", mt: 4 }}>
        <Paper elevation={3} sx={{ p: 4, backgroundColor: "secondary.main", borderRadius: "10px" }}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={ResetPasswordValidationSchema}
          >
            {({ errors, touched }) => {
              return (
                <Form>
                  <Stack spacing={2} sx={{ py: 2 }}>
                    <Field
                      as={CustomTextField}
                      fullWidth
                      id="resetToken"
                      name="resetToken"
                      placeholder="Enter Your Code"
                      variant="outlined"
                      error={touched.resetToken && Boolean(errors.resetToken)}
                      helperText={touched.resetToken && errors.resetToken}

                    />
                    <FormControl fullWidth>
                      <Field
                        as={CustomTextField}
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        placeholder="Enter Your New Password"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        error={touched.newPassword && Boolean(errors.newPassword)}
                        helperText={touched.newPassword && errors.newPassword}
                        InputProps={{
                          endAdornment:
                            getPasswordInputAdornment(handleTogglePassword),
                        }}
                      />
                    </FormControl>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ borderRadius: "10px" }}
                    >
                      {loading ? "please wait..." : "Reset Password"}
                    </Button>
                  </Stack>
                </Form>
              )
            }}
          </Formik>
        </Paper>
      </Container>
    </Box>
  )
}
