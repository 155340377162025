import { useEffect, useState } from "react";
import {
  Box,
  ListItem,
  ListItemButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { setActiveCategory } from "../../../redux/slices/filterSlice.js"; 
import axiosInstance from "../../../axios/axiosInstance.js";

 const CatigoryListOnMobile = () => {
  const them = useTheme()
  const dispatch = useDispatch();
  const activeCategory = useSelector((state) => state.filter.filterKeys.category);
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const handleItemClick = (category) => {
    dispatch(setActiveCategory(category === "ALL CATEGORIES" ? null : category));
    setAccordionOpen(false); // Close accordion on item click
  };

    // get all categories from server
    useEffect(() => {
      axiosInstance.get("/products/categories").then((res) => {
       setCategories(res.data);
      }).catch(() => {
       setCategories([]);
      });
     
     }, []);

  function renderRow() {
    return (
      <>
        <ListItem
          component="div"
          sx={{
            ":hover": {
              backgroundColor: "primary.main",
            },
            backgroundColor: activeCategory === "ALL CATEGORIES" ? "primary.main" : "transparent",
            px: 1,
          }}
        >
          <ListItemButton

            onClick={() => handleItemClick("ALL CATEGORIES")}
            sx={{
              fontSize: "14px",
              backgroundColor: activeCategory == null ? them.palette.primary.main : "",
              color: activeCategory == null ? "white" : "",
            }}
          >
            ALL CATEGORIES
          </ListItemButton>
        </ListItem>
        {categories.map((category) => (
          <ListItem
            component="div"
            key={category._id}
            sx={{
              ":hover": {
                backgroundColor: "primary.main",
              },
              // if activecatigory change background
              backgroundColor: activeCategory == category._id ? them.palette.primary.main : "",
              color: activeCategory == category._id ? "white" : "secondary.main",
              px: 1,
            }}
            onClick={() => handleItemClick(category._id)}
          >
            <ListItemButton sx={{ fontSize: "14px" }}>
              {category.name}
            </ListItemButton>
          </ListItem>
        ))}
      </>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Accordion expanded={isAccordionOpen} onChange={() => {
        setAccordionOpen(!isAccordionOpen)
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Select Category</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>{renderRow()}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CatigoryListOnMobile;