import { useEffect, useState } from "react";
import {
  Button,
  Stack,
  TextField,
  CircularProgress,
  Container,
  Typography,
  Box,
  Grid,
  Paper,

} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { setAlertDetails } from "../redux/slices/AlertDetailsSlice";
import { reviewValidationSchema } from "../validation/validationSchemas";
import { useParams, useNavigate } from "react-router-dom";
import { getUser } from "../redux/slices/AuthUser";
import axiosInstance from "../axios/axiosInstance";
import { SkeletonInCommentAndReviews } from "../components/general/loading/SkeletonInCommentAndReviews";
export const AddReviews = () => {
  const { productID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector(getUser)?.id; // get user from redux
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);

  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }))
  };

  // fetch product details
  useEffect(() => {
    setLoading(true)
    const fetchProduct = async () => {
      axiosInstance
        .get(
          `/products/${productID}`
        )
        .then((res) => {
          setProduct(res.data.product)

        }).catch(() => {
          setProduct(null);

        }).finally(() => {
          setLoading(false)
        })
    };
    fetchProduct();
  }, [productID]);
  const initialValues = {
    review: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    const allValues = {
      review: values.review,
      userID: userId,
      productID: productID,
    };

    axiosInstance
      .post(
        "/reviews",
        allValues,
      )
      .then(() => {
        setAlertDetailsToRedux("success", "Review Added Successfully!");
        setLoading(false);
        resetForm();
        navigate(`/product-detail/${productID}`);
      })
      .catch((err) => {
        setAlertDetailsToRedux("error", err.response.data.error);
        setLoading(false);
      });
  };

  return (
    <Box sx={{ pt: 8 }}>
      <Typography
        variant="h4"
        sx={{ color: "primary.main", fontWeight: "bold", textAlign: "center", mb: 8 }}
      >
        Review Product
      </Typography>
      <Box sx={{ backgroundColor: "text.main", py: 5 }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7}>
              {
                loading ? (
                  <SkeletonInCommentAndReviews />
                ) : (
                  <Grid container  spacing={3} >
                  <Grid item xs={12} md={4}>
                    <Paper sx={{ width: "100%", height: "150px", borderRadius: "15px" }}>
                      <img src={product ? product.pictures[0] : ""} alt="product" style={{ width: "100%", height: "100%", borderRadius: "15px",objectFit:"contain" }} />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6" sx={{ color: "secondary.main", fontFamily:"Poppins-Bold" }}>
                        {product ? product.name : ""}
                      </Typography>
                      <Typography variant="body1" sx={{ color: "text.secondary" }}>
                        {product ? product.description : ""}
                      </Typography>
                    </Box>
                  </Grid>

                  </Grid>
                )
              }
            </Grid>
            <Grid item xs={12} sm={5}>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={reviewValidationSchema}
              >
                {() => (
                  <Form>
                    <Stack spacing={3}>
                      <Box sx={{ backgroundColor: "#F4EFEA", p: 3, borderRadius: "10px" }}>
                        <Typography variant="h6" sx={{ color: "secondary.main", fontFamily:"Poppins-Bold", pb: 2 }}>
                          Write Review
                        </Typography>
                        <Field
                          name="review"
                          as={TextField}
                          fullWidth
                          multiline
                          rows={5}
                          placeholder="Add Review"
                          sx={{
                            '& .MuiOutlinedInput-root': { // To remove outline
                              backgroundColor: "white",
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                                borderRadius: "20px", // To remove round shape
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                              },
                            },
                          }}
                        />
                        <ErrorMessage
                          name="review"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Box>
                      <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{ p: 1 }}
                      >
                        {loading ? <CircularProgress size={24} /> : "Add Review"}
                      </Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>

  );
};

