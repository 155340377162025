import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const SearchSlice = createSlice({
  name: "Search",
  initialState: { status: false, historySearch: [] },
  reducers: {
    setSearchStatus: (state, { payload }) => {
      state.status = payload;
    },
    setHistorySearch: (state, { payload }) => {
      state.historySearch.unshift(payload);
    },
    deletetItemFromHistory: (state, { payload }) => {
        state.historySearch.splice(payload, 1);
    },
    clearHistorySearch: (state) => {
      state.historySearch = [];
    },
  },
});
const persistConfig = {
  key: "search",
  storage,
};

export const { setSearchStatus, setHistorySearch,deletetItemFromHistory,clearHistorySearch } = SearchSlice.actions;
const persistedSearchReducer = persistReducer(
  persistConfig,
  SearchSlice.reducer
);
export default persistedSearchReducer;
