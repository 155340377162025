import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CommentIcon from '@mui/icons-material/Comment';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { getUser } from "../../redux/slices/AuthUser";
// import mui components
import {
  Badge,
  Drawer,
  Box,
  Typography,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
// import icons
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PendingIcon from "@mui/icons-material/Pending";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
// import components
import { Unread } from "./Unread";
import { Approved } from "./Approved";
import { Pendding } from "./Pendding";
import { Rejected } from "./Rejected";
import { Ongoing } from "./Ongoing";
import { WatchList } from "./WatchList";
import axiosInstance from "../../axios/axiosInstance";
import { Comments } from "./Comments";
import { useSelector } from "react-redux";
import { NotificationContext } from "../../context";
export const Notifications = () => {
  const AuthUser = useSelector(getUser);
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [totalCountNotification, setTotalCountNotification] = useState(0);
  const [allNotificationsCount, setAllNotificationsCount] = useState([]);
  const [updateCount, setUpdateCount] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  // handle update count state
  const handleUpdateCount = () => {
    setUpdateCount(!updateCount);
  }

  // Handler function for tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Open drawer
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  // Close the drawer
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  // Fetch notifications count
  const getNotificationsCount = () => {
    axiosInstance.get("/notifications/unread-count").then((res) => {
      setTotalCountNotification(res.data.totalUnreadCount)// set total count of unread notifications
      setAllNotificationsCount(res.data.unreadCounts)
    });
  }

  useEffect(() => {
    // Fetch notifications count when the component mounts
    getNotificationsCount();
    // check watchlist notification
    const checkWatchListNotification = () => {
      axiosInstance.get(`/user/check-watchlist-availability/${AuthUser?.id}`)
    };
    checkWatchListNotification();
  }, [AuthUser?.id, updateCount]);

  // Close the drawer when the user navigates to a different page
  useEffect(() => {
    handleCloseDrawer();
  }, [navigate, location.pathname]);

  // Fetch notifications from the server every 1 minute
  useEffect(() => {
    // update notifications count every 5 minutes
    const intervalId = setInterval(() => {
      getNotificationsCount();
    }, 1 * 60 * 1000);

    // Cleanup function to clear the interval
    return () => {
      clearInterval(intervalId);
    };

  }, []);
  return (
    <NotificationContext.Provider value={{handleUpdateCount:handleUpdateCount}}>
      <Badge
        badgeContent={
          totalCountNotification
        }
        onClick={handleOpenDrawer}
        sx={{ cursor: "pointer", color: "primary.main" }}
      >
        <CircleNotificationsIcon sx={{ fontSize: "35px" }} />
      </Badge>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={handleCloseDrawer}
        sx={{

          "& .MuiPaper-root ": {
            borderRadius: "0px 50px 50px 0px",
            backgroundColor: "text.main",

            "& .MuiBox-root": {
              backgroundColor: "text.main",
            },

          }
        }}
      >
        <Box
          sx={{
            maxWidth: ["300px", "350px"],
            minWidth: "300px",
            height: "auto",
            p: 2,
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: '50px',
              backgroundColor: 'rgb(0 0 0 / 0%)',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '50px',
              backgroundColor: 'secondary.main',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'secondary.main',
            },


          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ display: "flex", alignItems: "center" }}
          >
            Notifications{" "}
            <CircleNotificationsIcon sx={{ ml: 2, color: "primary.main" }} />
          </Typography>

          <Box sx={{ py: 3 }}>
            <Tabs value={value} onChange={handleChange} sx={{ pb: 3 }}>
              <Tab
                sx={{
                  minWidth: "40px",
                  maxWidth: "40px",
                }}
                label={
                  <Badge
                    badgeContent={
                      allNotificationsCount.find((item) => item.type === "message")?.count
                    }
                    onClick={handleOpenDrawer}
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                    }}
                  >
                    <Tooltip title="Un Read Messages" placement="top">
                      <MarkAsUnreadIcon sx={{ color: "text.secondary" }} />
                    </Tooltip>
                  </Badge>
                }
              />
              <Tab
                sx={{
                  minWidth: "40px",
                  maxWidth: "40px",
                }}
                label={
                  <Badge
                    badgeContent={
                      allNotificationsCount.find((item) => item.type === "transaction rejected")?.count
                    }
                    onClick={handleOpenDrawer}
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                    }}
                  >
                    <Tooltip title="Rejected Request" placement="top">
                      <CancelPresentationIcon
                        sx={{ color: "text.secondary" }}
                      />
                    </Tooltip>
                  </Badge>
                }
              />
              <Tab
                sx={{
                  minWidth: "40px",
                  maxWidth: "40px",
                }}
                label={
                  <Badge
                    badgeContent={
                      allNotificationsCount.find((item) => item.type === "transaction approved")?.count
                    }
                    onClick={handleOpenDrawer}
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                    }}
                  >
                    <Tooltip title="Accepted Requests" placement="top">
                      <CheckBoxIcon sx={{ color: "text.secondary" }} />
                    </Tooltip>
                  </Badge>
                }
              />
              <Tab
                sx={{
                  minWidth: "40px",
                  maxWidth: "40px",
                }}
                label={
                  <Badge
                    badgeContent={
                      allNotificationsCount.find((item) => item.type === "transaction created")?.count
                    }
                    onClick={handleOpenDrawer}
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                    }}
                  >
                    <Tooltip title="Pendding Requests" placement="top">
                      <PendingIcon sx={{ color: "text.secondary" }} />
                    </Tooltip>
                  </Badge>
                }
              />
              <Tab
                sx={{
                  minWidth: "40px",
                  maxWidth: "40px",
                }}
                label={
                  <Badge
                    badgeContent={
                      allNotificationsCount.find((item) => item.type === "transaction paid")?.count
                    }
                    onClick={handleOpenDrawer}
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                    }}
                  >
                    <Tooltip title="Ongoing Order" placement="top">
                      <ConnectWithoutContactIcon
                        sx={{ color: "text.secondary" }}
                      />
                    </Tooltip>
                  </Badge>
                }
              />
              <Tab
                sx={{ minWidth: "40px", maxWidth: "40px" }}
                label={
                  <Badge
                    badgeContent={
                      allNotificationsCount.find((item) => item.type === "comment")?.count
                    }
                    onClick={handleOpenDrawer}
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                    }}
                  >
                    <Tooltip title="comments" placement="top">
                      <CommentIcon
                        sx={{ color: "text.secondary" }}
                      />
                    </Tooltip>
                  </Badge>
                }
              />
              <Tab
                sx={{ minWidth: "40px", maxWidth: "40px" }}
                label={
                  <Badge
                    badgeContent={
                      allNotificationsCount.find((item) => item.type === "product available")?.count
                    }
                    onClick={handleOpenDrawer}
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                    }}
                  >
                    <Tooltip title="watchList" placement="top">
                      <AlarmOnIcon
                        sx={{ color: "text.secondary" }}
                      />
                    </Tooltip>
                  </Badge>
                }
              />
            </Tabs>
            {value === 0 && <Unread />}
            {value === 1 && <Rejected />}
            {value === 2 && <Approved />}
            {value === 3 && <Pendding />}
            {value === 4 && <Ongoing />}
            {value === 5 && <Comments />}
            {value === 6 && <WatchList />}
          </Box>
        </Box>
      </Drawer>
    </NotificationContext.Provider>
  );
};
