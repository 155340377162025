import { Button, TableCell, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import { useNavigate } from "react-router-dom";
import { setAlertDetails } from "../../../redux/slices/AlertDetailsSlice";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import axiosInstance from "../../../axios/axiosInstance";
export const TableRowUser = ({ user }) => {
  const dispatch = useDispatch();
  const navigat = useNavigate();
  const [userdeleted, setuserdeleted] = useState(false);
  const [userblocked, setuserblocked] = useState(false);

  const handleGoToUserTransactions = (user) => {
    navigat(`/admins/user-transactions/${user._id}/${user.username}`);
  };
  const handleGoToEditUser = (user) => {
    navigat(`/admins/edit-user/${user._id}/${user.username}`);
  };
  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };

  // Delete User
  const handleDeleteUser = (id) => {
    axiosInstance
      .delete(
        `/admin/user/${id}`
      )
      .then(() => {
        setAlertDetailsToRedux("success", "User Deleted Successfully");
        setuserdeleted(true);
      })
      .catch((err) => {
        setAlertDetailsToRedux("error", err.response.data.error);

      });
  };

  // restore user
  const handleRestoreUser = (userID) => {
    axiosInstance
      .post(
        `/admin/user/${userID}/restore`,
        {}
      )
      .then(() => {
        setAlertDetailsToRedux("success", "User Restored Successfully");
        setuserdeleted(false);

      })
      .catch((err) => {
        setAlertDetailsToRedux("error", err.response.data.error);

      });
  };

  // handle block user
  const handleBlockUser = (userID) => {
    axiosInstance
      .post(
        `/admin/user/${userID}/block`,
        {}
      )
      .then(() => {
        setAlertDetailsToRedux("success", "User Blocked Successfully");
        setuserblocked(true);

      })
      .catch((err) => {
        setAlertDetailsToRedux("error", err.response.data.error);

      });
  };

  // handle unblock user
  const handleUnblockUser = (userID) => {
    axiosInstance
      .post(
        `/admin/user/${userID}/unblock`,
        {}
      )
      .then(() => {
        setAlertDetailsToRedux("success", "User Unblocked Successfully");
        setuserblocked(false);

      })
      .catch((err) => {
        setAlertDetailsToRedux("error", err.response.data.error);

      });
  };

  useEffect(() => {
    if (user.isDeleted) {
      setuserdeleted(true);
    } else {
      setuserdeleted(false);
    }

    if (user.isBlocked) {
      setuserblocked(true);
    } else {
      setuserblocked(false);
    }
  }, [user.isBlocked, user.isDeleted]);
  return (
    <TableRow
      key={user.username}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell align="center">{user.username}</TableCell>
      <TableCell align="center">{user.email}</TableCell>
      <TableCell align="center">
        {user.phone ? (
          user.phone
        ) : (
          <PhoneDisabledIcon sx={{ color: "shadow.main" }} />
        )}
      </TableCell>

      <TableCell align="center">
        <Button
          variant="text"
          size="small"
          sx={{ fontWeight: "bold", color: "primary.main" }}
          onClick={() => handleGoToUserTransactions(user)}
        >
          Transactions
        </Button>
      </TableCell>
      <TableCell align="center">
        <EditIcon
          sx={{ color: "primary.main", cursor: "pointer" }}
          onClick={() => handleGoToEditUser(user)}
        />
      </TableCell>
      <TableCell align="center">
        {userdeleted ? (
          <Button
            variant="text"
            size="small"
            sx={{
              fontWeight: "bold",
              color: "primary.main",
              px: 3,
            }}
            onClick={() => handleRestoreUser(user._id)}
          >
            <RestoreIcon sx={{ color: "primary.main", cursor: "pointer" }} />
          </Button>
        ) : (
          <Button
            variant="text"
            size="small"
            sx={{ fontWeight: "bold", color: "primary.main" }}
            onClick={() => handleDeleteUser(user._id)}
          >
            <DeleteIcon sx={{ color: "primary.main", cursor: "pointer" }} />
          </Button>
        )}
      </TableCell>
      <TableCell align="center">
        {userblocked ? (
          <Button
            variant="text"
            size="small"
            sx={{
              fontWeight: "bold",
              color: "primary.main",
              textTransform: "capitalize",
            }}
            onClick={() => handleUnblockUser(user._id)}
          >
            Unblock
          </Button>
        ) : (
          <Button
            variant="text"
            size="small"
            sx={{
              fontWeight: "bold",
              color: "primary.main",
              textTransform: "capitalize",
            }}
            onClick={() => handleBlockUser(user._id)}
          >
            Block
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
