/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import VerifiedIcon from "@mui/icons-material/Verified";
import { setAlertDetails } from '../../redux/slices/AlertDetailsSlice';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../axios/axiosInstance';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '../../context';
export const WatchList = () => {
    const { handleUpdateCount } = useContext(NotificationContext);
    const [loading, setLoading] = useState(false)
    const [watchlistNotifications, setWatchlistNotifications] = useState([])
    const [hiddenLoadMore, setHiddenLoadMore] = useState(false);
    const [updateNotification, setUpdateNotification] = useState(false)
    let [page, setPage] = useState(1);
    let limit = 10;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };
    // set notification as read 
    const setNotificationAsRead = (notificationId) => {
        axiosInstance
            .patch(`/notifications/markAsRead`, {
                ids: [notificationId]
            })
            .then((res) => {
                setAlertDetailsToRedux("success", res.data.message)
                handleUpdateCount(); // update notification count
                setUpdateNotification(!updateNotification)// update notification
            }).catch((err) => {
                setAlertDetailsToRedux("error", err.response.data.error)
            });
    };

    // handle go to product details
    const goToProductDetails = (productId) => {
        navigate(`/product-detail/${productId}`)
    }
    // fetch  notifications
    useEffect(() => {
        if (page === 1 && watchlistNotifications.length === 0) { // this to prevent loading to show when fetching more notifications
            setLoading(true)
        }
        const getNotifications = (type) => {
            axiosInstance
                .get(`/notifications?type=${type}&isRead=${false}&page=${page}&limit=${limit}`
                )
                .then((res) => {
                    if (res.data.notifications.length === 0) {
                        setHiddenLoadMore(true);
                    }
                    setWatchlistNotifications((prev) => [...prev, ...res.data.notifications]);
                }).finally(() => {
                    setLoading(false)
                });
        };
        getNotifications("product available");

    }, [limit, page])

    // handle update notification when notification is updated
    useEffect(() => {
        if(watchlistNotifications.length === 0) return; 
        axiosInstance.get(`/notifications?type=product available&isRead=${false}&page=${1}&limit=${limit}`).then((res) => {
            setWatchlistNotifications(res.data.notifications)
            if (res.data.notifications.length === 0) {
                setHiddenLoadMore(true);
            }
        })
    
    }, [updateNotification])
    // fetch more notification
    const fetchMoreNotification = () => {
        setPage(page + 1);
    }
    return (
        <Box>
            <Stack spacing={1}>
                {

                    loading && (
                        <Stack spacing={2}>
                            {
                                [1, 2, 3, 4].map((item) => {
                                    return (
                                        <Skeleton key={item} variant="rectangular" height={100} sx={{ borderRadius: "5px" }} />
                                    )
                                })
                            }
                        </Stack>
                    )

                }
                { // map through watchlist notifications
                    watchlistNotifications.map((item) => {
                        return (
                            <Box
                                key={item._id}
                                sx={{ position: "relative", p: 3, backgroundColor: "white !important", borderRadius: "10px" }}

                            >
                                <Box
                                    onClick={() => setNotificationAsRead(item?._id)}
                                    sx={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        cursor: "pointer",
                                        backgroundColor: "transparent !important"
                                    }}

                                >
                                    <Tooltip title="Set As readed">
                                        <VerifiedIcon sx={{ color: "primary.main" }} />
                                    </Tooltip>
                                </Box>
                                <Typography variant="body1" sx={{ fontSize: "14px", mt: 1 }}>
                                    {item.title}
                                </Typography>
                                <Button variant="contained" sx={{ backgroundColor: "primary.main", color: "white", mt: 1 }} onClick={() => goToProductDetails(item?.objectId)}>
                                    View Product Details
                                </Button>
                            </Box>
                        )
                    })
                }

            </Stack>
            {
                !hiddenLoadMore  ? (
                    <Box sx={{ textAlign: "center", mt: 2 }}>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "primary.main", color: "white", mt: 2 }}
                            onClick={fetchMoreNotification}
                        >
                            Load More
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{ textAlign: "center", mt: 2, color: "gray" }}>
                        No more notifications
                    </Box>
                )
            }
        </Box>
    )
}
