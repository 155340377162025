import { Box, Button, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import VerifiedIcon from "@mui/icons-material/Verified";
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import axiosInstance from '../../axios/axiosInstance';
import { setAlertDetails } from '../../redux/slices/AlertDetailsSlice';
import { useContext, useEffect, useState } from 'react';
import AvatarUI from './AvatarUI';
import { NotificationContext } from '../../context';
export const Comments = () => {
    const { handleUpdateCount } = useContext(NotificationContext);
    const [loading, setLoading] = useState(false)
    const [comments, setComments] = useState([]);
    const [hiddenLoadMore, setHiddenLoadMore] = useState(false);
    const [updateNotification, setUpdateNotification] = useState(false)
    let [page, setPage] = useState(1);
    let limit = 800;
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };

    // set notification as read 
    const setNotificationAsRead = (notificationId) => {
        axiosInstance
            .patch(`/notifications/markAsRead`, {
                ids: [notificationId]
            })
            .then((res) => {
                setAlertDetailsToRedux("success", res.data.message)
                handleUpdateCount(); // update notification count
                setUpdateNotification(!updateNotification)// update notification
            }).catch((err) => {
                setAlertDetailsToRedux("error", err.response.data.error)
            });
    };
    // handle navigation to product detail
    const handelNavigationToProduct = (objectId) => {
        navigate(`/product-detail/${objectId}`)
    };
    // fetch comments notifications
    useEffect(() => {
        if (page === 1 && comments.length === 0) { // this to prevent loading to show when fetching more notifications
            setLoading(true)
        }
        const getNotifications = (type) => {
            axiosInstance
                .get(`/notifications?type=${type}&isRead=${false}&page=${page}&limit=${limit}`
                )
                .then((res) => {
                    if (res.data.notifications.length === 0) {
                        setHiddenLoadMore(true);
                    }
                    setComments((prev) => [...prev, ...res.data.notifications]);
                }).finally(() => {
                    setLoading(false)
                })
        };
        getNotifications("comment");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, page])
    // handle update notification when notification is updated
    useEffect(() => {
        if (comments.length === 0) return;
        axiosInstance.get(`/notifications?type=comment&isRead=${false}&page=${1}&limit=${limit}`).then((res) => {
            setComments(res.data.notifications)
            if (res.data.notifications.length === 0) {
                setHiddenLoadMore(true);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNotification])
    // fetch more notification
    const fetchMoreNotification = () => {
        setPage(page + 1);
    }
    return (
        <Box>
            <Stack spacing={1}>
                {

                    loading && (
                        <Stack spacing={2}>
                            {
                                [1, 2, 3, 4].map((item) => {
                                    return (
                                        <Skeleton key={item} variant="rectangular" height={100} sx={{ borderRadius: "5px" }} />
                                    )
                                })
                            }
                        </Stack>
                    )

                }
                { // map through comments notifications
                    comments.map((item) => {
                        return (
                            <Box
                                key={item._id}
                                sx={{ position: "relative", p: 2, backgroundColor: "white !important", borderRadius: "10px" }}

                            >
                                <Box
                                    onClick={() => setNotificationAsRead(item._id)}
                                    sx={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        cursor: "pointer",
                                        backgroundColor: "transparent !important"
                                    }}

                                >
                                    <Tooltip title="Set As readed">
                                        <VerifiedIcon sx={{ color: "primary.main" }} />
                                    </Tooltip>
                                </Box>
                                <AvatarUI sender={item?.sender} />
                                <Typography
                                    sx={{ fontSize: "14px", color: theme.palette.text.primary, m2: 3 }}
                                >
                                    {item.title}
                                </Typography>

                                <Button onClick={() => handelNavigationToProduct(item?.objectId)} sx={{ mt: 1 }} variant="contained" color="primary">
                                    View Product Details
                                </Button>

                            </Box>
                        )
                    })
                }
            </Stack>
            {
                !hiddenLoadMore ? (
                    <Box sx={{ textAlign: "center", mt: 2, display: "flext", px: 1 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ backgroundColor: "primary.main", color: "white", mt: 2 }}
                            onClick={fetchMoreNotification}
                        >
                            Load More
                        </Button>
                    </Box>
                )
                    : (
                        <Box sx={{ textAlign: "center", mt: 2, color: "gray" }}>
                            No more notifications
                        </Box>
                    )
            }
        </Box>
    )
}

