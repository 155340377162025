import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import uploadImage from '../../../assets/images/uploadImg.webp';
export const ProfileImage = ({setFieldValue,values}) => {
  return (
    <Grid item xs={12}>
    <Typography variant="body1" gutterBottom component="div">
      Profile Picture
    </Typography>
    <Box sx={{border:"1px solid #80808066",p:1,borderRadius:"10px"}}> 
      <Stack spacing={2} direction="row">
        <Box position="relative" >
          <input
            id="upload-file"
            type="file"
            onChange={(event) => {
              setFieldValue("profilePicture", event.currentTarget.files[0]);
            }}
            name="profilePicture"
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              opacity: 0
            }}
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <img src={uploadImage} alt="upload" loading='lazy' style={{width:"30px",height:"30px"}}/>
          <Typography variant="body1" gutterBottom component="div" sx={{fontSize:["8px","16px"]}}>
            {values.profilePicture?.name || "Drag and drop a file here "}
          </Typography>
          </Stack>
        </Box>

        <Typography variant="body1" gutterBottom component="div" sx={{ color: "secondary.main", fontWeight: "bold" }}>
          or
        </Typography>

        <Button
          as="label"
          htmlFor="upload-file"
          variant="contained"
          component="label"
          color="primary"
          sx={{fontSize:["8px","16px",],p:[0,1],display:"flex",alignItems:"center",justifyContent:"center"}}
        >
          Select File
        </Button>
      </Stack>
    </Box>

  </Grid>
  )
}
