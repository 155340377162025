import { Box, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import axiosInstance from "../../axios/axiosInstance";
import { ProductDetailsInfoSkeleton } from "../general/loading/ProductDetailsInfoSkeleton";
export const ProductInfo = ({ product }) => {
    const [reviewNumber, setReviewNumber] = useState(0);

    // fetch reviews for user
    useEffect(() => {
        axiosInstance
            .get(
                `/reviews/products/${product?._id}`
            )
            .then((res) => {
                setReviewNumber(res.data.reviews.length);
            })
    }, [product]);
    return (
        <>
            {
                product ? (
                    <Box>
                        <Stack spacing={2} >
                            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                                <Typography variant="h5" sx={{ fontWeight: "500", color: "secondary.main" }}>
                                    {product ? product.name : null}
                                </Typography>
                                <Typography variant="h6" sx={{ color: "text.secondary" }}>
                                    {reviewNumber} reviews
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                                <Typography variant="h6" sx={{ color: "secondary.main", fontWeight: "500", }}>
                                    ${product ? product.price : null}/{product ? product.per : null}

                                </Typography>
                            </Stack>
                            <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                {product ? product.description : null}
                            </Typography>
                        </Stack>
                    </Box>
                ) : (
                    <ProductDetailsInfoSkeleton />
                )
            }
        </>

    )
}
