import { useEffect, useState } from 'react'
import axiosInstance from '../../../axios/axiosInstance';
import { Stack, Typography } from '@mui/material';
import { CommentItem } from './CommentItem';
import { ShowCommentAndReviewSkeleton } from '../../general/loading/ShowCommentAndReviewSkeleton';

export const ShowComments = ({ productID }) => {
  const [comments, setComments] = useState([]);
  const [loading,setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    const fetchComments = async () => {
      try {
        const res = await axiosInstance.get(
          `/comments/product/${productID}`
        );
        setComments(res.data.comments);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (error.response.status === 404) {
          setComments([]);
        }
      } 
    };

    fetchComments();
  }, [productID]);


  return (

    <>
    {
      !loading?(
        <Stack spacing={2}>
        {
          comments.length > 0 ? (
            comments.map(comment => {
              return <CommentItem key={comment._id} comment={comment} />
            })
          ) : (
            <Typography variant="h6" color="primary.main">No comments yet</Typography>
  
          )
        }
      </Stack> 
      ):(
      <ShowCommentAndReviewSkeleton/>
      )
    }
    </>
  )
}
