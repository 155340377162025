import {Box,Typography} from '@mui/material'
import { SaveAdress } from '../components/Settings/Saved-Adress/SaveAdress'

export const SaveAddress = () => {
  return (
  <Box py={"120px"}>
        <Typography variant="h4" align="center" sx={{ color: "primary.main", fontWeight: "bold",pb:8 }}>
            Save Address
        </Typography>

        <SaveAdress/>
    </Box>
  )
}
