import { useDispatch, useSelector } from 'react-redux';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { setAlertDetails } from '../../redux/slices/AlertDetailsSlice';
import { getUser } from '../../redux/slices/AuthUser';
import { useEffect, useState } from 'react';
import axiosInstance from '../../axios/axiosInstance';
export const ControlFavoriteProduct = ({ product }) => {
    const authUser = useSelector(getUser); // get user from redux
    const [isFavorite, setIsFavorite] = useState(false)
    const dispatch = useDispatch()
    // add product to favorite
    const addProductToFavorite = () => {
        axiosInstance.post('/products/add-to-favorites', {
            userId: authUser?.id,
            productId: product._id
        }).then(() => {
            setAlertDetailsToRedux('success', "Product added to favorites")
            setIsFavorite(true)

        }).catch(err => {
            setAlertDetailsToRedux('error', err.response.data.error)
        })
    }
    // remove product from favorite
    const removeProductFromFavorite = () => {
        axiosInstance.post('/products/remove-from-favorites', {
            userId: authUser?.id,
            productId: product._id
        }).then(() => {
            setAlertDetailsToRedux('success', "Product removed from favorites")
            setIsFavorite(false)

        }).catch(err => {
            setAlertDetailsToRedux('error', err.response.data.error)

        })
    }
    const controlFaivoriteProduct = () => {
        if (isFavorite) {
            removeProductFromFavorite()
        } else {
            addProductToFavorite()
        }
    }

    // check if product is favorite
    useEffect(() => {
        const checkProductIsFavorite = () => {
            axiosInstance.post('/products/is-favorited', {
                userId: authUser?.id,
                productId: product ? product._id : null
            }).then(res => {
                setIsFavorite(res.data.isFavorited)
            })
        }
        if (authUser?.id) {
            checkProductIsFavorite()
        }

    }, [authUser?.id, product])

    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };

    return (
        <FavoriteIcon onClick={controlFaivoriteProduct} sx={{ fontSize: "35px", color: isFavorite ? "primary.main" : "gray", cursor: "pointer", }}
        />
    )
}
