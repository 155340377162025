import { Box, Grid, Stack, Typography, Link } from '@mui/material';
import location from "../../assets/images/location.webp";
import phone from "../../assets/images/phone.webp";
import email from "../../assets/images/email.webp";
import website from "../../assets/images/website.webp";

const info = [
    {
        icon: location,
        title: "Our Location",
        info: "76 Mill Road, Penfield, NY 26.",
        link: "https://www.google.com/maps/search/?api=1&query=76+Mill+Road,+Penfield,+NY+26"
    },
    {
        icon: phone,
        title: "Phone Number",
        info: "+1 234 567 890",
        link: "tel:+1234567890"
    },
    {
        icon: email,
        title: "Email",
        info: "customerservice@directrent.com",
        link: "mailto:customerservice@directrent.com"
    }, {
        icon: website,
        title: "Website",
        info: "www.directrent.com",
        link: "http://www.directrent.com"
    }
];

export const ContactUsInfo = () => {
    return (
        <Box>
            <Typography variant="body1" sx={{ color: "secondary.main", width: ["100%", "70%"] }}>
                Our story is one of passion and perseverance, as we have embarked on a journey
                to pioneer innovation with cutting-edge technology. We are a team of.
            </Typography>
            <Grid container spacing={5} pt={5} pb="140px" >
                {info.map((item) => {
                    return (
                        <Grid item xs={12} sm={6} key={item.title}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Link href={item.link} target="_blank">
                                    <Box sx={{ backgroundColor: "primary.main", minWidth: "60px", minHeight: "60px", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={item.icon} alt="contact us" loading='lazy' style={{ maxWidth: "30px", maxHeight: "30px" }} />
                                    </Box>
                                </Link>
                                <Stack spacing={1} sx={{ flexWrap: "wrap", flexGrow: 1 }} >
                                    <Typography variant="h6" sx={{ color: "secondary.main", fontWeight: "bold", }}>
                                        {item.title}
                                    </Typography>
                                    <Link href={item.link} target="_blank" style={{textDecoration:"none"}}>
                                        <Typography variant="body1" sx={{ color: "secondary.main", wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-all" }}>
                                            {item.info}
                                        </Typography>
                                    </Link>
                                </Stack>
                            </Stack>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};