import { Box, Button, Container, Stack, Typography } from "@mui/material"
export const EarnMoney = () => {
  return (
    <Box sx={{ backgroundColor: "secondary.main", py: 5 }}>
      <Container>
        <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ textAlign: "center" }}>
          <Typography variant="h6" component="h3" sx={{ color: "text.main" }}>
            Make money from the things you own!
          </Typography>
          <Button variant="contained" sx={{ my: "10px", borderRadius: "20px", width: "fit-content", textTransform: "capitalize", px: 5, py: 1, fontSize: "20px" }}>
             Start Earning
          </Button>
        </Stack>
      </Container>
    </Box>
  )
}
