import { Box, IconButton, Stack } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { styled, useTheme } from '@mui/material/styles';
import { setSearchStatus } from '../../../redux/slices/Search';
import { useDispatch } from 'react-redux';
import TuneIcon from '@mui/icons-material/Tune';
const StyledInput = styled('input')({
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
});
export const Search = () => {
    const dispatch = useDispatch();
    const openSearchDialog = () => {
        dispatch(setSearchStatus(true));
    }
    const theme = useTheme();

    return (

        <Stack direction="row" justifyContent="center" alignItems="center"
            sx={{
                height: "60px",
                backgroundColor: "rgb(247, 247, 247)",
                borderRadius: "20px",
                px: 3,
                width: ["100%", "50%"],
                margin: "auto",
                cursor: "pointer",
                position: "relative",
                my: 3
            }}

        >
            {/*box absolute to click it when user want search open search Dialog */}
            <Box sx={{ position: "absolute", top: "0px", right: "0px", bottom: "0px", left: "0px", cursor: "pointer" }} onClick={openSearchDialog}></Box>
            <IconButton
                aria-label="searchIcone"
                sx={{

                    color: "",
                }}
            >
                <SearchIcon aria-label="searchIcone" />

            </IconButton>

            <StyledInput value="Search products here..." disabled  aria-label="Search products"/>
          <TuneIcon sx={{color:theme.palette.primary.main}}/>
    
       
        </Stack>

    )
}
