import { Box, Button } from '@mui/material';
import {
    APIProvider,
    AdvancedMarker,
    Map,
} from '@vis.gl/react-google-maps';

export const Maps = ({ user, setUser }) => {
    // auto detect location
    const handleAutoDetectLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const newPosition = { lat: latitude, lng: longitude };
                    setUser((prev) => ({
                        ...prev,
                        location: newPosition

                    }));
                },

            );
        }
    };
    return (
        <APIProvider apiKey='AIzaSyBgwMV6zIMMSXiui7N3hqvyraHuSlUQ2p0'>
            <Map
                mapId="e8b1b7b3b3b4b3b3hgc"
                zoom={2}
                center={{ lat: user.location ? user.location.lat : 0, lng: user.location ? user.location.lng : 0 }}
                onClick={(event) => {
                    const position = event.detail.latLng
                    setUser((prev) => ({
                        ...prev,
                        location: position
                    }))
                }}
                style={{ width: "100%", height: "300px" }}
            >
                {user.location ? <AdvancedMarker position={user.location} /> : null}
            </Map>
            <Box>
                <Button onClick={handleAutoDetectLocation} sx={{ textAlign: "center" }}>Auto Detect Location</Button>
            </Box>
        </APIProvider>
    )
}
