import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useEffect, useContext } from "react";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { LoadingMoreProductsAnimation } from "../../general/loading/LoadingMoreProductsAnimation";
import { UsersTable } from "./UsersTable";
import { UserContext } from "../../../context";
import { GeneralSkeletonLoading } from "../../general/loading/GeneralSkeletonLoading";
import axiosInstance from "../../../axios/axiosInstance";
const GetAllUsers = () => {
  const { search } = useContext(UserContext); // Get the search value from the context to send it to the backend
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [endUsers, setEndUser] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const limit = 10;
  // Fetch initial user when the component mounts
  useEffect(() => {
    setLoadingPage(true);
    axiosInstance.post(
      `/admin/users?page=${1}&limit=${limit}`,
      { name: search.searchValue },

    ).then((response) => {
      setUsers(response.data.users);
      setLoadingPage(false);
    }).catch(() => {
      setLoadingPage(false);

    });

  }, [search.searchValue]);

  // Fetch additional products when the page number changes depending on the scroll position
  const fetchAdditionalUsers = async (pageNumber) => {
    if (!loadingPage) {
      setLoading(true);
    }
    axiosInstance.post(`/admin/users?page=${pageNumber}&limit=${limit}`, { name: search.searchValue }).then((response) => {
      if (response.data.users.length === 0) {
        setEndUser(true);
      }
      setUsers((prevUser) => {
        return [...prevUser, ...response.data.users];
      });
      setPage(pageNumber);
    }).catch(() => {/* */ }).finally(() => {
      setLoading(false);
    });

  };
  return (
    <InfiniteScroll
      style={{
        overflow: "hidden", padding: "5px", marginTop: "40px", position:
          "relative"
      }}
      dataLength={users.length}
      next={() => fetchAdditionalUsers(page + 1)}
      hasMore={!endUsers}
      loader={
        loading && (
          <Box
            sx={{
              position: "absolute",
              bottom: "5px",
              right: "0px",
              width: "100%",
            }}
          >
            <Paper
              sx={{
                p: 2,
                width: "100%",
                backgroundColor: "shadow.main",
                color: "primary.main",
                textAlign: "center",
              }}
            >
              <LoadingMoreProductsAnimation loadingText="Loading More Users ..." />
            </Paper>
          </Box>
        )
      }
    >
      <Grid container spacing={2}>
        {loadingPage ? (
          <Grid item xs={12}>
            <Stack spacing={2}>
              <GeneralSkeletonLoading number={20} />
            </Stack>
          </Grid>
        ) : users.length > 0 ? (
          <>
            <UsersTable users={users} />
          </>
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{ textAlign: "center", color: "primary.main" }}
            >
              No Users Found
            </Typography>
          </Grid>
        )}
      </Grid>
    </InfiniteScroll>
  );
};
export default GetAllUsers;
