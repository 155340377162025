import { Stack, Typography } from '@mui/material'
import notFoundImage from "../assets/images/not-found.webp"

export const NotFound = () => {
    return (
        <Stack spacing={5} sx={{ justifyContent: "center", alignItems: "center" }}>
            <img src={notFoundImage} alt="page-not-Found" loading="lazy"/>
            <Typography variant='h6' component="div" sx={{color:"primary.main",fontWeight:"bold"}}>
                 Page Not Found
            </Typography>
        </Stack>
    )
}
