import { Container, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import EditUser from '../../components/admin/user/EditUser'
import EditPassword from '../../components/admin/user/EditPassword'
export const EditUserPage = () => {
    const { username } = useParams()
    return (
        <Container py={3}>
            <Typography variant="h5" component="h4" sx={{ my: 5, color: "text.secoundry", fontWeight: "bold" }}>
                Edit information  {username}
            </Typography>
            <EditUser />
            <EditPassword />
        </Container>
    )
}
