/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Skeleton, Stack, Tooltip,  } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch } from "react-redux";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import axiosInstance from "../../axios/axiosInstance";
import { useContext, useEffect, useState } from "react";
import AvatarUI from "./AvatarUI";
import { NotificationContext } from "../../context";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
export const Unread = () => {
  const theme = useTheme();
  const { handleUpdateCount } = useContext(NotificationContext);
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([]);
  const [hiddenLoadMore, setHiddenLoadMore] = useState(false);
  const [updateNotification, setUpdateNotification] = useState(false)
  let [page, setPage] = useState(1);
  let limit = 10;
  const dispatch = useDispatch();
  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }))
  };

  // fetch  notifications
  useEffect(() => {
    if (page === 1 && messages.length === 0) { // this to prevent loading to show when fetching more notifications
      setLoading(true)
    }
    const getNotifications = (type) => {
      axiosInstance
        .get(`/notifications?type=${type}&isRead=${false}&page=${page}&limit=${limit}`
        )
        .then((res) => {
          if (res.data.notifications.length === 0) {
            setHiddenLoadMore(true);
          }
          setMessages((prev) => [...prev, ...res.data.notifications]);

        }).finally(() => {
          setLoading(false)
        })
    };
    getNotifications("message");

  }, [limit, page])

  // set notification as read 
  const setNotificationAsRead = (notificationId) => {
    axiosInstance
      .patch(`/notifications/markAsRead`, {
        ids: [notificationId]
      })
      .then((res) => {
        setAlertDetailsToRedux("success", res.data.message)
        handleUpdateCount(); // update notification count
        setUpdateNotification(!updateNotification)// update notification
      }).catch((err) => {
        setAlertDetailsToRedux("error", err.response.data.error)
      });
  };
   // handle update notification when notification is updated
   useEffect(() => {
    if(messages.length === 0) return;
    axiosInstance.get(`/notifications?type=message&isRead=${false}&page=${1}&limit=${limit}`).then((res) => {
        setMessages(res.data.notifications)
        if (res.data.notifications.length === 0) {
            setHiddenLoadMore(true);
        }
    })
}, [ updateNotification])
  // fetch more notification
  const fetchMoreNotification = () => {
    setPage(page + 1);
  }
  return (
    <Stack spacing={1} >
      {

        loading && (
          <Stack spacing={2}>
            {
              [1, 2, 3, 4].map((item) => {
                return (
                  <Skeleton key={item} variant="rectangular" height={100} sx={{ borderRadius: "5px" }} />
                )
              })
            }
          </Stack>
        )

      }
      {messages.map((message) => {
        return (
          <Stack
            key={message._id}
            sx={{
              backgroundColor: "white",
              borderRadius: "5px",
              p: 2,
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "5px",
                right: "5px",
                cursor: "pointer",
                backgroundColor: "transparent !important"
              }}
              onClick={() => setNotificationAsRead(message?._id)}
            >
              <Tooltip title="Set As readed">
                <VerifiedIcon sx={{ color: "primary.main" }} />
              </Tooltip>
            </Box>
             <AvatarUI sender={message?.sender} />
            <Link to="/chat" style={{ fontSize: "16px", marginTop: "6px", color: theme.palette.secondary.main, textDecoration: "none", fontWeight: "400" }}>
              {message.title}
            </Link>

          </Stack>

        );
      })}
      {
        !hiddenLoadMore ? (
          <Button
            variant="contained"
            sx={{ backgroundColor: "primary.main", color: "white", mt: 2 }}
            onClick={fetchMoreNotification}
          >
            Load More
          </Button>) : (
          <Box sx={{ textAlign: "center", mt: 2, color: "gray" }}>
            No more notifications
          </Box>
        )
      }
    </Stack>
  );
};

