import { Stack, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NotifcationSwitch from './NotificationsSetting';
import { getUser } from '../../redux/slices/AuthUser';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
export const ListSettings = () => {
    const authUser = useSelector(getUser);
    return (

        <Stack spacing={2}>
            {/**Account info */}
            <Stack direction="row" justifyContent="space-between"
                sx={{ backgroundColor: "rgb(247,247,247)", mt: 5, p: 2, cursor: "pointer", textDecoration: "none" }} as={Link} to={`/account-info/${authUser?.id}`} >
                <Typography variant="body1" align="center" sx={{ color: "secondary.main", fontWeight: "bold" }}>
                    Account info
                </Typography>
                <ArrowForwardIcon sx={{ color: "secondary.main", fontSize: "30px", cursor: "pointer" }} />
            </Stack>
            {/**save Addresses */}
            <Stack direction="row" justifyContent="space-between"
                sx={{ backgroundColor: "rgb(247,247,247)", mt: 5, p: 2, cursor: "pointer", textDecoration: "none" }} as={Link} to="/save-address">
                <Typography variant="body1" align="center" sx={{ color: "secondary.main", fontWeight: "bold" }}>
                    Save Addresses
                </Typography>
                <ArrowForwardIcon sx={{ color: "secondary.main", fontSize: "30px", cursor: "pointer" }} />
            </Stack>
            {/**notification settings */}
            <Stack direction="row" justifyContent="space-between"
                sx={{ backgroundColor: "rgb(247,247,247)", mt: 5, p: 2, cursor: "pointer" }} >
                <Typography variant="body1" align="center" sx={{ color: "secondary.main", fontWeight: "bold" }}>
                    Notifications

                </Typography>
                <NotifcationSwitch />
            </Stack>
            {/** Language */}
            <Stack direction="row" justifyContent="space-between"
                sx={{ backgroundColor: "rgb(247,247,247)", mt: 5, p: 2, cursor: "pointer" }} >
                <Typography variant="body1" align="center" sx={{ color: "secondary.main", fontWeight: "bold" }}>
                    Language

                </Typography>
                <Typography variant="body1" align="center" sx={{ color: "secondary.main", fontWeight: "bold" }}>
                    English
                </Typography>

            </Stack>
            {/**Country*/}
            <Stack direction="row" justifyContent="space-between"
                sx={{ backgroundColor: "rgb(247,247,247)", mt: 5, p: 2, cursor: "pointer" }} >
                <Typography variant="body1" align="center" sx={{ color: "secondary.main", fontWeight: "bold" }}>
                    Country

                </Typography>
                <Typography variant="body1" align="center" sx={{ color: "secondary.main", fontWeight: "bold" }}>
                    {authUser?.user?.address?.country || "?"}

                </Typography>
            </Stack>

        </Stack>


    )
}
