import { Box, Button, CircularProgress, Container, FormControl, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTheme, styled } from "@mui/material/styles";
import { signUpWithReferralValidationSchema } from '../validation/validationSchemas';
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
// import icons
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Diversity3Icon from '@mui/icons-material/Diversity3';
const CustomTextField = styled(TextField)({
  color: "secondary.main",
  '& .MuiOutlinedInput-root': { // To remove outline
    borderRadius: "10px",
    backgroundColor: "white",
    color: "secondary.main",
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'black',
      fontSize: '12px',
    },
  },
  '& .MuiFormLabel-root': { // To change label color
    color: 'black', // Replace 'desiredColor' with the color you want
  },
});


export const SignUpWithReferrals = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const initialValues = {
    username: "",
    email: "",
    password: "",
    repeatPassword: "",
    referralCode: ""
  }
  // handle toggle password function
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // get password input adornment
  const getPasswordInputAdornment = () => {
    return (
      <InputAdornment position="end">
        <IconButton onClick={handleTogglePassword} edge="end">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );
  };

  // handle submit function
  const handleSubmit = (/* values */) => {
    setLoading(true)
    /*  console.log(values) */
  }
  return (
    <Box sx={{ pt: 8 }}>
      <Typography variant="h2" align="center" sx={{ color: "primary.main", fontWeight: "bold", mb: 8, fontSize: ["35px", "60px"] }}>
        Sign up
      </Typography>

      <Box sx={{ backgroundColor: "text.main", py: "100px" }}>
        <Container maxWidth="sm">
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2, backgroundColor: "secondary.main", px: 5 }}>
            <Typography variant="h4" align="center" sx={{ color: "white", fontWeight: "bold", textTransform: "capitalize" }}>
              Sign up with
              <br />
              Referral Code
            </Typography>
            <Typography variant="body2" align="center" sx={{ color: "white", my: 2 }}>
              Please enter your sign in credentials to create your account.
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={signUpWithReferralValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleBlur, errors, touched }) => (
                <Form>
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <CustomTextField
                      size='small'
                      variant="outlined"
                      id="username"
                      name="username"
                      placeholder="Username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.username && Boolean(errors.username)}
                      helperText={touched.username && errors.username}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PermContactCalendarIcon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                    />

                  </FormControl>

                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <CustomTextField
                      size='small'
                      type="email"
                      placeholder="Enter your email"
                      variant="outlined"
                      id="identifier"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineIcon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 1 }} >
                    <CustomTextField
                      size='small'
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      variant="outlined"
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: getPasswordInputAdornment(),
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOpenIcon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <CustomTextField
                      size='small'
                      type={showPassword ? "text" : "password"}
                      placeholder="Repeat Password"
                      variant="outlined"
                      id="repeatPassword"
                      name="repeatPassword"
                      value={values.repeatPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.repeatPassword && Boolean(errors.repeatPassword)}
                      helperText={touched.repeatPassword && errors.repeatPassword}
                      InputProps={{
                        endAdornment: getPasswordInputAdornment(),
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOpenIcon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <CustomTextField
                      size='small'
                      placeholder="Referral Code"
                      variant="outlined"
                      id="referralCode"
                      name="referralCode"
                      value={values.referralCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.referralCode && Boolean(errors.referralCode)}
                      helperText={touched.referralCode && errors.referralCode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Diversity3Icon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ borderRadius: "10px", py: 1, mt: 2 }}
                  >
                    Create account {" "}
                    {loading ? (
                      <CircularProgress
                        size={20}
                        sx={{ color: "#dcc4c4", marginLeft: "5px" }}
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                </Form>
              )}
            </Formik>

            <Typography variant="body2" sx={{ textAlign: "center", mt: 2, color: "white" }}>
              By signing up you agree to our

              <Link to="/" style={{ color: "white", paddingLeft: "5px" }}>Privacy Policy and Terms </Link>.
            </Typography>



            <Typography variant="body2" sx={{ textAlign: "center", mt: 3, color: "white" }}>
              Already have an account?{" "}
              <Link
                to="/login"
                style={{ color: theme.palette.primary.main, marginLeft: "3px" }}
              >
                Sign in
              </Link>
            </Typography>
          </Paper>
        </Container>
      </Box>
    </Box>
  )
}
