import { useState } from "react";
import { Formik, Form } from "formik";
import { useTheme, styled } from '@mui/material/styles';
import {
    TextField,
    FormControl,
    Button,
    Typography,
    Paper,
    InputAdornment,
    IconButton,
    CircularProgress,
    Stack,
    Box,
    Container,
    Divider,
} from "@mui/material";
import { setOpenDialog } from "../../../redux/slices/userDetailsDialogSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { loginValidationSchema } from "../../../validation/validationSchemas";
import { useDispatch } from "react-redux";
import { setAlertDetails } from "../../../redux/slices/AlertDetailsSlice";
import { setAuthUser } from "../../../redux/slices/AuthUser";
import FacebookLoginButton from "../LoginBySocialmedia/LoginWithFacebook";
import GoogleLoginButton from "../LoginBySocialmedia/LoginWithGoogle";
import axiosInstance from "../../../axios/axiosInstance";
import { decodeToken } from "../../../helper/decodeToken";
// import icons 
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PhoneIcon from '@mui/icons-material/Phone';
const initialValues = {
    identifier: "",
    password: ""
};
const CustomTextField = styled(TextField)({
    color: "secondary.main",
    '& .MuiOutlinedInput-root': { // To remove outline
        borderRadius: "10px",
        backgroundColor: "white",
        color: "secondary.main",
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
        '& .MuiInputBase-input::placeholder': {
            color: 'black',
            fontSize: '12px',

        },
    },
    '& .MuiFormLabel-root': { // To change label color
        color: 'black', // Replace 'desiredColor' with the color you want
    },
});
const Login = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loginMethod, setLoginMethod] = useState("email");
    // toggle password visibility
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    // if user not have some information then show dialog for complete information
    const showDialogIfUserNotHaveSomeInformation = (user) => {
        if (user.role === "User") {
            // if user role is user show dialog if admin role is admin don't show dialog
            if (
                !user.firstName ||
                !user.lastName ||
                !user.phoneNumber ||
                !user.location

            ) {

                dispatch(setOpenDialog(true));
            }
        }
    };

    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };

    const handleSubmit = (values) => {
        setLoading(true);
        axiosInstance
            .post(
                "/user/login",
                values
            )
            .then((res) => {
                const { token, foundedUser } = res.data;
                const { id, exp } = decodeToken(token);
                dispatch(setAuthUser({ user: foundedUser, exp: exp, id: id })) // set user to redux
                localStorage.setItem("token", token);
                setLoading(false);
                showDialogIfUserNotHaveSomeInformation(foundedUser);
                navigate("/");
            })
            .catch((err) => {
                if (err) {
                    setAlertDetailsToRedux("error", err.response.data.error);
                }
                setLoading(false);
            });

    };

    const getPasswordInputAdornment = () => {
        return (
            <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        );
    };

    return (
        <Box sx={{ pt: 8 }}>
            <Typography variant="h2" align="center" sx={{ color: "primary.main", fontWeight: "bold", mb: 8, fontSize: ["35px", "60px"] }}>
                Login
            </Typography>

            <Box sx={{ backgroundColor: "text.main", py: "100px" }}>
                <Container maxWidth="sm">
                    <Paper elevation={3} sx={{ py: 4, borderRadius: 2, backgroundColor: "secondary.main", px: [2, 5] }}>
                        <Typography variant="h4" align="center" sx={{ color: "primary.main", fontWeight: "bold", fontSize: ["30px", "40px"] }}>
                            Welcome Back
                        </Typography>
                        <Typography variant="body2" align="center" sx={{ color: "white", my: 2 }}>
                            Please enter your login credentials to access your account.
                        </Typography>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={loginValidationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, handleChange, handleBlur, errors, touched }) => (
                                <Form>
                                    <FormControl fullWidth margin="normal">
                                        <FormControl fullWidth sx={{ mb: 1 }}>
                                            <CustomTextField
                                                size='small'
                                                type={loginMethod === "email" ? "email" : "tel"}
                                                placeholder={loginMethod === "email" ? "Email" : "Phone Number"}
                                                variant="outlined"
                                                id="identifier"
                                                name="identifier"
                                                value={values.identifier}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.email && Boolean(errors.email)}
                                                helperText={touched.email && errors.email}
                                                InputProps={{
                                                    startAdornment: loginMethod === "email" ? <MailOutlineIcon sx={{ fontSize: "15px", mr: 1 }} /> : <PhoneIcon sx={{ fontSize: "15px", mr: 1 }} />
                                                }}
                                            />

                                        </FormControl>
                                    </FormControl>
                                    <FormControl fullWidth sx={{ mb: 1 }}>
                                        <CustomTextField
                                            size='small'
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Password"
                                            variant="outlined"
                                            id="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.password && Boolean(errors.password)}
                                            helperText={touched.password && errors.password}
                                            InputProps={{
                                                endAdornment:
                                                    getPasswordInputAdornment(handleTogglePassword),
                                                startAdornment: <LockOpenIcon sx={{ fontSize: "15px", mr: 1 }} />
                                            }}
                                        />
                                    </FormControl>
                                    <Link to="/sendToken" style={{ color: theme.palette.text.main, textDecoration: "none" }}>Forgot Password ?</Link>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        style={{ marginTop: 20 }}
                                    >
                                        Login {loading ? <CircularProgress size={20} sx={{ color: "#dcc4c4", marginLeft: "5px" }} /> : ""}
                                    </Button>


                                </Form>
                            )}
                        </Formik>
                        { // if user want to login with email show login with phone number button and vice versa
                            loginMethod === "email" ? (
                                <Typography variant="body1" component="h6" onClick={() => setLoginMethod("phone")} sx={{ mt: 2, color: "white", textAlign: "center", cursor: "pointer", }}>
                                    Login with Phone Number
                                </Typography>
                            ) : (
                                <Typography variant="body1" component="h6" onClick={() => setLoginMethod("email")} sx={{ mt: 2, color: "white", textAlign: "center", cursor: "pointer" }}>

                                    Login with Email
                                </Typography>
                            )
                        }
                        <Divider sx={{
                            mt: 3,
                            color: "text.main",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            "&::before": {
                                borderColor: "text.main",
                            },
                            "&::after": {
                                borderColor: "text.main",
                            }
                        }} >
                            or
                        </Divider>
                        <Stack spacing={2} direction="row" sx={{ mt: 2, alignItems: "center", justifyContent: "center" }}>
                            <FacebookLoginButton />
                            <GoogleLoginButton />
                        </Stack>
                        <Box sx={{ textAlign: "center", mt: 3, color: "white" }}>
                            { /*eslint-disable-next-line react/no-unescaped-entities */}
                            Don't have an account ?{" "}
                            <Link
                                to="/signup"
                                style={{ color: theme.palette.primary.main, marginLeft: "3px" }}
                            >
                                Create an account
                            </Link>
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </Box>
    );
};

export default Login;