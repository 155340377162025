import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { Container, Grid, InputLabel, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { AdminTransactionsSearch } from "../../../context";

export const SearchByNameOrStatus = () => {
    const [searchKeys, setSearchKeys] = useState({
        name: "",
        status: "",
    });

    const { changeSearchKeys } = useContext(AdminTransactionsSearch);// get the function from context

    // handle change of input fields
    const handleChangeFiled = (e) => {
        setSearchKeys({ ...searchKeys, [e.target.name]: e.target.value });
    };

    // send search keys to context
    const sendSearchKeys = () => {
        changeSearchKeys({search:searchKeys.name,status:searchKeys.status});
    };

    // handle enter key press
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            sendSearchKeys();
        }
      };
    const status = [
        "Pending",
        "Approved",
        "Rejected",
        "Ongoing",
        "Completed",
        "Cancelled",
    ];
    return (
        <Container>
            <Grid
                container
                spacing={2}
                sx={{ justifyContent: "center", alignItems: "center", py: 3 }}
            >
                <Grid item xs={6} md={3}>
                    <TextField
                        fullWidth
                        name="name"
                        label="Search by name"
                        onChange={(e) => handleChangeFiled(e)}
                        value={searchKeys.name}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"> Status </InputLabel>
                        <Select
                            fullWidth
                            name="status"
                            value={searchKeys.status}
                            onChange={(e) => handleChangeFiled(e)}
                            label="Status"
                        >
                            {status.map((status, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={status}
                                        sx={{ fontSize: ["15px", "18px"] }}
                                    >
                                        {status}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={sendSearchKeys}
                        sx={{ py: 2 }}
                    >
                        Search
                    </Button>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ py: 2 }}
                        onClick={sendSearchKeys}
                    >
                        Show All
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};
