import { Container, Grid, Box, Button } from "@mui/material"
import { useEffect, useState } from "react";
import { setAlertDetails } from "../../../redux/slices/AlertDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axios/axiosInstance";
import { fetchUser, getUser } from '../../../redux/slices/AuthUser';
import { AdressInput } from "./AdressInput";
import { Maps } from "./Maps";
export const SaveAdress = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector(getUser);
    const [user, setUser] = useState({
        address: {
            country: "",
            state: "",
            zipCode: "",
            apartment: "",
            city: "",
            address: ""

        },
        location: null

    });

    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };

    // handle change value
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser((prev) => ({
            ...prev,
            address: {
                ...prev.address,
                [name]: value
            }
        }));
    };
    // handle save
    const handleSave = async (e) => {
        e.preventDefault();
        // if location is null
        if (!user.location) {
            setAlertDetailsToRedux("error", "Please select location on the map")
            return;
        }
        axiosInstance.put(`/user/${authUser?.id}`, user
        ).then(() => {
            setAlertDetailsToRedux("success", "Adress updated successfully")
            // fetch new user data after update
            dispatch(fetchUser(authUser?.id));
            navigate(-1);
        }).catch(err => {
            setAlertDetailsToRedux("error", err.response.data.error)

        })
    }
    // get user data
    useEffect(() => {
        setUser({
            address: authUser?.user?.address ? {
                country: authUser?.user?.address?.country || "",
                state: authUser?.user?.address?.state || "",
                zipCode: authUser?.user?.address?.zipCode || "",
                apartment: authUser?.user?.address?.apartment || "",
                city: authUser?.user?.address?.city || "",
                address: authUser?.user?.address?.address || "",
            } : {
                country: "",
                state: "",
                zipCode: "",
                apartment: "",
                city: "",
                address: ""
            },
            location: authUser?.user?.location || null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (

        <Container >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <AdressInput user={user} handleChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Maps user={user} setUser={setUser} />
                    <Box sx={{ mt: 2, px: 5 }}>
                        <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2 }} fullWidth>Save</Button>
                    </Box>
                </Grid>
            </Grid>

        </Container>
    )
}

