import { Box, Container, Typography } from "@mui/material";
import bgtitle from "../../assets/images/titleBg.webp";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

export const HeaderNavigation = () => {
    const location = useLocation();
    const theme = useTheme();
    const isActive = (path) => {
        return location.pathname === path;
    }

    return (
        <Box
            sx={{
                py: 4,
                backgroundImage: `url(${bgtitle})`,
                backgroundAttachment: "fixed",
                backgroundPosition: "center",
                backgroundSize: "cover",
                color: "primary.main",
            }}
        >
            <Container maxWidth="md">
                <Typography
                    variant="h1"
                    component="h1"
                    sx={{ fontSize: ["16px", "20px", "25px"], fontWeight: "bold", pb: 2 }}
                >
                    Admin Dashboard
                </Typography>

                <Box role="presentation" >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/" style={{ textDecoration: "none", fontWeight: "bold", color: isActive("/") ? theme.palette.primary.main : theme.palette.secondary.main }}>
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            to="/admins"
                            style={{ textDecoration: "none", color: isActive("/admins") ? theme.palette.primary.main : theme.palette.secondary.main }}
                        >
                            Users
                        </Link>
                        <Link
                            underline="hover"
                            to="/admins/categories"
                            style={{ textDecoration: "none", color: isActive("/admins/categories") ? theme.palette.primary.main : theme.palette.secondary.main }}
                        >
                            Categories
                        </Link>
                        <Link
                            underline="hover"
                            to="/admins/add-categories"
                            style={{ textDecoration: "none", color: isActive("/admins/add-categories") ? theme.palette.primary.main : theme.palette.secondary.main }}
                        >
                            Add Categories
                        </Link>
                        <Link 
                            underline="hover" 
                            to="/admins/Configuration" 
                            style={{ textDecoration: "none", color: isActive("/admins/Configuration") ? theme.palette.primary.main : theme.palette.secondary.main }}
                        >
                            Configuration
                        </Link>

                    </Breadcrumbs>
                </Box>
            </Container>
        </Box>
    );
};