import { Button, Container, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlertDetails } from "../redux/slices/AlertDetailsSlice";
import axiosInstance from "../axios/axiosInstance";
import { useParams } from "react-router-dom";
export const VerificationPage = () => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { method } = useParams();

  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };
  // handle change code input
  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };

  // handle send verification code
  const sendVerificationCode = () => {
    if (method === "email") {
      axiosInstance
        .post(
          "/user/verify-email",
          { verifyToken: code }
        )
        .then(() => {
          navigate("/login");
        })
        .catch((err) => {
          if (err) {
            setAlertDetailsToRedux("error", err.response.data.err);

          }
        });
    } else {
      axiosInstance
        .post(
          "/user/verify-phone",
          { verifyToken: code }
        )
        .then(() => {
          navigate("/login");
        })
        .catch((err) => {
          if (err) {
            setAlertDetailsToRedux("error", err.response.data.err);

          }
        });
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 8 }}>
      <Typography variant="h4" sx={{ textAlign: "center", mb: 5, color: "secondary.main", fontWeight: "bold" }}>
        Verify Your Acount
      </Typography>
      <Stack
        sx={{ justifyContent: "center", alignItems: "center", height: "40vh" }}
      >
        <TextField
          name="code"
          id="outlined-basic"
          label={method === "email" ? "Email Verification Code" : "Phone Verification Code"}
          variant="outlined"
          onChange={handleChangeCode}
          fullWidth
          sx={{
            '& .MuiFormLabel-root': {
              color: "secondary.main",
            },
            // outlined input color in focus
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'secondary.main',
            },

          }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={sendVerificationCode}
        >
          Verify Account
        </Button>

      </Stack>
    </Container>
  );
};

