import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs';
export const PaymentInfo = ({ transaction }) => {
    //calculate number of day  the product will be rented
    const startDate = dayjs(transaction.startDate).startOf('day');
    const endDate = dayjs(transaction.endDate).startOf('day');
    let numberOfUnits;
    switch (transaction?.productID?.per) {
        case 'Week':
            numberOfUnits = endDate.add(1, 'day').diff(startDate, 'week');
            break;
        case 'Month':
            numberOfUnits = endDate.add(1, 'day').diff(startDate, 'month');
            break;
        case 'Year':
            numberOfUnits = endDate.add(1, 'day').diff(startDate, 'year');
            break;
        default:
            numberOfUnits = endDate.add(1, 'day').diff(startDate, 'day');
    }

    return (
        <Box sx={{ backgroundColor: "#F7F7F7", p: 2, borderRadius: "10px" }}>
            <Typography variant="h5" sx={{ color: "secondary.main", fontWeight: "bold" }}>Order info</Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                <Typography variant="body1" component="p">Price Per {transaction?.productID?.per}</Typography>
                <Typography variant="body1" component="p">{transaction?.productID?.price}$</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                {/**show time rent  */}
                <Typography variant="body1" component="p">Time Rent</Typography>
                <Typography variant="body1" component="p">{numberOfUnits} {transaction?.productID?.per}</Typography>

            </Box>
            {/** show sub total price  */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                <Typography variant="body1" component="p">Sub Total</Typography>
                <Typography variant="body1" component="p">{transaction?.productID?.price * numberOfUnits}$</Typography>
            </Box>

            {/** show tax */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                <Typography variant="body1" component="p">Tax</Typography>
                <Typography variant="body1" component="p">??</Typography>
            </Box>
            {/** show total price */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                <Typography variant="body1" component="p">Total</Typography>
                <Typography variant="body1" component="p">??</Typography>
            </Box>
        </Box>

    )
}
