import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import mobile from "../../../assets/images/mobile.webp"
import googlePlay from "../../../assets/images/get-app-from-googlePlay.svg"
import appStore from "../../../assets/images/get-app-from-googlePlay.svg"
import { Link } from 'react-router-dom'
export const DounloadApp = () => {
    return (

        <Box sx={{ backgroundColor: "text.main", py: 4 }}>
            <Container>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={8} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{}}>
                            <Typography variant="h5" sx={{ color: "primary.main", letterSpacing: "5px",fontWeight:"bold" }}>New Launch</Typography>
                            <Typography variant="h3" sx={{
                                color: "secondary.main", fontWeight: "bolder", fontSize: ["30px", "40px"],
                                "::after": {
                                    "content": "'App Today'",
                                    "color": "primary.main",
                                    "paddingLeft": "15px",
                                    "fontFamily":"Poppins-Bold"
                                },
                            }}>
                                Download our mobile

                            </Typography>
                            <Typography variant="body1" sx={{ color: "secondary.main", mt: 1 }}>
                                Quisque faucibus id dui vitae tempus. Nullam mauris felis, fermentum nec lacus in, luctus varius nibh.
                                Pellentesque eu pharetra erat. Pellentesque erat erat, molestie ac massa nec, rutrum suscipit
                            </Typography>
                            <p>Get the app to rent or list your items on the go</p>
                            <Stack spacing={1} direction="row" sx={{ maxWidth: "250px" }}>
                                <Link to="/">
                                    <img src={googlePlay} alt="mobile app" loading='lazy' style={{ width: "120px", height: "45px", objectFit: "contain" }} />
                                </Link>
                                <Link to="/">
                                    <img src={appStore} alt="app" loading='lazy' style={{ width: "120px", height: "45px", objectFit: "contain" }} />
                                </Link>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={mobile} alt="app" loading='lazy' style={{ width: "100%", height: "100%", objectFit: "contain" }} />

                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
