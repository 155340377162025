import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axiosInstance from "../../../axios/axiosInstance"
export const AverageRating = () => {
  const [average, setAverage] = useState(0)
  const params = useParams()
  useEffect(() => {
    const getRatingAvarege = () => {
      axiosInstance.post(`ratings/average-rating`, {
        id: params?.userId,
      }).then(res => {
        setAverage(res.data.averageRating)
      }).catch(err => {
        if (err.response.status === 404) {
          setAverage(0)
        }
      })
    }
    getRatingAvarege()
  }
    , [params?.userId])
  return (
    <Box sx={{ py: 3 }}>
      <Typography
        variant="h6"
        component="div"
        sx={{ color: "primary.main" }}

      >
        Rating
      </Typography>
      <Typography
        variant="h4"
        component="div"
        sx={{ color: "secondary.main" }}
      >
        {average ? `${average + "." + 0}` : "0.0"}
      </Typography>
    </Box>
  )
}
