import { useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
const productData = [
    { id: 1, name: 'Product 1', location: { lat: 12.971598, lng: 77.594562 } },
    { id: 2, name: 'Product 2', location: { lat: 34.052235, lng: -118.243683 } },
    // Add more products with their locations
];


const MapComponent = () => {
    const [markers, setMarkers] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });

    // add GOOGLE MAPS API KEY here
  
    const mapStyles = {
        height: '500px',
        width: '100%',
    };

    useEffect(() => {
        // Fetch the user's current location using Geolocation API
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error('Error getting user location:', error.message);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }

        // Set markers for available products (replace with your actual product data)
        setMarkers(productData.map((product) => ({
            id: product.id,
            lat: product.location.lat,
            lng: product.location.lng,
            name: product.name,
        })));
    }, []);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);

        // set nearby items markers on map
        // setMarkers();
    };

    const handleMapClick = (event) => {
        setUserLocation({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        });
    };

    return (
        <div>
          
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={4}
                center={userLocation}
                onClick={handleMapClick}
            >
                {markers.map((marker) => (
                    <Marker
                        key={marker.id}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        title={marker.name}
                    />
                ))}
            </GoogleMap>
          
        </div>
    );
};

export default MapComponent;