import { Avatar, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

const AvatarUI = ({sender}) => {
  return (
    <Stack direction="row" sx={{ alignItems: "center" }}>
              <Avatar
                sx={{ textDecoration: "none", width: "30px", height: "30px", mr: 1 }}
              >
                {sender?.profilePicture ? (
                  <img
                    src={sender?.profilePicture}
                    alt={sender?.username || "user"}
                    style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                  />
                ) : (

                sender?.username[0].toUpperCase() || "user"
                )}
              </Avatar>
              <Link
                to={`/contactWithUser/${sender?._id}/${sender?.username}`}
                style={{
                  textDecoration: "none",
                  color: "red",
                  fontSize: "14px",
                }}
              >
                {sender?.username || "user"}
              </Link>
            </Stack>
  )
}

export default AvatarUI