import store from "../redux/store";
import Navbar from "../components/general/header/Navbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../components/general/Footer";
import { useEffect } from "react";
import SearchDialog from "../components/general/CustomSearch";
import { ScrollToTop } from "../components/general/ScrollToTop";
import { setAlertDetails } from "../redux/slices/AlertDetailsSlice";
import { clearUser, getUser } from "../redux/slices/AuthUser";
import axiosInstance from "../axios/axiosInstance";
import { useSelector } from "react-redux";
import dayjs from 'dayjs'; // Import Day.js

export const Root = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    store.dispatch(setAlertDetails({ type, message }));
  };

  // check if pathname is change scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // check exp date of token and remove it if it is expired
  const { exp, id } = useSelector(getUser);
  useEffect(() => {
    const currentTime = dayjs();
    const expTime = dayjs.unix(exp);
    if (id && expTime.isBefore(currentTime)) {
      store.dispatch(clearUser());
      localStorage.removeItem("token");
      localStorage.removeItem("productSaved");
      setAlertDetailsToRedux("error", "The session expired,try login again");
      navigate("/");
    } else {

      // set timeout to logout user after token expired
      const timeout = expTime.diff(currentTime, 'second');
      setTimeout(() => {
        store.dispatch(clearUser());
        localStorage.removeItem("token");
        localStorage.removeItem("productSaved");
        setAlertDetailsToRedux("error", "The session expired,try login again");
        navigate("/");
      }, timeout * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create an axios interceptor to check if user is authenticated or not
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 403) {
        localStorage.removeItem("token");// clear token from local storage
        localStorage.removeItem("productSaved");// clear saved product from local storage
        store.dispatch(clearUser()); // clear user from redux
        store.dispatch(setAlertDetails({ type: "error", message: "The session expired, Or you did not login,try login" }));
        navigate("/");// redirect to login page
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      <SearchDialog />
      <Navbar />
      <Outlet />
      <Footer />
      <ScrollToTop />
    </>
  );
};
