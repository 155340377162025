import { Box, Container, Grid, Typography } from '@mui/material';
import referrals1 from '../../assets/images/referrals-1.png';
import referrals2 from '../../assets/images/referrals-2.png';
import referrals3 from '../../assets/images/referrals-3.png';


const List = [
    {
        img: referrals1,
        title: "Get your referral code",
        description: "Login to Direct Rent and agree to our terms. We’ll give you a unique referral code to share. "
    },
    {
        img: referrals2,
        title: "Refer your friends",
        description: "Share your referral code with friends. They can use this when they buy one of our personal health or life plans."
    },
    {
        img: referrals3,
        title: "Get 10% OFF each",
        description: "You’ll both get your 10% Off after your friend becomes a member"
    }

]

export const HowItWork = () => {
    return (
        <Box sx={{backgroundColor:"text.main",py:5}}>
        <Container>
            <Typography variant="h5" component="h2" sx={{ fontFamily: "Poppins-Bold", color: "primary.main", fontSize: ["20px", "45px"], textAlign: "center" }}>

                How it works
            </Typography>
            <Typography variant="body1" component="p" sx={{ color: "secondary.main", fontFamily: "Poppins", fontWeight: "500", fontSize: "25px", textAlign: "center", marginTop: "20px" }}>
                Here’s how to get your 10% discount on your next purchase
            </Typography>

            <Grid container my={8} sx={{ alignItems: "center",justifyContent:"center" }}>
                {List.map((item, index) => (
                    <Grid item xs={12} md={4} key={index}>
                       <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <img src={item.img}
                                alt="listStyle"
                                style={{ width: "140px", height: "140px" }} />
                        </Box>
                        <Typography variant="h5" component="h2" sx={{ fontFamily: "Poppins-Bold", color: "secondary.main", fontSize: ["20px", ], textAlign: "center", marginTop: "20px" }}>
                            {item.title}
                        </Typography>
                        <Typography variant="body1" component="p" sx={{ color: "secondary.main", fontFamily: "Poppins", fontWeight: "500", fontSize: "16px", textAlign: "center", marginTop: "20px" }}>
                            {item.description}
                        </Typography>

                    </Grid>
                ))}
            </Grid>
        </Container>
        </Box>
    )
}
