import { Tab, Tabs, } from "@mui/material";
import { useState } from "react";
// import navigation components
import { YourRents } from "./YourRents";
import { InRents } from "./InRents";
import { PastRents } from "./PastRents";
export const NavigationProducts = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Tabs value={value} onChange={handleChange} centered sx={{ py: 3 }}>
                <Tab
                    label={
                        "Past rents"
                    }
                    sx={{ textTransform: "capitalize" }}
                />

                <Tab
                    label={
                        "Your rents"
                    }
                    sx={{ textTransform: "capitalize" }}
                />
                <Tab
                    label={
                        "In rent"

                    }
                    sx={{ textTransform: "capitalize" }}
                />
            </Tabs>

            {value === 0 && <PastRents />}
            {value === 1 && <YourRents />}
            {value === 2 && <InRents />}
        </>
    );
};
