import { useState } from "react";
import { Formik, Form } from "formik";
import { useTheme, styled } from "@mui/material/styles";
import {
  TextField,
  FormControl,
  Button,
  Paper,
  InputAdornment,
  IconButton,
  CircularProgress,
  Stack,
  Typography,
  Box,
  Container,
  Divider,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { signupValidationSchema } from "../../../validation/validationSchemas";
import { setAlertDetails } from "../../../redux/slices/AlertDetailsSlice";
import FacebookLoginButton from "../LoginBySocialmedia/LoginWithFacebook";
import GoogleLoginButton from "../LoginBySocialmedia/LoginWithGoogle";
import axiosInstance from "../../../axios/axiosInstance";

// import icons 
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PhoneIcon from '@mui/icons-material/Phone';
const initialValues = {
  username: "",
  identifier: "",
  password: "",
};

const CustomTextField = styled(TextField)({
  color: "secondary.main",
  '& .MuiOutlinedInput-root': { // To remove outline
    borderRadius: "10px",
    backgroundColor: "white",
    color: "secondary.main",
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'black',
      fontSize: '12px',
    },
  },
  '& .MuiFormLabel-root': { // To change label color
    color: 'black', // Replace 'desiredColor' with the color you want
  },
});
const Signup = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signUpmethod, setSignUpMethod] = useState("email");
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };

  // handle send token to email 
  const sendTokenToEmail = (email) => {
    axiosInstance.post("/user/generate-email-token", { email }).then(() => {
      setAlertDetailsToRedux("success", "Please Check Your Email And Enter Code")
      setTimeout(() => {
        setAlertDetailsToRedux("", "")
      }, 3000);
      navigate(`/verification/email`);
    }).catch(err => {
      setAlertDetailsToRedux("error", err.response.data.error);
      setTimeout(() => {
        setAlertDetailsToRedux("", "");
      }, 3000);
    });
  };
  // handle send token to phone number
  const sendTokenToPhoneNumber = (phoneNumber) => {
    axiosInstance.post("/user/generate-phone-token", { phoneNumber }).then(() => {
      setAlertDetailsToRedux("success", "Please Check Your Phone And Enter Code")
      navigate("/verification/phone");
    }
    ).catch(err => {
      setAlertDetailsToRedux("error", err.response.data.error);
    });
  };
  // handle submit
  const handleSubmit = (values) => {
    setLoading(true);
    let finalValues = {}
    // check if sign up method is email TO set final values conatin email filed asigned with identifire value 
    if (signUpmethod === "email") {
      finalValues = {
        email: values.identifier,
        password: values.password,
        username: values.username,

      }
    } else {
      finalValues = {
        phoneNumber: values.identifier,
        password: values.password,
        username: values.username,

      }
    }

    axiosInstance
      .post(
        "/user/signup",
        finalValues
      )
      .then(() => {
        // check if sign up method is email or phone number to send token to email or phone number
        if (signUpmethod === "email") {
          sendTokenToEmail(values.identifier);
        } else {
          sendTokenToPhoneNumber(values.identifier);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          setAlertDetailsToRedux("error", err.response.data.error);
        }
        setLoading(false);
      });
  };

  const getPasswordInputAdornment = () => {
    return (
      <InputAdornment position="end">
        <IconButton onClick={handleTogglePassword} edge="end">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <Box sx={{ pt: 8 }}>
      <Typography variant="h2" align="center" sx={{ color: "primary.main", fontWeight: "bold", mb: 8, fontSize: ["35px", "60px"] }}>
        Sign up
      </Typography>

      <Box sx={{ backgroundColor: "text.main", py: "100px" }}>
        <Container maxWidth="sm">
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2, backgroundColor: "secondary.main", px: 5 }}>
            <Typography variant="h4" align="center" sx={{ color: "primary.main", fontWeight: "bold", fontSize: ["30px", "40px"] }}>
              Sign up
            </Typography>
            <Typography variant="body2" align="center" sx={{ color: "white", my: 2 }}>
              Please enter your sign in credentials to create your account.
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={signupValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleBlur, errors, touched }) => (
                <Form>
                  <FormControl fullWidth sx={{ mb: 1 }}>

                    <CustomTextField
                      size='small'
                      variant="outlined"
                      id="username"
                      name="username"
                      placeholder="Username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.username && Boolean(errors.username)}
                      helperText={touched.username && errors.username}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PermContactCalendarIcon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                    />

                  </FormControl>

                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <CustomTextField
                      size='small'
                      type={signUpmethod === "email" ? "email" : "tel"}
                      placeholder={signUpmethod === "email" ? "Email" : "Phone"}
                      variant="outlined"
                      id="identifier"
                      name="identifier"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {signUpmethod === "email" ? <MailOutlineIcon sx={{ fontSize: "15px" }} /> : <PhoneIcon sx={{ fontSize: "15px" }} />}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <CustomTextField
                      size='small'
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      variant="outlined"
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: getPasswordInputAdornment(),
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOpenIcon sx={{ fontSize: "15px" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ borderRadius: "10px", py: 1, mt: 2 }}
                  >
                    Create account {" "}
                    {loading ? (
                      <CircularProgress
                        size={20}
                        sx={{ color: "#dcc4c4", marginLeft: "5px" }}
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
            { // if user want to login with email show login with phone number button and vice versa
              signUpmethod === "email" ? (
                <Typography variant="body1" component="h6" onClick={() => setSignUpMethod("phone")} sx={{ mt: 2, color: "white", textAlign: "center", cursor: "pointer", }}>
                  SignUp with Phone Number
                </Typography>
              ) : (
                <Typography variant="body1" component="h6" onClick={() => setSignUpMethod("email")} sx={{ mt: 2, color: "white", textAlign: "center", cursor: "pointer" }}>

                  SignUp with Email
                </Typography>
              )
            }
            <Typography variant="body2" sx={{ textAlign: "center", mt: 2,color: "white" }}>
              By signing up you agree to our

              <Link to="/" style={{color: "white", paddingLeft: "5px" }}>Privacy Policy and Terms </Link>.
            </Typography>

            <Divider sx={{
              mt: 3,
              color: "text.main",
              textTransform: "uppercase",
              fontWeight: "bold",
              "&::before": {
                borderColor: "text.main",
              },
              "&::after": {
                borderColor: "text.main",
              }
            }} >
              or
            </Divider>
            <Stack direction="row" spacing={2} sx={{ mt: 2, alignItems: "center", justifyContent: "center" }}>
              <FacebookLoginButton />
              <GoogleLoginButton />
            </Stack>

            <Typography variant="body2" sx={{ textAlign: "center", mt: 3, color: "white" }}>
              Already have an account?{" "}
              <Link
                to="/login"
                style={{ color: theme.palette.primary.main, marginLeft: "3px" }}
              >
                Sign in
              </Link>
            </Typography>
          </Paper>
        </Container>
      </Box>
    </Box>

  );
};

export default Signup;