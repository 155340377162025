import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Button, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from "react-redux";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import { getUser } from "../../redux/slices/AuthUser";
import axiosInstance from "../../axios/axiosInstance";
import { useTheme } from "@mui/material/styles";
import { GeneralSkeletonLoading } from "../general/loading/GeneralSkeletonLoading";
// format date to be more readable
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

const getStatusColor = (status) => {
  switch (status) {
    case "Pending":
      return "orange";
    case "Approved":
      return "green";
    case "Rejected":
      return "red";
    case "Ongoing":
      return "blue";
    case "Completed":
      return "gray";
    case "Cancelled":
      return "red";
    default:
      return "black";
  }
};
export const Items = ({ transactions, loading }) => {
  const theme = useTheme();
  const userId = useSelector(getUser)?.id// get user id from redux
  const handleCanselDisabled = (transaction) => {
    // return true if transaction status is not pending or user equal owner of the product
    if (transaction.status === "Pending") {
      if (userId === transaction.ownerID._id) {
        return true;
      }
    }
    else {
      return true;
    }

  };
  const dispatch = useDispatch();
  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }))
  };

  // handle cancel transaction


  const handleCanselTransaction = (id) => {

    axiosInstance
      .put(
        `/transactions/cancel/${id}`,
        {},
      )
      .then(() => {
        setAlertDetailsToRedux("success", "Transaction canceled successfully");

      })
      .catch((err) => {
        if (err) {
          setAlertDetailsToRedux("error", err.response.data.error);

        }
      });
  };
  const navigate = useNavigate();

  const handlleNavigateToAddReview = (id) => {
    navigate(`/add-review/${id}`);
  };

  return (
    <TableContainer component={Box} sx={{ borderRadius: "10px", borderBottom: "1px solid rgb(247,247,247)" }}>
      <Table sx={{ minWidth: 650, }} aria-label="simple table">
        <TableHead >
          <TableRow sx={{ backgroundColor: "rgb(209,209,209)", }}>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>
              Name
            </TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>
              Start At
            </TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>
              End At
            </TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>
              Renter
            </TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>
              Owner
            </TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>
              Cancel
            </TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "primary.main" }}>
              Review
            </TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px",], color: "secondary.main " }}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            loading ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Stack spacing={2} sx={{ width: "100%" }}>
                    <GeneralSkeletonLoading number={10} />
                  </Stack>
                </TableCell>
              </TableRow>
            ) : (
              transactions.length > 0 ? (
                transactions.map((transaction, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" sx={{ color: "primary.main" }}>
                        {
                          <Link to={`/product-detail/${transaction.productID._id}`} style={{ textDecoration: "none", color: theme.palette.primary.main, }}>
                            {transaction.productID.name}
                          </Link>
                        }
                      </TableCell>
                      <TableCell align="center" sx={{ color: "secondary.main " }}>
                        {formatDate(transaction.startDate)}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "secondary.main " }}>
                        {formatDate(transaction.endDate)}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "secondary.main " }}>
                        {transaction.userID._id === userId
                          ? "You"
                          : transaction.userID.username}
                      </TableCell>
                      <TableCell align="center">
                        {transaction.ownerID._id === userId ? "You" : transaction.ownerID.username}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "secondary.main " }}>
                        <Button
                          variant="text"
                          color="primary"
                          sx={{ fontSize: ["10px", "15px"] }}
                          disabled={handleCanselDisabled(transaction)}
                          onClick={() => handleCanselTransaction(transaction._id)}
                        >
                          Cancel
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="text"
                          color="primary"
                          sx={{ fontSize: ["10px",] }}
                          onClick={() =>
                            handlleNavigateToAddReview(transaction.productID._id)
                          }
                          disabled={(userId === transaction.ownerID._id || transaction.productID.isDeleted) ? true : false}
                        >
                          <AddIcon sx={{ fontSize: "20px" }} />
                        </Button>
                      </TableCell >
                      <TableCell
                        align="center"
                        sx={{ color: getStatusColor(transaction.status) }}
                      >
                        {transaction.status}
                      </TableCell>
                    </TableRow>
                  );
                })

              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box
                      sx={{ textAlign: "center", color: "primary.main", width: "100%", fontWeight: "bold" }}
                    >

                      No Products Found
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};


