import { Box, Button, Container, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
export const SuccessPayment = () => {
  const navigate = useNavigate();
  // back home page
  const handleBackHomePage = () => {
    navigate('/');
  };
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50vh'
        }}
      >
        <CheckCircleOutlineIcon color="success" style={{ fontSize: 100 }} />
        <Typography variant="h4" component="div" gutterBottom>
          Success!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your payment was successful.
        </Typography>
        <Button variant="contained" onClick={handleBackHomePage}>
          Go back to homepage
        </Button>
      </Box>
    </Container>
  );
}

