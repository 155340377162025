import { Container, Typography } from "@mui/material";
import FavoritesProductsList from "../components/Favourites/FavoritesProductsList"; 
export const FavoriteProducts = () => {
  
    return (
            <Container maxWidth="lg" sx={{py:"100px"}}>
                <Typography variant="h3" align="center" sx={{color:"primary.main",mb:"70px",fontWeight:"bold"}}>
                Favourites
                </Typography>
                <FavoritesProductsList/>
            </Container>
    );
};





