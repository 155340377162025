import { Box, Container, Typography } from '@mui/material'

import { MessagesHistory } from '../components/chat/MessagesHistory'

export const Chat = () => {
  return (
  <Box sx={{py:8}}> 
    <Typography variant="h4" align="center" sx={{color:"primary.main",pb:5,fontWeight:"bold",fontSize:["22px","30px"]}}>
    your Chatting with your friends
    </Typography>
    <Container>
    <MessagesHistory/>
 </Container>
      
  </Box>
  )
}

