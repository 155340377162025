import { Alert } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
export const AlertMessage = () => {
  const alert = useSelector((state) => state.alertDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    if (alert.message) {
      setTimeout(() => {
        dispatch(setAlertDetails({ type: "", message: "" }));
      }, 3000);
    }
  }, [alert.message, dispatch]);
  return (
    <>
      {alert.message ? (
        <Alert
          severity={alert.type}
          sx={{
            position: "fixed",
            top: "50px",
            left: "20px",
            zIndex: 3000,
            width: "300px",
            py: 2,
          }}
        >
          {alert.message}
        </Alert>
      ) : null}
    </>
  );
};
