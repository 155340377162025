import { Stack, Box, Typography, FormControl, InputLabel, Select, TextField, MenuItem, } from "@mui/material"
const country = ["United States", "Nigeria", "Ghana", "Kenya", "South Africa"];
import { styled } from "@mui/material/styles"
// conditionally render states based on country dinamically
const states = (country) => {
    switch (country) {
        case "United States":
            return ["California", "Florida", "New York", "Texas", "Washington"];
        case "Nigeria":
            return ["Abuja", "Lagos", "Kano", "Kaduna", "Enugu"];
        case "Ghana":
            return ["Accra", "Kumasi", "Tamale", "Takoradi", "Ho"];
        case "Kenya":
            return ["Nairobi", "Mombasa", "Kisumu", "Nakuru", "Eldoret"];
        case "South Africa":
            return ["Cape Town", "Johannesburg", "Durban", "Pretoria", "Bloemfontein"];
        default:
            return [""];
    }
}
const CustomTextFiled = styled(TextField)({
    color: "secondary.main",
    '& .MuiOutlinedInput-root': { // To remove outline
        backgroundColor: "white",
        '& fieldset': {
            borderColor: 'transparent',

        },
        '&:hover fieldset': {
            borderColor: 'transparent',

        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
    '& .MuiFormLabel-root': {
        color: 'black', // To change label color
    },
});


export const AdressInput = ({ user, handleChange }) => {
    return (
        <Box spacing={2} p={2} sx={{ borderRadius: "10px", backgroundColor: "#F7F7F7" }}>
            <Typography variant="h6" sx={{ color: "secondary.main", fontWeight: "500",py:2 }}>
                Home
            </Typography>
            <form>
                <Stack spacing={2}>
                    {/** country  */}
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"> Country </InputLabel>
                        <Select fullWidth value={user.address ? user.address.country ? user.address.country : "" : ""} name="country" label="Country" size='medium' onChange={handleChange}
                            sx={{
                                color: "secondary.main", backgroundColor: "white", borderRadius: "10px",
                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    border: 0,
                                },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    border: 0,
                                },

                            }}
                        >
                            {country.map((country, i) => {
                                return (
                                    <MenuItem key={i} value={country}>{country}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    {/** name */}
                
                    {/**  address  */}
                    <FormControl fullWidth>
                        <CustomTextFiled
                            name="address"
                            label="Address"
                            variant="outlined"
                            size="medium"
                            value={user.address ? user.address.address ? user.address.address : "" : ""}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {/** Apartment/Floor no.  */}
                    <FormControl fullWidth>
                        <CustomTextFiled
                            name="apartment"
                            label="Apartment/Floor no."
                            variant="outlined"
                            size="medium"
                            value={user.address ? user.address.apartment ? user.address.apartment : "" : ""}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {/** city  */}
                    <FormControl fullWidth>
                        <CustomTextFiled
                            name="city"
                            label="City"
                            variant="outlined"
                            size="medium"
                            value={user.address ? user.address.city ? user.address.city : "" : ""}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {/**Governorate */}
                    <FormControl fullWidth>
                        <CustomTextFiled
                            name="governorate"
                            label="Governorate"
                            variant="outlined"
                            size="medium"
                            value={user.address ? user.address.governorate ? user.address.governorate : "" : ""}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {/** state  */}
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"> State </InputLabel>
                        <Select fullWidth value={user.address ? user.address.state ? user.address.state : "" : ""} name="state" label="State" size='medium' onChange={handleChange}
                            sx={{
                                color: "secondary.main", backgroundColor: "white", borderRadius: "10px",
                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    border: 0,
                                },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    border: 0,
                                },

                            }}
                        >
                            {states(user.address.country).map((state, i) => {
                                return (
                                    <MenuItem key={i} value={state}>{state}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    {/** zip code  */}
                    <FormControl fullWidth>
                        <CustomTextFiled
                            name="zipCode"
                            label="Zip Code"
                            variant="outlined"
                            size="medium"
                            value={user.address ? user.address.zipCode ? user.address.zipCode : "" : ""}

                            onChange={handleChange}
                        />
                    </FormControl>
                    {/** phone number  */}
                  {/*   <FormControl fullWidth>
                        <CustomTextFiled
                            name="phoneNumber"
                            label="Phone Number"
                            variant="outlined"
                            size="medium"
                            value={user.phoneNumber ? user.phoneNumber : ""}
                            onChange={handleChange}
                        />
                    </FormControl> */}
                </Stack>
            </form>
        </Box>
    )
}
