import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, Form, Field } from "formik";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  FormControl,
  TextField,
  Box, Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import { getUser } from "../../redux/slices/AuthUser";
import axiosInstance from "../../axios/axiosInstance";
import { NotifyMe } from "./NotifyMe";
const CustomDatePicker = styled(DatePicker)({
  backgroundColor: "white",
  color: "secondary.main",
  borderRadius: "20px",
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
      border: 'none',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
      border: 'none',
    },
  },

  '& .MuiFormLabel-root': {
    color: 'black',
  },
});

const RentDate = ({ product }) => {
  const navigate = useNavigate();
  const authUser = useSelector(getUser); // get userid from redux
  const dispatch = useDispatch();
  const today = new Date();
  const initialValues = {
    From: null,
    To: null,
    number: "",
  };

  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };

  // handle disabled dates
  // set unavailable dates to dayjs in array of objects 
  const disabledDates = product?.unavailability?.map(({ start_date, end_date }) => ({
    start: dayjs(start_date),
    end: dayjs(end_date),
  }));
  const isDateDisabled = (date) => { // Datepicker will pass the date to this function automatically
    const selectedDate = dayjs(date);
    return disabledDates.some(
      ({ start, end }) =>
        selectedDate.isBetween(
          start.startOf("day"), // Start of the day for start date
          end.endOf("day"), // End of the day for end date
          { inclusive: "[]" } // Include start and end dates
        ) || selectedDate.isSame(start.startOf("day")) // Check if the selected date is the same as the start date
    );
  };
  // handle submit
  const onSubmit = (values) => {
    // handle validation errors and show alert
    if (product.per === "Day") {
      if (values.From > values.To) {
        setAlertDetailsToRedux("error", "End Date must be after Start Date");
        return;
      }
      if (!values.From || !values.To) {
        setAlertDetailsToRedux(
          "error",
          "Please choose start date and end date"
        );

        return;
      }
    } else {
      if (values.number <= 0) {
        setAlertDetailsToRedux("error", "Number must be greater than 0");

        return;
      }
      if (!values.From) {
        setAlertDetailsToRedux("error", "Please choose start date");

        return;
      }
    }

    // check if user rent his own product or not
    if (authUser) {
      if (authUser?.id === product.userID._id) {
        setAlertDetailsToRedux("error", "You can't rent your own product");


        return;
      }
    }

    // check if product per Day or other and calculate end date
    if (product.per !== "Day") {
      let endDate;
      if (product.per === "Week") {
        endDate = dayjs(values.From)
          .add(values.number, "week")
          .subtract(1, "day");
      } else if (product.per === "Month") {
        endDate = dayjs(values.From)
          .add(values.number, "month")
          .subtract(1, "day");
      } else if (product.per === "Year") {
        endDate = dayjs(values.From)
          .add(values.number, "year")
          .subtract(1, "day");
      }
      values.To = endDate;
    }

    // convert date to string
    const fromDate = dayjs(values.From).format("ddd, MMM DD, YYYY");
    const toDate = dayjs(values.To).format("ddd, MMM DD, YYYY");

    const finalValues = {
      startDate: fromDate,
      endDate: toDate,
      productID: product._id,
      userID: authUser?.id,
    };
    // send request to the server to create transaction
    axiosInstance
      .post(
        "/transactions/create",
        finalValues
      )
      .then(() => {
        setAlertDetailsToRedux(
          "success",
          "we will send you a message when the owner accept your request"
        );

      })
      .catch((err) => {
        setAlertDetailsToRedux("error", err.response.data.error);

      });
  };
// GO TO CHAT PAGE
const GoChatPage = (user) => {
  navigate(`/contactWithUser/${user?._id}/${user?.username || "user"}`)
}
  return (

    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ setFieldValue, values }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Form>
            <Box sx={{ width: "100%", backgroundColor: "#F7F7F7 ", p: 2, borderRadius: "5px" }}>
              <Typography variant="h6" component="h2" color="textPrimary" mb={2}>
                Select your rental period
              </Typography>
              <Stack spacing={2}>

                <FormControl fullWidth>
                  <Field
                    fullWidth
                    name="From"
                    as={CustomDatePicker}
                    value={dayjs(values.From)} // convert to dayjs
                    minDate={dayjs(today)}
                    shouldDisableDate={isDateDisabled}
                    disabled={!product?.available} // disable input if product owner close the product 
                    onChange={(newDate) =>
                      setFieldValue("From", dayjs(newDate))
                    } // convert to dayjs

                    slotProps={{ // actions
                      actionBar: {
                        actions: ["accept", "cancel"],
                      },
                    }}
                    closeOnSelect={false}
                  />
                </FormControl>

                {
                  // chek if product per Day or other and show the right date
                  product?.per === "Day" ? (

                    <FormControl fullWidth>
                      <Field
                        fullWidth
                        name="To"
                        as={CustomDatePicker}
                        value={dayjs(values.To)} // convert to dayjs
                        minDate={dayjs(today)}
                        shouldDisableDate={isDateDisabled}
                        disabled={!product?.available} // disable input if product owner close the product
                        onChange={(newDate) =>
                          setFieldValue("To", dayjs(newDate))
                        } // convert to dayjs
                        slotProps={{
                          actionBar: {
                            actions: ["accept", "cancel"],
                          },
                        }}
                        closeOnSelect={false}
                      />
                    </FormControl>

                  ) : (

                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        name="number"
                        type="number"
                        label={`Number of ${product?.per}`}
                        value={values.number}
                        disabled={!product?.available} // disable input if product owner close the product
                        onChange={(event) =>
                          setFieldValue("number", event.target.value)
                        }
                      />
                    </FormControl>

                  )
                }
                {/* render notify me  when product is unavailable*/}
                <NotifyMe />
                {/* render message if product is not unavailable */}
                {
                  !product?.available && (

                    <Typography variant="caption" component="p" color="error">
                      Sorry this product is not available for rent now
                    </Typography>

                  )
                }
              </Stack>
            </Box>
            <Stack spacing={2} sx={{ my: 3, }}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{ backgroundColor: "#F7F7F7", color: "secondary.main", "&:hover": { backgroundColor: "#F7F7F7", color: "secondary.main" } }}
                onClick={() => GoChatPage(product.userID)} // go to chat page
              >
                Chat
              </Button>
              <Button
                fullWidth
                variant="contained"
                size="large"
                type="submit"
                disabled={!product?.available} // disable button if product owner close the product
              >
                Rent Now
              </Button>
            </Stack>

          </Form>
        </LocalizationProvider>
      )}
    </Formik>


  );
};
export default RentDate;
