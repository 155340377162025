import { useEffect, useState } from 'react'
import axiosInstance from '../../../axios/axiosInstance';
import { Stack, Typography } from '@mui/material';
import { ReviewItem } from './ReviewItem';
import { ShowCommentAndReviewSkeleton } from '../../general/loading/ShowCommentAndReviewSkeleton';
export const ShowReviews = ({ productID }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    const fetchComments = async () => {
      try {
        const res = await axiosInstance.get(
          `/reviews/products/${productID}`
        );
        setReviews(res.data.reviews);
      } catch (_) {/**/}
      finally{
        setLoading(false)
      }
    };

    fetchComments();
  }, [productID]);
  return (
    <>
      {
        !loading ? (
          <Stack spacing={2}>
            {
              reviews.length > 0 ? (
                reviews.map(review => {
                  return <ReviewItem key={review._id} review={review} />
                })
              ) : (
                <Typography variant="h6" color="primary.main">No reviews yet</Typography>
              )
            }
          </Stack>
        ) : (
          <ShowCommentAndReviewSkeleton />
        )
      }
    </>
  )
}
