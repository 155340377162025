import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import { HeaderNavigation } from "../components/admin/HeaderNavigation";

export const Admin = () => {
  return (
    <>
      <HeaderNavigation />
      <Container maxWidth="lg" sx={{ pb: 4 }}>
        <Outlet />
      </Container>
    </>
  );
};


