import { useEffect, useRef, useState } from "react";
import {
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUser } from "../../../redux/slices/AuthUser";
import { AppBars } from "./AppBars";
import { SettingBar } from "./SettingBar";
import { AsideMobile } from "./AsideMobile";
function Navbar() {
  const navbarRef = useRef(null);
  const path = useLocation();
  const authUser = useSelector(getUser); // get user id from redux
  const [openStack, setOpenStack] = useState(false);
  // handle open stack
  const handleOpenStack = () => {
    setOpenStack(!openStack);
  }

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  useEffect(() => {
    // if path changes then set openStack to false
    setOpenStack(false)
  }, [path.pathname])
  return (
    <Box sx={{ width: "100%" }}>
      <AppBars handleOpenDrawer={handleOpenDrawer} handleOpenStack={handleOpenStack} navbarRef={navbarRef} user={authUser?.user} />
      {/*box for user settings*/}
      <SettingBar navbarRef={navbarRef} openStack={openStack} />
      {/* Drawer with tabs for mobile */}
      <AsideMobile openDrawer={openDrawer} handleCloseDrawer={handleCloseDrawer} user={authUser?.user} />
    </Box>

  );
}

export default Navbar;
