import { useDispatch, useSelector } from "react-redux";
import { setActiveCategory } from "../../../redux/slices/filterSlice";
import { Box, Stack, Typography, useTheme } from "@mui/material";
/* import categories from "../../../data/categories"; */
import { useEffect, useState } from "react";
import axiosInstance from "../../../axios/axiosInstance";
import { GeneralSkeletonLoading } from "../../general/loading/GeneralSkeletonLoading";
const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const activeCategory = useSelector(
    (state) => state.filter.filterKeys.category
  );
  const theme = useTheme();

  const handleItemClick = (category) => {
    dispatch(setActiveCategory(category));
  };

  // get all categories from server
  useEffect(() => {
    setLoading(true);
    axiosInstance.get("/products/categories").then((res) => {
      setCategories(res.data);
      setLoading(false);
    }).catch(() => {
      setCategories([]);
    });

  }, []);
  return (
    <Box>
      {
        loading ? (
          <Stack spacing={2} pt={3}>
            <GeneralSkeletonLoading number={10} />
          </Stack>
        ) : (
          <>
            <Typography
              component="div"
              variant="subtitle1"
              onClick={() => handleItemClick(null)}
              sx={{
                cursor: "pointer",
                transition: "color 0.3s, transform 0.3s ease-in-out",
                textTransform: "capitalize",
                marginTop: 4,
                marginBottom: 1,
                width: "fit-content",
                fontSize: ["14px", "14px", "16px"],

                color:
                  activeCategory === null ? theme.palette.primary.main : "inherit",
                fontWeight: activeCategory === null ? "bolder" : "normal",
                "&:hover": {
                  color: theme.palette.primary.main,
                  transform: 'scale(1.05)',
                },
              }}
            >
              ALL CATEGORIES
            </Typography>
            {categories.map((category) => (
              <Typography
                key={category._id}
                component="div"
                variant="subtitle1"
                onClick={() => handleItemClick(category._id)}
                sx={{
                  cursor: "pointer",
                  transition: "color 0.3s, transform 0.3s ease-in-out",
                  marginBottom: 1,
                  width: "fit-content",
                  fontSize: "17px",
                  textTransform: "capitalize",
                  color:
                    activeCategory === category._id
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main,
                  fontWeight: activeCategory === category ? "bolder" : "normal",
                  "&:hover": {
                    color: theme.palette.primary.main,
                    transform: 'scale(1.05)',
                  },
                }}
              >
                {category.name}
              </Typography>
            ))}
          </>
        )
      }
    </Box>
  );
};

export default CategoryList;

