import { Box, Button, Container, InputAdornment, TextField, Typography } from '@mui/material'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useState } from 'react';
import { Link } from 'react-router-dom';
export const RecieveYourReferralsCode = () => {
    const [email, setEmail] = useState('')
    const [sended, setSended] = useState(false)

    // handle email change
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    // handle form submit
    const handleSubmit = (e) => {
        e.preventDefault()
        setSended(true)
    }
    return (
        <Box my={8}>
            <Container maxWidth="sm">
                <Box sx={{ backgroundColor: "secondary.main", borderRadius: "20px", p: 8 }}>
                    {
                        sended ? (
                            <Box sx={{py:5}}>
                                <Typography variant="h5" component="h2" sx={{ fontFamily: "Poppins-Bold", color: "primary.main", fontSize: ["25px"], textAlign: "center" }}>
                                    Succesfully Sent!
                                </Typography>
                                <Typography variant="body1" component="p" sx={{ color: "white", fontFamily: "Poppins", fontWeight: "500", fontSize: "16px", textAlign: "center", marginTop: "20px" }}>
                                    Once your friend uses the code, you’ll get 10% OFF
                                    <br />
                                    on your next purchase.
                                </Typography>

                                <Button component={Link} to="/" fullWidth variant="contained" sx={{ backgroundColor: "primary.main", color: "white", fontFamily: "Poppins-Bold", fontSize: "16px", borderRadius: "10px", padding: "10px", mt: 4 }}>
                                    Done
                                </Button>

                            </Box>
                        ) : (
                            <Box>

                                <Typography variant='h4' component="h3" sx={{ color: "white", textAlign: "center", fontFamily: "Poppins-Bold" }}>
                                    Recieve Your Referral
                                    <br />
                                    Code Now on Your Email
                                </Typography>
                                <Typography variant='body2' sx={{ color: "white", textAlign: "center", mt: 2 }}>
                                    Please enter your login credentials to recieve referral code.
                                </Typography>

                                <TextField
                                    fullWidth
                                    placeholder='Enter your email '
                                    id="outlined-start-adornment"
                                    sx={{
                                        backgroundColor: "white", mt: 3,
                                        // remove outline
                                        borderRadius: "10px",
                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                    onChange={handleEmailChange}

                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <MailOutlineIcon />
                                        </InputAdornment>,
                                    }}
                                />

                                <Button fullWidth variant="contained" sx={{ backgroundColor: "primary.main", color: "white", fontFamily: "Poppins-Bold", fontSize: "16px", borderRadius: "10px", padding: "10px", marginTop: "20px", }} onClick={handleSubmit}>
                                    Send
                                </Button>

                            </Box>
                        )
                    }
                </Box>
            </Container>

        </Box>
    )
}
