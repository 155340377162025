import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
const time = ["Day", "Week", "Month", "Year"];
const conditions = ["New", "Used"];
export const ProductFields = ({ editValues, handleChange, categories }) => {
    return (

        <Stack spacing={2} sx={{ margin: "auto" }}>
            <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                name="name"
                value={editValues.name}
                onChange={handleChange}
            />
            <TextField
                id="outlined-basic"
                label="Price"
                variant="outlined"
                name="price"
                value={editValues.price}
                onChange={handleChange}
            />
            <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                name="description"
                value={editValues.description}
                onChange={handleChange}
            />

            <FormControl fullWidth>
                <InputLabel htmlFor="Per">Per</InputLabel>
                <Select
                    id="Per"
                    name="per"
                    label="Per"
                    value={editValues.per}
                    fullWidth
                    onChange={handleChange}
                >
                    {time.map((time) => (
                        <MenuItem key={time} value={time}>
                            {time}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel htmlFor="Condition">Condition</InputLabel>
                <Select
                    id="Condition"
                    name="condition"
                    label="Condition"
                    value={editValues.condition}
                    fullWidth
                    onChange={handleChange}
                >
                    {conditions.map((condition) => (
                        <MenuItem key={condition} value={condition}>
                            {condition}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel htmlFor="Category">Category</InputLabel>
                <Select
                    id="category"
                    name="category"
                    label="Category"
                    value={editValues.category}
                    fullWidth
                    onChange={handleChange}
                >
                    {categories.map((category) => (
                        <MenuItem key={category} value={category._id}>
                            {category.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel htmlFor="Available">Available</InputLabel>
                <Select
                    id="Available"
                    name="available"
                    label="Available"
                    value={editValues.available}
                    fullWidth
                    onChange={handleChange}
                >
                    <MenuItem value={true}>Open</MenuItem>
                    <MenuItem value={false}>Close</MenuItem>
                </Select>
            </FormControl>

        </Stack>
    )
}
