import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const darkModeSlice = createSlice({
  name: "Mode",
  initialState: { dark: false },
  reducers: {
    setMode: (state, { payload }) => {
      state.dark = payload;
    },
  },
});
const persistConfig = {
  key: "mode",
  storage,
};
export const { setMode } = darkModeSlice.actions;
const persistedDarkModeReducer = persistReducer(
  persistConfig,
  darkModeSlice.reducer
);
export default persistedDarkModeReducer;
