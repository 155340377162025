import { Box, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { useEffect, useState } from 'react';
import axiosInstance from '../../axios/axiosInstance';
import { useDispatch } from "react-redux";
import { setAlertDetails } from '../../redux/slices/AlertDetailsSlice';
import { useParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import axios from 'axios';

export const EditImages = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [pictures, setPictures] = useState([]);
    const [loading, setLoading] = useState(pictures.map(() => false));
    const [newImageLoading, setNewImageLoading] = useState(false); // for new image

    const getPresignedUrl = async (image) => {
        try {
            if (!image.type.startsWith("image/")) {
                throw new Error("Unsupported file format, only images are allowed");
            }
            const response = await axiosInstance.post(
                "/products/upload-image-url",
                {
                    contentType: image.type,
                }
            );
            const presignedUrl = response.data.presignedUrl;
            const imageUrl = response.data.imgUrl;
            if (response.status === 200) {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                }
                const compressedImage = await imageCompression(image, options);
                await axios.put(presignedUrl, compressedImage, {
                    headers: {
                        "Content-Type": image.type,
                    },
                });
                return imageUrl;
            } else {
                throw new Error("Failed to get presigned URL");
            }
        } catch (error) {
            throw new Error("Failed to upload image");
        }
    };

    const handleImageChange = async (event, index) => {
        if (index === pictures.length) {
            setNewImageLoading(true); // Add this line
        } else {
            setLoading(prevState => {
                const newLoading = [...prevState];
                newLoading[index] = true;
                return newLoading;
            });
        }

        const selectedImage = event.target.files[0];
        try {
            const imageUrl = await getPresignedUrl(selectedImage, index);
            if (imageUrl) {
                const newPictures = [...pictures.slice(0, index), imageUrl, ...pictures.slice(index + 1)];
                setPictures(newPictures);
                await axiosInstance.put(`/products/${id}`, { pictures: newPictures });
                setAlertDetailsToRedux("success", "Image updated successfully");
            } else {
                setAlertDetailsToRedux("error", "Failed to get image URL");
            }
        } catch (error) {
            setAlertDetailsToRedux("error", error.message);
        } finally {
            if (index === pictures.length) {
                setNewImageLoading(false);
            } else {
                setLoading(prevState => {
                    const newLoading = [...prevState];
                    newLoading[index] = false;
                    return newLoading;
                });
            }
        }
    };
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }));
    };

    const deleteImage = async (index) => {
        try {
            // prevent user from deleting all images from product , at least one image should be available
            if (pictures.length === 1) {
                setAlertDetailsToRedux("error", "At least one image should be available");
                return;
            }
            const newPictures = [...pictures];
            newPictures.splice(index, 1);

            await axiosInstance.put(`/products/${id}`, { pictures: newPictures });
            setPictures(newPictures);
            setAlertDetailsToRedux("success", "Image deleted successfully");
        } catch (error) {
            setAlertDetailsToRedux("error", error.response.data.error);
        }
    };

    useEffect(() => {
        axiosInstance
            .get(`/products/${id}`)
            .then((res) => {
                setPictures(res.data.product.pictures);
            })
            .catch((err) => {
                setAlertDetailsToRedux("error", err.response.data.error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <Stack direction="row" sx={{ flexWrap: "wrap", gap: 2 }} >
            {
                pictures.map((picture, i) => {
                    return (
                        <Box key={i} sx={{ position: "relative", width: ["135px", "150px"], height: "100px", border: "1px solid #8080801f", borderRadius: "5px", boxShadow: "0px 0px 3px 0px #8080807c" }}>
                            <img src={picture} alt="product" style={{ width: "100%", height: "100%", borderRadius: "5px" }} />
                            {loading[i] ? (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        color: "white",
                                        padding: "10px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    Loading...
                                </Box>
                            ) : null}
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "0",
                                    right: "5px",
                                    color: "primary.main",
                                    backgroundColor: "white",
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer"
                                }}
                            >
                                <DeleteIcon onClick={() => deleteImage(i)} />
                            </Box>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "0",
                                    right: "50px",
                                    color: "secondary.main",
                                    backgroundColor: "white",
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer"
                                }}
                            >
                                <Box sx={{ position: "relative" }}>
                                    <label htmlFor={`upload-image-${i}`} style={{ cursor: "pointer" }}>
                                        <UpgradeIcon sx={{}} />
                                        <input
                                            id={`upload-image-${i}`}
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => handleImageChange(e, i)}
                                            style={{ display: "none" }}
                                        />
                                    </label>
                                </Box>
                            </Box>
                        </Box>
                    );
                }
                )}
            {/* Add new image */}
            <Box sx={{ position: "relative", width: ["135px", "150px"], height: "100px", border: "1px solid #8080801f", boxShadow: "0px 0px 3px 0px #8080807c" }}>
                {newImageLoading ? (
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                            zIndex: 1000
                        }}
                    >
                        Uploading...
                    </Box>
                ) : null}
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "primary.main",
                        backgroundColor: "white",
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer"
                    }}
                >
                    <label htmlFor={`upload-image-new`} style={{
                        cursor: "pointer", width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <UpgradeIcon sx={{ fontSize: "40px" }} />
                        <input
                            id={`upload-image-new`}
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageChange(e, pictures.length)}
                            style={{ display: "none" }}
                        />
                    </label>

                </Box>
            </Box>

        </Stack>
    )
}
