
import { Box, Button } from "@mui/material";
import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps";
export const Maps = ({ values, setFieldValue }) => {

    // auto detect location
    const handleAutoDetectLocation = (setFieldValue) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const newPosition = { lat: latitude, lng: longitude };
                    // Set the detected location as initial values
                    setFieldValue("location", newPosition);

                },
            );
        }
    };

    return (
        <APIProvider apiKey='AIzaSyBgwMV6zIMMSXiui7N3hqvyraHuSlUQ2p0'>
            <Map
                mapId="e8b1b7b3b3b4b3b3hgctt"
                zoom={2}
                center={values.location}
                onClick={(event) => {
                    const position = event.detail.latLng
                    setFieldValue("location", position);

                }}
                style={{ height: "250px", width: "100%" }}
            >
                <AdvancedMarker position={values.location} />
            </Map>
            <Box>
                <Button onClick={() => handleAutoDetectLocation(setFieldValue)} sx={{ textAlign: "center" }}>Auto Detect Location</Button>
            </Box>
        </APIProvider>
    )
}
