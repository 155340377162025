import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { setAlertDetails } from "../redux/slices/AlertDetailsSlice";
import { useParams, useNavigate } from "react-router-dom";
import { reviewValidationSchema } from "../validation/validationSchemas";
import axiosInstance from "../axios/axiosInstance";
import { useEffect, useState } from "react";
import { SkeletonInCommentAndReviews } from "../components/general/loading/SkeletonInCommentAndReviews";

export const EditReview = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reviewContent, setReviewContent] = useState("");
    const { productID, reviewID } = useParams();

    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };

    useEffect(() => {
        // fetch product details
        const fetchProduct = async () => {
            setLoading(true)
            axiosInstance
                .get(
                    `/products/${productID}`
                )
                .then((res) => {
                    setProduct(res.data.product)

                }).catch(() => {
                    setProduct(null);

                }).finally(() => {
                    setLoading(false)
                })
        };
        // fetch review 
        const fetchReview = async () => {
            axiosInstance
                .get(
                    `/reviews/${reviewID}`
                )
                .then((res) => {

                    setReviewContent(res.data.review.review);

                })
        };
        fetchProduct();
        fetchReview();
    }, [productID, reviewID]);

    const initialValues = {
        review: reviewContent,
    };
    // handle form submission
    const handleSubmit = (values, { resetForm }) => {
        axiosInstance
            .put(
                `/reviews/${reviewID}`,
                { review: values.review }
            )
            .then(() => {
                setReviewContent(values.review);
                setAlertDetailsToRedux("success", "Comment updated successfully");
                resetForm();
                navigate(`/product-detail/${productID}`);
            })
            .catch((err) => {
                setAlertDetailsToRedux("error", err.response.data.error);
            });
    };

    return (
        <Box sx={{ pt: 8 }}>
            <Typography
                variant="h4"
                sx={{ color: "primary.main", fontWeight: "bold", textAlign: "center", mb: 8 }}
            >
                Edit Review Product
            </Typography>
            <Box sx={{ backgroundColor: "text.main", py: 5 }}>
                <Container maxWidth="lg">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={7}>
                            {
                                loading ? (
                                    <SkeletonInCommentAndReviews />
                                ) : (
                                    <Grid container spacing={3} >
                                        <Grid item xs={12} md={4}>
                                            <Paper sx={{ width: "100%", height: "150px", borderRadius: "15px" }}>
                                                <img src={product ? product.pictures[0] : ""} alt="product" style={{ width: "100%", height: "100%", borderRadius: "15px", objectFit: "contain" }} />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Box sx={{ p: 2 }}>
                                                <Typography variant="h6" sx={{ color: "secondary.main", fontFamily: "Poppins-Bold" }}>
                                                    {product ? product.name : ""}
                                                </Typography>
                                                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                                                    {product ? product.description : ""}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={reviewValidationSchema}
                                enableReinitialize={true}
                            >
                                {() => (
                                    <Form>
                                        <Stack spacing={3}>
                                            <Box sx={{ backgroundColor: "#F4EFEA", p: 3, borderRadius: "10px" }}>
                                                <Typography variant="h6" sx={{ color: "secondary.main", fontFamily: "Poppins-Bold", pb: 2 }}>
                                                    Edit Your Review
                                                </Typography>
                                                <Field
                                                    name="review"
                                                    as={TextField}
                                                    fullWidth
                                                    multiline
                                                    rows={5}
                                                    placeholder="Edit your review here..."
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': { // To remove outline
                                                            backgroundColor: "white",
                                                            '& fieldset': {
                                                                borderColor: 'transparent',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: 'transparent',
                                                                borderRadius: "20px", // To remove round shape
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: 'transparent',
                                                            },
                                                        },
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="review"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Box>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                                sx={{ p: 1 }}
                                            >
                                                Save Review
                                            </Button>
                                        </Stack>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>

                </Container>
            </Box>
        </Box>
    );
};
