import { Box, Button, FormControl, MenuItem, Select, Stack } from "@mui/material";
import { InputLabel } from "@mui/material";
import { useContext, useState } from "react";
import { UserTransactionsSearch } from "../../context";
import SearchIcon from "@mui/icons-material/Search";
export const Searchkeys = () => {
  const [name, setName] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const { changeSearchKeys } = useContext(UserTransactionsSearch);
  // handle search by status
  const handleChangeStatus = (e) => {
    changeSearchKeys({ name: "", status: e.target.value });
    setStatusValue(e.target.value);
  };
  // handle search by name
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  // handle search by name
  const handleSearchByName = () => {
    changeSearchKeys({ name: name, status: "" });
    // clear name input
    setName("");
  };
  // handle enter key press
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      changeSearchKeys({ name: "", status: e.target.value });
    }
  };
  // clear search keys
  const clearSearchKeys = () => {
    changeSearchKeys({ name: "", status: "" });
    setName("");
    setStatusValue("");
  };

  const status = [
    "Pending",
    "Approved",
    "Rejected",
    "Ongoing",
    "Completed",
    "Cancelled",
  ];
  return (


    <Stack spacing={2}>

      <Stack spacing={2} direction="row" sx={{ mt: 3, backgroundColor: "rgb(247,247,247)", width: ["100%"], borderRadius: "10px" }}>
        <input
          type="email"
          placeholder="Search by name"
          style={{
            padding: "20px",
            borderRadius: "10px",
            border: "none",
            width: "80%",
            outline: "none",
            backgroundColor: "rgb(247,247,247)",
            fontWeight: "bold",
            fontSize: "15px",
          }}
          name="name"
          value={name}
          onChange={(e) => handleNameChange(e)}
          onKeyDown={handleKeyDown}
        />
        <Box sx={{ width: "20%", backgroundColor:  "rgb(247,247,247)", borderRadius: "10px", p: 1, display: "flex", alignItems: "center" }}>
          <Box
            sx={{ backgroundColor: "primary.main", width: "100%", height: "100%", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}
            onClick={handleSearchByName}
          >
            <SearchIcon sx={{ color: "white", cursor: "pointer" }} />
          </Box>
        </Box>
      </Stack>

      <Stack spacing={2} direction="row" sx={{ mt: 3, width: ["100%"], borderRadius: "10px" }}>
        <FormControl sx={{ width: "70%" }}>
          <InputLabel id="Status" sx={{ color: "secondary.main", fontWeight: "bold" }}>Status</InputLabel>
          <Select
            fullWidth
            name="status"
            label="Status"
            id="status"
            value={statusValue}
            onChange={(e) => handleChangeStatus(e)}
            sx={{
              color: "secondary.main", backgroundColor: "rgb(247,247,247)", borderRadius: "10px", ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
            }}
          >
            {status.map((status) => {
              return (
                <MenuItem
                  key={status}
                  value={status}
                  sx={{ fontSize: ["15px", "18px"] }}
                >
                  {status}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button

          variant="contained"
          sx={{ fontSize: "12px", width: "30%", fontWeight: "bold" }}
          onClick={clearSearchKeys}
        >
          Show All
        </Button>

      </Stack>

    </Stack>

  );
};
