import { Box, Button, Stack, } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";

export const ContactUsForm = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState({ name: "", email: "", phone: "", message: "" });
    const [loading, setLoading] = useState(false);
    const handleChangeValues = (e) => {
        setFormValues({ ...formValues, [e.target.id]: e.target.value });
    };

    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };

    // handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            service_id: import.meta.env.VITE_VERCEL_EMAIL_SERVICE_ID,
            template_id: import.meta.env.VITE_VERCEL_EMAIL_TEMPLATE_ID,
            user_id: import.meta.env.VITE_VERCEL_EMAIL_USER_ID,
            template_params: {
                'to_name': import.meta.env.VITE_VERCEL_RECEIVER_EMAIL_NAME,
                'from_name': formValues.name,
                'from_email': formValues.email,
                "replay_to": formValues.email,
                'phone': formValues.phone,
                'message': formValues.message
            }
        };
        axios.post('https://api.emailjs.com/api/v1.0/email/send', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (response.status === 200) {
                setAlertDetailsToRedux("success", "Message sent successfully");
                // Clear form values
                setFormValues({ name: "", email: "", phone: "", message: "" });
            }
        }).catch(() => {
            setAlertDetailsToRedux("error", "Failed to send message");
        }).finally(() => {
            setLoading(false);
        });

    };
    return (
        <Box py={8}>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2} direction="column" justifyContent="center"  >
                    <input
                        type="text"
                        required
                        id="name"
                        name="name"
                        style={{
                            padding: "20px 10px 20px 10px",
                            borderRadius: "5px",
                            border: "none",
                            outline: "none",
                            fontSize: "17px",
                            color: theme.palette.secondary.main,
                            boxShadow: "0px 0px 5px 0px #F3F3F3",
                         

                        }}
                        placeholder="First Name"
                        onChange={handleChangeValues}
                        value={formValues.name}
                    />


                    <input
                        type="email"
                        id="email"
                        name="email"
                        style={{
                            padding: "20px 10px 20px 10px",
                            marginTop: "10px",
                            marginBottom: "10px",
                            borderRadius: "5px",
                            border: "none",
                            outline: "none",
                            fontSize: "17px",
                            color: theme.palette.secondary.main,
                            boxShadow: "0px 0px 5px 0px #F3F3F3",

                        }}
                        onChange={handleChangeValues}
                        value={formValues.email}
                        placeholder="Email"
                    />


                    <input

                        required
                        id="phone"
                        name="phone"
                        placeholder="Phone Number"
                        style={{
                            padding: "20px 10px 20px 10px",

                            marginTop: "10px",
                            marginBottom: "10px",
                            borderRadius: "5px",
                            border: "none",
                            outline: "none",
                            fontSize: "17px",
                            color: theme.palette.secondary.main,
                            boxShadow: "0px 0px 5px 0px #F3F3F3",

                        }}
                        onChange={handleChangeValues}
                        value={formValues.phone}
                    />
                    <textarea
                        type="text"
                        required
                        rows={8}
                        placeholder="Message"
                        id="message"
                        name="message"
                        style={{
                            width: "100%",
                            padding: "30px",
                            marginTop: "10px",
                            marginBottom: "10px",
                            borderRadius: "5px",
                            border: "none",
                            height: "300px",
                            outline: "none",
                            fontSize: "17px",
                            color: theme.palette.secondary.main,
                            boxShadow: "0px 0px 5px 0px #F3F3F3",
                        }}
                        onChange={handleChangeValues}
                        value={formValues.message}

                    />
                    <Button variant="contained" sx={{ width: "fit-content", px: 4, py: 1 }} type="submit">
                        {loading ? "Sending..." : "Send Message"}
                    </Button>
                </Stack>

            </form>
        </Box>
    );
}
