import {
  createSelector,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import axiosInstance from "../../axios/axiosInstance";

// create an async thunk for fetching user
export const fetchUser = createAsyncThunk("AuthUser/fetchUser", async (id) => {
  const response = await axiosInstance.get(`/user/${id}`);
  return response.data.user;
});

const AuthUserSlice = createSlice({
  name: "AuthUser",
  initialState: {
    user: null,
    exp: null,
    id: null,
  },
  reducers: {
    setAuthUser: (state, { payload }) => {
      state.user = payload.user;
      state.exp = payload.exp;
      state.id = payload.id;
    },
    // clear auth user
    clearUser: (state) => {
      state.user = null;
      state.exp = null;
      state.id = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

const persistConfig = {
  key: "user",
  storage,
};

export const getUser = createSelector(
  (state) => state.authUser,
  (authUser) => authUser
);

export const { setAuthUser, clearUser } = AuthUserSlice.actions;

const persistedAuthUserReducer = persistReducer(
  persistConfig,
  AuthUserSlice.reducer
);

export default persistedAuthUserReducer;
