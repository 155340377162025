import { Box, Typography } from "@mui/material"
import { AboutReferrals } from "../components/Referrals/AboutReferrals"
import { HowItWork } from "../components/Referrals/HowItWork"



export const Referrals = () => {
  return (
    <Box sx={{ pt: 8 }}>

      <Typography variant="h2" component="h1" sx={{ textAlign: "center", fontFamily: "Poppins-Bold", color: "primary.main" }}>Referrals</Typography>
      <AboutReferrals />
      <HowItWork />

    </Box>
  )
}

