import { createContext } from "react";

export const UserContext = createContext();// create context for user search keys in admin dashboard

export const AdminTransactionsSearch = createContext(); // create context for admin transactions search keys

export const UserTransactionsSearch = createContext();// create context for user transactions search keys

export const UserProductsSearch = createContext();// create context for user products search keys

export const NotificationContext = createContext();// create context for notification