import { Container, Typography } from '@mui/material'
import PopularProductsList from '../components/popular-products/PopularProductsList'

export const PopularProducts = () => {
    return (
        <Container maxWidth="lg" sx={{ py: "100px" }}>
            <Typography variant="h3" align="center" sx={{ color: "primary.main", mb: "70px", fontWeight: "bold",fontFamily:"Poppins-Bold" }}>
                Popular Products
            </Typography>
             <PopularProductsList/>
        </Container>

    )
}
