import { Stack, TextField } from '@mui/material'
import { UserProfileInfoSkeleton } from '../general/loading/UserProfileInfoSkeleton'

export const AboutUser = ({ user }) => {
    return (
        <>
            {
                user ? (
                    <Stack spacing={1}>
                        {/** if displayName not exist not render it*/}
                        {
                            user?.displayName && (
                                <Stack spacing={1}>
                                    <label>Name</label>
                                    <TextField
                                        value={`${user?.displayName}` || ''}
                                        disabled
                                    />
                                </Stack>
                            )
                        }
                        {
                            user && user.username && (
                                <Stack spacing={1}>
                                    <label>Username</label>
                                    <TextField
                                        value={`@${user.username}` || ''}
                                        disabled
                                    />
                                </Stack>
                            )
                        }
                        {
                            user?.email && (
                                <Stack spacing={1}>
                                    <label>Email</label>
                                    <TextField
                                        value={user?.email || ''}
                                        disabled
                                    />
                                </Stack>
                            )
                        }
                        {
                            user?.phoneNumber && (
                                <Stack>
                                    <label>Phone Number</label>
                                    <TextField
                                        value={user?.phoneNumber || ''}
                                        disabled
                                    />
                                </Stack>
                            )
                        }
                    </Stack>
                ) : (
                    <UserProfileInfoSkeleton/>
                )
            }
        </>
    )
}