import {
  Box,
  Button,
  Stack,
  TextField,
  Select,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setAlertDetails } from "../../../redux/slices/AlertDetailsSlice";
const country = ["United States", "Nigeria", "Ghana", "Kenya", "South Africa"];
import { useParams } from "react-router-dom";
import axiosInstance from "../../../axios/axiosInstance";
// conditionally render states based on country dinamically
const states = (country) => {
  switch (country) {
    case "United States":
      return ["California", "Florida", "New York", "Texas", "Washington"];
    case "Nigeria":
      return ["Abuja", "Lagos", "Kano", "Kaduna", "Enugu"];
    case "Ghana":
      return ["Accra", "Kumasi", "Tamale", "Takoradi", "Ho"];
    case "Kenya":
      return ["Nairobi", "Mombasa", "Kisumu", "Nakuru", "Eldoret"];
    case "South Africa":
      return ["Cape Town", "Johannesburg", "Durban", "Pretoria", "Bloemfontein"];
    default:
      return [""];
  }

}
const EditUser = () => {
  const { userID } = useParams();
  const [user, setUser] = useState({
    username: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: {
      country: "",
      state: "",
      zipCode: "",
    },
  });
  const dispatch = useDispatch();
  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };

  // handle fetch user
  useEffect(() => {


    const handleFetchUser = () => {
      axiosInstance
        .get(
          `/user/${userID}`
        )
        .then((res) => {
          setUser({
            username: res.data.user.username ? res.data.user.username : "",
            firstName: res.data.user.firstName ? res.data.user.firstName : "",
            lastName: res.data.user.lastName ? res.data.user.lastName : "",
            phoneNumber: res.data.user.phoneNumber
              ? res.data.user.phoneNumber
              : "",
            email: res.data.user.email ? res.data.user.email : "",
            address: res.data.user.address
              ? {
                country: res.data.user.address.country
                  ? res.data.user.address.country
                  : "",
                state: res.data.user.address.state
                  ? res.data.user.address.state
                  : "",
                zipCode: res.data.user.address.zipCode
                  ? res.data.user.address.zipCode
                  : "",
              }
              : {
                country: "",
                state: "",
                zipCode: "",
              },
          });
        })
     
    };
    handleFetchUser();
  }, [userID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setUser((prevValues) => ({
      ...prevValues,
      address: {
        ...prevValues.address,
        [name]: value,
      },
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.username === "") {
      // check if user name is empty
      setAlertDetailsToRedux("error", "username shoud not be empty");

      return;
    }

    axiosInstance
      .put(
        `/admin/user/${userID}`,
        user
      )
      .then(() => {
        setAlertDetailsToRedux("success", "User updated successfully");
        // store updated user to redux
      })
      .catch((err) => {
        setAlertDetailsToRedux("error", err.response.data.error);
      });
  };

  return (
    <Box>
      <form>
        <Stack spacing={2}>
          <TextField
            name="username"
            label="User Name"
            variant="outlined"
            size="medium"
            value={user.username}
            onChange={handleChange}
          />
          <TextField
            name="firstName"
            label="First Name"
            variant="outlined"
            size="medium"
            value={user.firstName}
            onChange={handleChange}
          />
          <TextField
            name="lastName"
            label="Last Name"
            variant="outlined"
            size="medium"
            value={user.lastName}
            onChange={handleChange}
          />
          <TextField
            name="phoneNumber"
            label="Phone Number"
            variant="outlined"
            size="medium"
            value={user.phoneNumber}
            onChange={handleChange}
          />
          <TextField
            name="email"
            label="Email"
            variant="outlined"
            size="medium"
            value={user.email}
            onChange={handleChange}
          />

          <Box sx={{ py: "2px" }}>
            <Typography
              variant="body1"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Address
            </Typography>

            <Stack sx={{ direction: ["column", "row"] }} spacing={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Country </InputLabel>
                <Select
                  fullWidth
                  value={
                    user.address
                      ? user.address.country
                        ? user.address.country
                        : ""
                      : ""
                  }
                  onChange={handleAddressChange}
                  name="country"
                  label="Country"
                  size="medium"
                >
                  {country.map((country, i) => {
                    return (
                      <MenuItem key={i} value={country}>
                        {country}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> State </InputLabel>
                <Select
                  fullWidth
                  value={
                    user.address
                      ? user.address.state
                        ? user.address.state
                        : ""
                      : ""
                  }
                  onChange={handleAddressChange}
                  name="state"
                  label="State"
                  size="medium"
                >
                  {states(user.address.country).map((state, i) => {
                    return (
                      <MenuItem key={i} value={state}>{state}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  name="zipCode"
                  label="Zip Code"
                  variant="outlined"
                  size="medium"
                  value={
                    user.address
                      ? user.address.zipCode
                        ? user.address.zipCode
                        : ""
                      : ""
                  }
                  onChange={handleAddressChange}
                />
              </FormControl>
            </Stack>
          </Box>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
export default EditUser;
