import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useEffect, useContext } from "react";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { LoadingMoreProductsAnimation } from "../../general/loading/LoadingMoreProductsAnimation";
import { useParams } from "react-router-dom";
import { TransactionsTable } from "./TransactionsTable";
import { AdminTransactionsSearch } from "../../../context";
import { GeneralSkeletonLoading } from "../../general/loading/GeneralSkeletonLoading";
import axiosInstance from "../../../axios/axiosInstance";
const UserTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [endTransactions, setEndtransactions] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const limit = 10;
  const { userID } = useParams();

  const { searchKeys } = useContext(AdminTransactionsSearch);
  // Fetch initial transactions when the component mounts
  useEffect(() => {
    setLoadingPage(true);
    const fetchInitialTransactions = async () => {
      try {
        const response = await axiosInstance.get(
          `/admin/user/transactions/${userID}?page=${1}&limit=${limit}&search=${
            searchKeys.search
          }&status=${searchKeys.status}`
        );
        if (response.status === 200) {
          setTransactions(response.data.transactions);
        }
      } catch (_) {/**/}
      setLoadingPage(false);
    };

    fetchInitialTransactions();
  }, [searchKeys.search, searchKeys.status, userID]);

  // Fetch additional Transactions when the page number changes depending on the scroll position
  const fetchAdditionalTransactions = async (pageNumber) => {
    try {
      if (!loadingPage) {
        // Prevents the loading animation from showing when the page is loading if the initial products are still loading
        setLoading(true);
      }

      const response = await axiosInstance.get(
        `/admin/user/transactions/${userID}?page=${pageNumber}&limit=${limit}&search=${searchKeys.search}&status=${searchKeys.status}`
      );

      if (response.status === 404) {
        setEndtransactions(true);
      } else {
        setTransactions((prevTransactions) => {
          // Using the callback form to ensure we work with the latest state
          return [...prevTransactions, ...response.data.transactions];
        });
        setPage(pageNumber);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        setEndtransactions(true);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <InfiniteScroll
      style={{ overflow: "hidden", padding: "5px", marginTop: "40px" }}
      dataLength={transactions.length}
      next={() => fetchAdditionalTransactions(page + 1)}
      hasMore={!endTransactions}
      loader={
        loading && (
          <Box
            sx={{
              position: "absolute",
              bottom: "5px",
              right: "0px",
              width: "100%",
            }}
          >
            <Paper
              sx={{
                p: 2,
                width: "100%",
                backgroundColor: "shadow.main",
                color: "primary.main",
                textAlign: "center",
              }}
            >
              <LoadingMoreProductsAnimation loadingText="Loading More Transactions ..." />
            </Paper>
          </Box>
        )
      }
    >
      <Grid container spacing={2}>
        {loadingPage ? (
          <Grid item xs={12}>
               <Stack spacing={2} sx={{ width: "100%" }}>
              <GeneralSkeletonLoading number={20} />
            </Stack>
          </Grid>
        ) : transactions.length > 0 ? (
          <>
            <TransactionsTable transactions={transactions} />
          </>
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{ textAlign: "center", color: "primary.main" }}
            >
              No Transactions Found
            </Typography>
          </Grid>
        )}
      </Grid>
    </InfiniteScroll>
  );
};
export default UserTransactions;
