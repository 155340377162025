import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useEffect } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { ProductItem } from "../general/ProductItem";
import { LoadingMoreProductsAnimation } from "../general/loading/LoadingMoreProductsAnimation";
import { SkeletonLoadingProducts } from "../general/loading/SkeletonLoadingProducts";
import axiosInstance from "../../axios/axiosInstance";

export const ProductsListByCategories = ({ activeFilter, apiFilterUrl }) => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [endProducts, setEndProducts] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const limit = 10;

  // reset products when activeCategory changes
  useEffect(() => {
    setEndProducts(false);
    setPage(1);
    setProducts([]);
  }, [activeFilter]);

  // Fetch initial products when the component mounts or when activeCategory changes
  useEffect(() => {
    setLoadingPage(true);
    const fetchInitialProductsByCategories = async () => {
      try {
        const response = await axiosInstance.post(
          `${apiFilterUrl}?page=${1}&limit=${limit}`,
          activeFilter,
          { headers: { "Content-Type": "application/json" } }
        );
        if (response.status === 200) {
          setProducts(response.data.products);
        }

      } catch (_) {/* empty*/ }
      finally {
        setLoadingPage(false);
      }
    };

    fetchInitialProductsByCategories();
  }, [apiFilterUrl, activeFilter]);

  // Fetch additional products when the page number changes depending on the scroll position
  const fetchAdditionalProducts = async (pageNumber) => {
    try {
      if (!loadingPage) {
        setLoading(true);
      }
      const response = await axiosInstance.post(
        `${apiFilterUrl}?page=${pageNumber}&limit=${limit}`,
        activeFilter,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.products.length === 0) {
        setEndProducts(true);
      } else {
        setProducts((prevProducts) => [
          ...prevProducts,
          ...response.data.products,
        ]);
        setPage(pageNumber);
      }
    } catch (_) {/* */ } finally {
      setLoading(false);
    }
  };

  return (
    <InfiniteScroll
      style={{ overflow: "hidden", padding: "5px" }}
      dataLength={products.length}
      next={() => fetchAdditionalProducts(page + 1)}
      hasMore={!endProducts}
      loader={
        loading && (
          <Grid
            item
            xs={12}
            sx={{
              position: "absolute",
              bottom: "5px",
              right: "0px",
              width: "100%",
            }}
          >
            <Paper
              sx={{
                p: 2,
                width: "100%",
                backgroundColor: "shadow.main",
                color: "primary.main",
                textAlign: "center",
              }}
            >
              <LoadingMoreProductsAnimation loadingText="Loading More Products ..." />
            </Paper>
          </Grid>
        )
      }
    >
      <Grid container spacing={2}>
        {loadingPage ? ["", "", "", "", "", "", "", "", "", "", "", ""].map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} >
            <SkeletonLoadingProducts />
          </Grid>
        )) : products.length > 0 ? (
          products.map((product, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ProductItem product={product} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{ textAlign: "center", color: "primary.main" }}
            >
              No Products Match Your Search
            </Typography>
          </Grid>
        )}
      </Grid>
    </InfiniteScroll>
  );
};
