import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import {  useState } from "react";
import axios from "axios";
import imageCompression from 'browser-image-compression';
import { useDispatch, useSelector } from "react-redux";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import axiosInstance from "../../axios/axiosInstance";
import InputFiled from "./InputFiled";
import UploadImages from "./UploadImages";
import { addProductValidationSchema } from "../../validation/validationSchemas";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../redux/slices/AuthUser";
const AddProductForm = () => {
const Authuser = useSelector(getUser);
  const [imageLoading, setImageLoading] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const savedProduct = JSON.parse(localStorage.getItem("productSaved"));
  const initialValues = savedProduct ? savedProduct : {
    category: "",
    name: "",
    price: "",
    per: "",
    description: "",
    condition: "",
    pictures: [],

  };

  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };

  const getPresignedUrl = async (image) => {
    try {
      if (!image.type.startsWith("image/")) {
        setAlertDetailsToRedux("error", "Please select an image file");
      }
      const response = await axiosInstance.post(
        "/products/upload-image-url",
        {
          contentType: image.type,

        }
      );
      const presignedUrl = response.data.presignedUrl;
      const imageUrl = response.data.imgUrl;
      if (response.status === 200) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        }
        const compressedImage = await imageCompression(image, options);
        await axios.put(presignedUrl, compressedImage, {
          headers: {
            "Content-Type": image.type,
          },
        });
        return imageUrl;
      } 
    } catch (error) {
      setAlertDetailsToRedux("error", error.response.data.error || "failed to upload image");
      setLoading(false);
      return null;
    }
  };

  const handleImageChange = async (event, index, setFieldValue) => {
    const selectedImage = event.target.files[0];
    setImageLoading(prevState => ({ ...prevState, [index]: true }));
    try {
      const imageUrl = await getPresignedUrl(selectedImage);
      if (imageUrl) {
        setFieldValue(`pictures.${index}`, imageUrl);
      }
    } finally {
      setImageLoading(prevState => ({ ...prevState, [index]: false }));
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const finalValues = {
        ...values,
        userID: Authuser?.id,
      };


      if (!Authuser?.user.location?.lat || !Authuser?.user.location?.lng) {
        navigate(`/save-address`);
        localStorage.setItem(
          "productSaved",
          JSON.stringify(values)
        );
        setAlertDetailsToRedux("error", "Please Update Your location from map");
        return;
      }


      await axiosInstance.post("/products", finalValues);
      setAlertDetailsToRedux("success", "Product added successfully");
      localStorage.removeItem("productSaved");
      resetForm();
    } catch (error) {
      setAlertDetailsToRedux("error", error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={addProductValidationSchema}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form>
            <Grid container spacing={5}>
              <Grid item xs={12} md={8}>
                <InputFiled
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <UploadImages
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleImageChange={handleImageChange}
                  imageLoading={imageLoading}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", my: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  size="large"
                  sx={{ width: ["100%", "30%"], fontFamily: "Poppins-Bold" }}
                >
                  {loading ?
                    <>
                      <CircularProgress size={24} sx={{ mr: "15px" }} />
                      Please wait...
                    </>
                    : "Add Product"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddProductForm;
