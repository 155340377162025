import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps"
import { MapSkeleton } from "./loading/MapSkeleton"
export const Maps = ({ location }) => {
  return (
    <>
      {
        location ? (<APIProvider apiKey="AIzaSyBgwMV6zIMMSXiui7N3hqvyraHuSlUQ2p0">
          <Map
            zoom={2}
            center={{ lat: location ? location.lat : 0, lng: location ? location.lng : 0 }}
            mapId="e8b1b7b3b3b4b3b3"

            style={{ width: "100%", height: "150px" }}
          >
            <AdvancedMarker position={{ lat: location ? location.lat : 0, lng: location ? location.lng : 0 }} />
          </Map>
        </APIProvider>

        ) : (
          <MapSkeleton />
        )
      }



    </>
  )
}
