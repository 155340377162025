import { Box, Container, Grid, Typography } from '@mui/material'
import about from '../../assets/images/about.webp'
import { useEffect, useState } from 'react';
export const WhoWe = () => {
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        setImageLoaded(true);
    }, [imageLoaded]);

    return (
        <Box py={5} /* sx={{height:"100vh"}} */>
            <Container>
                <Grid container spacing={3} alignItems="center">

                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography variant='h3' component="h2" sx={{ color: "primary.main", fontWeight: "bold" }} >
                                What is direct rent?
                            </Typography>
                            <Typography variant='body1' sx={{ color: "secondary.main", mt: 2 }}>
                                is an online marketplace that makes it easy to rent the items you need, and lend out the stuff you don’t. Our platform connects kiwis all over the country, allowing them to safely exchange items with their neighbours through a safe and secure network.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            {
                                !imageLoaded ? (
                                    <Box sx={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: "center", }}>

                                    </Box>
                                ) : (

                                    <img
                                        src={`${about}?${Date.now()}`}
                                        alt="about"
                                        style={{ width: "100%", height: "500px", objectFit: "contain" }}

                                    />
                                )
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
