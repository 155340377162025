import { useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { InfinityScrollTransactions } from "../components/history/InfinityScrollTransactions";
import { UserTransactionsSearch } from "../context";
import { Searchkeys } from "../components/history/SearchKeys";

export const TransactionHistory = () => {
  const [searchKeys, setSearchKeys] = useState({ name: "", status: "" });

  const changeSearchKeys = (keys) => {
    setSearchKeys(keys);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 8, position: "relative" }}>
      <UserTransactionsSearch.Provider
        value={{ searchKeys: searchKeys, changeSearchKeys: changeSearchKeys }}
      >
        <Typography variant="h3" sx={{ mb: 8,color:"primary.main",textAlign:"center",fontWeight:"bold" }}>
           History
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Searchkeys />
          </Grid>
          <Grid item xs={12} md={8}>
            <InfinityScrollTransactions />
          </Grid>
        </Grid>
    
      </UserTransactionsSearch.Provider>
    </Container>
  );
};


