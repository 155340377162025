import { useState, useEffect } from "react";
import { Container, Grid, Typography, Button, Box } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../redux/slices/AuthUser";
import { setAlertDetails } from "../redux/slices/AlertDetailsSlice";
import RentDate from "../components/product-details/RentDate";
import { Slider } from "../components/product-details/Slider";
import { ProductInfo } from "../components/product-details/ProductInfo";
import axiosInstance from "../axios/axiosInstance";
import { Maps } from "../components/general/Map";
import { ShowComments } from "../components/product-details/comments/ShowComments";
import { ShowReviews } from "../components/product-details/reviews/ShowReviews";
import UserInformation from "../components/product-details/UserInformation";

export const ProductDetails = () => {
  const authUser = useSelector(getUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productID } = useParams();
  const [product, setProduct] = useState({});
  const [notFound, setNotFound] = useState(false);
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };

  const handleNavigateToReview = () => {
    if (!authUser?.id) {
      setAlertDetailsToRedux("error", "Please Login to Add Review");
      return;
    } else {
      if (product && authUser?.id === product.userID._id) {
        setAlertDetailsToRedux("error", "You can't add review to your own product");
        return;
      } else {
        navigate(`/add-review/${productID}`);
      }
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await axiosInstance.get(`/products/${productID}`);
        setProduct(res.data.product);
      } catch (err) {
        if (err.response.status === 404) {
          setNotFound(true);
        }
      }

    };
    fetchProduct();
  }, [productID]);


  if (notFound) {
    return (
      <Container maxWidth={"lg"} sx={{ py: 5, height: "60vh", display: "flex", alignItems: "center", justifyContent: "center", }}>
        <Typography variant="h5" sx={{ textAlign: "center", color: "primary.main", fontFamily: "Poppins-Bold" }}>
          404 | Product not found
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth={"lg"} sx={{ py: 5 }}>
      <Grid container spacing={8}>
        <>
          <Grid item xs={12} md={5}>
            <Slider images={product ? product.pictures : []} />
          </Grid>
          <Grid item xs={12} md={7}>
            <ProductInfo product={product} />
            <Grid container spacing={2} py={3}>
              <Grid item xs={12} md={6}>
                <Maps location={product ? product.location : ""} />
              </Grid>
              <Grid item xs={12} md={6}>
                <ShowComments productID={productID} />
                <Button
                  variant="contained"
                  sx={{ width: "content-fit", p: 1, my: 3 }}
                  onClick={() => navigate(`/add-comment/${product ? product._id : ""}`)}
                >
                  Add Comment
                </Button>
              </Grid>
            </Grid>
            <RentDate product={product || {}} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Box>
                  <Typography variant="h5" sx={{ color: "secondary.main", fontWeight: "bold", mb: 2 }}>
                    Customer Reviews
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ width: "200px", p: 1 }}
                    onClick={handleNavigateToReview}
                  >
                    Write a Review
                  </Button>
                </Box>
                <UserInformation user={product ? product.userID : ""} />
              </Grid>
              <Grid item xs={12} md={7}>
                <ShowReviews productID={productID} />
              </Grid>
            </Grid>
          </Grid>
        </>
      </Grid>
    </Container>
  );
};
