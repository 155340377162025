import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useEffect, useContext } from "react";
import { Box, Paper, } from "@mui/material";
import { Items } from "./Items";
import { useSelector } from "react-redux";
import { getUser } from "../../redux/slices/AuthUser";
import { UserTransactionsSearch } from "../../context";
import { LoadingMoreProductsAnimation } from "../general/loading/LoadingMoreProductsAnimation";
import axiosInstance from "../../axios/axiosInstance";
export const InfinityScrollTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [endProducts, setEndProducts] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const limit = 10;
  // get search keys from context
  const { searchKeys } = useContext(UserTransactionsSearch);
  const userId = useSelector(getUser)?.id; // get user id from redux
  // Fetch initial Transactions when the component mounts
  useEffect(() => {
    setLoadingPage(true);
    const fetchInitialTransactions = async () => {
      try {
        const response = await axiosInstance.get(
          `/transactions/user/${userId}?page=${1}&limit=${limit}&status=${searchKeys.status
          }&search=${searchKeys.name}`
        );
       
        if (response.status === 200) {
          setTransactions(response.data.transactions);
        }
      } catch (_) {/**/}
      setLoadingPage(false);
    };

    fetchInitialTransactions();
  }, [searchKeys.name, searchKeys.status, userId]);

  // Fetch additional Transactions when the page number changes depending on the scroll position
  const fetchAdditionalTransactions = async (pageNumber) => {
    try {
      if (!loadingPage) {
        // Prevents the loading animation from showing when the page is loading if the initial Transactions are still loading
        setLoading(true);
      }

      const response = await axiosInstance.get(
        `/transactions/user/${userId}?page=${pageNumber}&limit=${limit}&status=${searchKeys.status}&search=${searchKeys.name}`
      );

      if (response.status === 404) {
        setEndProducts(true);
      } else {
        setTransactions((prevProducts) => {
          return [...prevProducts, ...response.data.transactions];
        });
        setPage(pageNumber);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        setEndProducts(true);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <InfiniteScroll
        style={{
          overflow: "hidden",
          position: "relative",
          padding: "5px",
          height: transactions.length > 0 ? "70vh" : "50vh",

        }}
        dataLength={transactions.length}
        next={() => fetchAdditionalTransactions(page + 1)}
        hasMore={!endProducts}
        loader={
          loading && (
            <Box
              sx={{
                position: "absolute",
                bottom: "5px",
                right: "0px",
                width: "100%",
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  width: "100%",
                  backgroundColor: "shadow.main",
                  color: "primary.main",
                  textAlign: "center",

                }}
              >
                <LoadingMoreProductsAnimation loadingText="Loading More Transactions ..." />
              </Paper>
            </Box>
          )
        }
      >
        <Box sx={{ width: "100%" }}>
          <Items transactions={transactions} loading={loadingPage} />
        </Box>
      </InfiniteScroll>
    </>
  );
};
