import React from "react";
import bugImagee from "./assets/images/bug.png";
import { Box, Button, Container, Stack, Typography } from "@mui/material";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      console.log("ErrorBoundary");
      return (
        <Box sx={{ width: "100%", height: "100vh", backgroundColor: "text.main" }}>
          <Container maxWidth="md" sx={{ textAlign: "center" }}>
            <Stack sx={{ justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: ["column", "row"], gap: ["30px", "80px"] }}>
              <Box >
                <Box sx={{ width: ["100%"], height: ["200px", "300px"], display: "flex", justifyContent: "center" }}>
                  <img
                    src={bugImagee}
                    alt="bug"
                    style={{ width: "100%", height: "100%" }}
                    loading="lazy"
                  />
                </Box>
              </Box>
              <Box>
                <Typography variant="h4" sx={{ color: "primary.main", fontFamily: "Poppins-Bold" }}>
                  ERROR
                </Typography>
                <Typography variant="body1" sx={{ color: "text.secondary", py: 2 }}>
                  Something went wrong. Please try again later.
                </Typography>
                <Button fullWidth variant="contained" sx={{ backgroundColor: "primary.main", color: "white", mt: 2 }} onClick={() =>
                  window.location.href = "/"
                } >
                  Home
                </Button>
              </Box>


            </Stack>
          </Container>
        </Box>
      );
    }

    return this.props.children;
  }
}
