import { styled } from "@mui/material/styles";
import { TextField, Container, Button, Box } from "@mui/material";
import { useState, useContext } from "react";
import { UserContext } from "../../../context"; // Import the context
const CustomInput = styled(TextField)({
  "& .css-cg8wgx-MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: 0,
   
  },
});
export const SearchByName = () => {
  const [searchValue, setSearchValue] = useState("");
  const { search } = useContext(UserContext); // Get the context set state function
  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  // Set the search value in the context
  const handleSubmit = () => {
    search.changeSearchValue(searchValue); // Set the search value in the context
    setSearchValue("");// Clear the input after submitting
  };
  // handle search on enter key
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Container
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box>
        <CustomInput
          type="text"
          placeholder="Search by name"
          onChange={handleChangeSearch}
          value={searchValue}
          sx={{maxWidth:["75%"]}}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ borderRadius: "0px", py:2,maxWidth:["25%"]}}
          onClick={handleSubmit}
        >
          Search
        </Button>
      </Box>
    </Container>
  );
};
