/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Skeleton, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axios/axiosInstance";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import AvatarUI from "./AvatarUI";
import { NotificationContext } from "../../context";
export const Pendding = () => {
  const { handleUpdateCount } = useContext(NotificationContext);
  const theme = useTheme();
  const [loading, setLoading] = useState(false)
  const [requests, setRequests] = useState([]);
  const [hiddenLoadMore, setHiddenLoadMore] = useState(false);
  const [updateNotification, setUpdateNotification] = useState(false)
  let [page, setPage] = useState(1);
  let limit = 10;
  const dispatch = useDispatch();
  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }))
  };
  // set notification as read
  const setNotificationAsRead = (notificationId) => {
    axiosInstance
      .patch(`/notifications/markAsRead`, {
        ids: [notificationId]
      }).then(() => {
        handleUpdateCount(); // update notification count
        setUpdateNotification(!updateNotification)// update notification
      })

  };
  // handle  accept request
  const handleAccept = (request) => {
    axiosInstance
      .put(
        `/transactions/approve/${request.objectId}`,
        {},

      ).then((res) => {
        setAlertDetailsToRedux("success", res.data.message)
        setNotificationAsRead(request._id) // set notification as read

      })
      .catch((err) => {
        setAlertDetailsToRedux("error", err.response.data.error)

      });
  };
  // handle reject request
  const handleReject = (request) => {
    axiosInstance
      .put(
        `/transactions/reject/${request.objectId}`,
        {},

      ).then((res) => {
        setAlertDetailsToRedux("success", res.data.message)
        setNotificationAsRead(request._id) // set notification as read
      })
      .catch((err) => {
        setAlertDetailsToRedux("error", err.response.data.error)

      });
  };
  // fetch comments notifications
  useEffect(() => {
    if (page === 1 && requests.length === 0) { // this to prevent loading to show when fetching more notifications
      setLoading(true)
    }
    const getNotifications = (type) => {
      axiosInstance
        .get(`/notifications?type=${type}&isRead=${false}&page=${page}&limit=${limit}`
        )
        .then((res) => {
          if (res.data.notifications.length === 0) {
            setHiddenLoadMore(true);
          }
          setRequests((prev) => [...prev, ...res.data.notifications]);
        }).finally(() => {
          setLoading(false)
        })
    };
    getNotifications("transaction created");

  }, [limit, page])

     // handle update notification when notification is updated
     useEffect(() => {
      if(requests.length === 0) return;
      axiosInstance.get(`/notifications?type=transaction created&isRead=${false}&page=${1}&limit=${limit}`).then((res) => {
          setRequests(res.data.notifications)
          if (res.data.notifications.length === 0) {
              setHiddenLoadMore(true);
          }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ updateNotification])
  // fetch more notification
  const fetchMoreNotification = () => {
    setPage(page + 1);
  }
  return (
    <Stack spacing={1}>
      {

        loading && (
          <Stack spacing={2}>
            {
              [1, 2, 3, 4].map((item) => {
                return (
                  <Skeleton key={item} variant="rectangular" height={100} sx={{ borderRadius: "5px" }} />
                )
              })
            }
          </Stack>
        )

      }
      {requests.map((request) => {
        return (
          <Stack
            key={request._id}
            sx={{
              backgroundColor: "white",
              borderRadius: "5px",

              p: 2,
              position: "relative",
            }}
          >
            <AvatarUI sender={request.sender} />
            <Box direction="row" sx={{ alignItems: "center", backgroundColor: "transparent !important" }}>
              <Link to="/transactions" style={{ fontSize: "12px", marginTop: "6px", color: theme.palette.secondary.main, textDecoration: "none", fontWeight: "400" }}>
                {
                  request?.title
                }
              </Link>
            </Box>
            <Stack direction="row" spacing={2} pt={2}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "secondary.main", color: "white" }}
                onClick={() => {
                  handleAccept(request);
                }}
              >
                Accept
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: "primary.main", color: "white" }}
                onClick={() => {
                  handleReject(request);
                }}
              >
                Reject
              </Button>
            </Stack>
          </Stack>
        );
      })}
      {
        !hiddenLoadMore ? (<Button
          variant="contained"
          sx={{ backgroundColor: "primary.main", color: "white", mt: 2 }}
          onClick={fetchMoreNotification}
        >
          Load More
        </Button>) : (
          <Box sx={{ textAlign: "center", mt: 2, color: "gray" }}>
            No more notifications
          </Box>
        )
      }
    </Stack>
  );
};