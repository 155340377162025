import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  clearHistorySearch,
  deletetItemFromHistory,
  setHistorySearch,
  setSearchStatus,
} from "../../redux/slices/Search";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import HistoryIcon from "@mui/icons-material/History";
import { useState } from "react";
import { setSearchByName } from "../../redux/slices/filterSlice";
import { useNavigate } from "react-router-dom";
import { useTheme, styled } from "@mui/material/styles";


const CustomDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    minHeight: "90vh",
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.5) !important",
    backdropFilter: "blur(3px)",
  },
  "@media (max-width: 400px)": {
    "& .MuiPaper-root": {
      minWidth: window.innerWidth - 10 + "px",
    },
  },
  "@media (min-width: 768px)": {
    "& .MuiPaper-root": {
      minWidth: "600px",
    },
  },
}));

const SearchDialog = () => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const searchStatus = useSelector((state) => state.search.status);
  const searchHistory = useSelector((state) => state.search.historySearch);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setSearchStatus(false));
  };

  // handle change search
  const handleChangeSearch = (event) => {
    setSearchValue(event.target.value);
  };
  // handle search
  const handleSerach = () => {
    dispatch(setHistorySearch(searchValue));
    dispatch(setSearchByName(searchValue));
    handleClose();
    navigate("/shop");
  };

  // handle delete item from history search
  const handleDeleteItemFromHistory = (itemIndex) => {
    dispatch(deletetItemFromHistory(itemIndex));
  };

  // handle clear history search
  const handleClearHistory = () => {
    dispatch(clearHistorySearch());
    // clear search by name in filter slice
    dispatch(setSearchByName(null));
  };
  // handle key down
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      dispatch(setHistorySearch(searchValue));
      dispatch(setSearchByName(searchValue));
      navigate("/shop");
      handleClose();
    }
  };
  const searchByHistory = (item) => {
    dispatch(setSearchByName(item));
    handleClose();
    navigate("/shop");
  };
  return (
    <CustomDialog
      open={searchStatus}
      onClose={handleClose}
      sx={{
        minWidth: "100%",
      }}
    >
      <DialogTitle sx={{ color: "primary.main" }}>Search </DialogTitle>
      <DialogContent>
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <SearchIcon
            sx={{ mr: 1, cursor: "pointer", color: "primary.main" }}
            onClick={handleSerach}
          />
          <input
            style={{
              fontSize: "1rem",
              width: "100%",
              border: "none",
              outline: "none",
              background: "transparent",
              caretColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              "::value": {
                color: theme.palette.primary.main,
              },
            }}
            autoFocus
            id="name"
            name="email"
            type="text"
            onChange={handleChangeSearch}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </Stack>
        <Divider sx={{ mt: 1 }} />
        <Button
          startIcon={<DeleteForeverIcon />}
          onClick={handleClearHistory}
          sx={{ my: 2 }}
        >
          History
        </Button>
        <Stack spacing={1} sx={{ my: 3 }}>
          {searchHistory.map((item, index) => {
            return (
              <Stack
                direction="row"
                justifyContent="space-between"
                key={`${item} ${index}`}
                sx={{
                  backgroundColor: "shadow.light",
                  padding: 1,
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  onClick={() => searchByHistory(item)}
                >
                  <HistoryIcon sx={{ color: "primary.main" }} />
                  <Typography variant="body1" sx={{ color: "primary.main" }}>
                    {item}
                  </Typography>
                </Stack>
                <CloseIcon
                  sx={{ cursor: "pointer", color: "primary.main" }}
                  onClick={() => handleDeleteItemFromHistory(index)}
                />
              </Stack>
            );
          })}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ px: 2 }}>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default SearchDialog;
