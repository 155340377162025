import { Box, Button, Container, Stack, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react"
import axiosInstance from "../../axios/axiosInstance";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import { useDispatch } from "react-redux";
export const Configuration = () => {
  const dispatch = useDispatch();
  const [numberPopularProducts, setNumberPopularProducts] = useState(0);
  const [additionalFees, setAdditionalFees] = useState(0);

  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }))
  };
  // get the number of popular products  
  const getPopularProducts = async () => {
    axiosInstance.get('/configurations/popular-products-count').then((res) => {
      setNumberPopularProducts(res.data);
    })
  };
  // get additional fees 
  const getAdditionalFees = async () => {
    axiosInstance.get('/configurations/additional-fee').then((res) => {
      setAdditionalFees(res.data);
    })
  };

  useEffect(() => {
    getPopularProducts()
    getAdditionalFees()
  }, []);

  // handle change additional fees
  const handleChangeAdditionalFees = (e) => {
    setAdditionalFees(e.target.value);
  };
  // handle update additional fees
  const handleUpdateAdditionalFees = () => {
    axiosInstance.post('/configurations/additional-fee', {
      value: additionalFees,
      description: "test"
    }).then((res) => {
      setAdditionalFees(res.data.value);
      setAlertDetailsToRedux("success", "Additional fees updated successfully");
    }).catch(() => {
      setAlertDetailsToRedux("error", "Failed to update additional fees");
    });
  };
  // handle change number of popular products
  const handleChangeNumberPopularProducts = (e) => {
    setNumberPopularProducts(e.target.value);
  };
  // handle update number of popular products
  const handleUpdateNumberPopularProducts = () => {
    axiosInstance.post('/configurations/popular-products-count', {
      value: numberPopularProducts,
      description: "test"
    }).then((res) => {
      setNumberPopularProducts(res.data.value);
      setAlertDetailsToRedux("success", "Number of popular products updated successfully");
    }).catch(() => {
      setAlertDetailsToRedux("error", "Failed to update number of popular products");
    });
  };

  return (
    <Container maxWidth="md" sx={{ my: 5 }}>
      <Typography variant="h4" component="h1" sx={{ fontWeight: "bold", pb: 2, textAlign: "center", color: "primary.main", fontFamily: "Poppins-Bold" }}>
        Configuration
      </Typography>
      <Stack spacing={2} my={5}>
        {/*Additional fees */}
        <Box>
          <Typography variant="h6" component="h6" sx={{ pb: 2, color: "primary.main" }}>
            Additional Fees
          </Typography>
          <Stack direction="row" sx={{ width: "100%" }}>
            <TextField type="number" value={additionalFees} fullWidth placeholder="Enter Additional Fees" onChange={handleChangeAdditionalFees} />
            <Button variant="contained" color="primary" size="large" onClick={handleUpdateAdditionalFees}>Update</Button>
          </Stack>
        </Box>
        {/*Number of popular products */}
        <Box>
          <Typography variant="h6" component="h6" sx={{ pb: 2, color: "primary.main" }}>
            Number of Popular Products
          </Typography>
          <Stack direction="row" sx={{ width: "100%" }}>
            <TextField type="number" value={numberPopularProducts} fullWidth placeholder="Enter Number of Popular Products" onChange={handleChangeNumberPopularProducts} />
            <Button variant="contained" color="primary" size="large" onClick={handleUpdateNumberPopularProducts}>Update</Button>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}
