import { Box, Container, Grid, Typography, Stack } from '@mui/material'
import bgBanar from '../../../assets/images/banarbg.webp'
import banarMobile from '../../../assets/images/banarMobile.png'
import googlePlay from "../../../assets/images/get-app-from-googlePlay.svg"
import appStore from "../../../assets/images/get-app-from-appStore.svg"
import { Link } from 'react-router-dom'

export const HeroSection = () => {
  return (
    <Box sx={{ width: "100%", backgroundColor: "#f3e8dca4" }}>
      <Box sx={{
        backgroundImage: `url(${bgBanar})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",

      }}>
        <Container maxWidth="lg">
          <Grid container spacing={2} >
            <Grid item xs={12} md={6}>

              <Box sx={{ marginTop: "50px", pt: [1, 8] }}>
                <Typography variant="h2" sx={{ color: "primary.main", fontWeight: "bold",fontFamily:"Poppins-Bold", fontSize: ["35px", "70px"] }}>Rent anything
                  <br />
                  from anyone</Typography>
                <Typography variant="body1" sx={{ color: "secondary.main", pt: 2 }}>
                  Just about anything you’d ever want is already
                  <br />
                  in your community. Now you can access it.
                </Typography>
                <Typography variant="h5" component={"h2"} sx={{ color: "secondary.main", pt: 1, fontWeight: "bold" }}>
                  GET THE APP
                </Typography>
                <Stack direction="row" spacing={2} sx={{ pt: 2, pb: 5 }}>
                  <Link to="/">
                  <img src={googlePlay} alt="rent" style={{ width: "130px", height: "45px" }} loading='lazy' />
                  </Link>
                  <Link to="/">
                  <img src={appStore} alt="rent" style={{ width: "130px", height: "45px" }} loading='lazy' />
                  </Link>
                </Stack>
              </Box>

            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ height: "100%",width:"100%",display:"flex",justifyContent:"center" }}>
                <img src={banarMobile} alt="rent" style={{ width: "100%", height: "100%",objectFit:"fill" }} loading='lazy' />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </Box>
  )
}

