import { Grid, TextField } from '@mui/material'
import { Field } from 'formik'
export const InputFiled = ({errors,touched}) => {
  return (
   <>
     <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          label="First Name"
                          name="firstName"
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          label="Last Name"
                          name="lastName"
                          error={touched.lastName && Boolean(errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          fullWidth
                          label="Display Name"
                          name="displayName"
                          error={
                            touched.displayName && Boolean(errors.displayName)
                          }
                          helperText={touched.displayName && errors.displayName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          fullWidth
                          label="Phone Number"
                          name="phoneNumber"
                          error={
                            touched.phoneNumber && Boolean(errors.phoneNumber)
                          }
                          helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          fullWidth
                          label="Address"
                          name="address"
                          error={touched.address && Boolean(errors.address)}
                          helperText={touched.address && errors.address}
                        />
                      </Grid>
   </>
  )
}
