import { AppBar, Avatar, Box, Button, Container, Divider, IconButton, Stack, Toolbar, Tooltip, Typography } from '@mui/material'
import Logo from "../../../assets/images/logo.svg";
import menu from "../../../assets/images/menu.svg";
import { Link } from 'react-router-dom'
import usIcon from "../../../assets/images/us-icon.svg";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Notifications } from "../../notifications/Notifications";
export const AppBars = ({ user, handleOpenStack, handleOpenDrawer, navbarRef }) => {
    return (
        <AppBar position="relative" sx={{ backgroundColor: "background.default", zIndex: 1100, boxShadow: "none" }} ref={navbarRef}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: "end", alignItems: "center", width: "100%", px: 2, position: "relative", zIndex: 300 }}>
                <Button sx={{ color: "secondary.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: "16px" }} component={Link} to={`/`}>
                    FAQ
                </Button>
                <Button sx={{ color: "secondary.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: "16px" }} component={Link} to={`/`}>
                    Terms of Service
                </Button>
                <img src={usIcon} alt="us-icon" loading='lazy' style={{ width: "20px", height: "20px", marginLeft: "10px" }} />
            </Stack>
            <Divider />
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        component={Link}
                        to="/"
                        sx={{
                            mr: 2,
                            flexGrow: { xs: 1, sm: 0 },
                            display: "flex",
                            alignItems: "center",
                            width: ["80px", "150px"],
                            height: ["50px", "80px"],
                            my: 1,
                        }}
                    >
                        <img src={Logo} alt="DirectRent" loading='lazy' style={{ width: "100%", height: "100%" }} />
                    </Typography>


                    <Box
                        sx={{
                            display: ["none", "none", "none", "flex"],
                            justifyContent: "center",
                            alignItems: "center",
                            flexGrow: 1,
                        }}
                    >
                        {
                            // if login user is admin then show admin button
                            user?.role === "Admin" ? (
                                <Button
                                    sx={{ my: 2, color: "primary.main", display: "flex", alignItems: "center", textTransform: "capitalize", fontWeight: "bold" }}
                                    component={Link}
                                    to={`/admins`}
                                    startIcon={<AdminPanelSettingsIcon />}
                                >
                                    Admin
                                </Button>
                            ) : null
                        }
                        <Button
                            sx={{
                                my: 2, color: "primary.main", display: "block", textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out', fontWeight: "bold",
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            component={Link}
                            to={`/`}
                        >
                            Home
                        </Button>
                        <Button
                            sx={{
                                my: 2, color: "primary.main", display: "block", textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out', fontWeight: "bold",
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            component={Link}
                            to={`/about-us`}
                        >
                            About us
                        </Button>
                        <Button
                            sx={{
                                my: 2, color: "primary.main", display: "block", textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out', fontWeight: "bold",
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            component={Link}
                            to={`/shop`}
                        >
                            Shop
                        </Button>
                        {
                            user && (
                                <Button
                                    sx={{
                                        my: 2, color: "primary.main", display: "block", textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out', fontWeight: "bold",
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                    }}
                                    component={Link}
                                    to={`/add-new-product`}
                                >
                                    Rent
                                </Button>
                            )
                        }
                        <Button sx={{
                            my: 2, color: "primary.main", display: "block", textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out', fontWeight: "bold",
                            '&:hover': {
                                transform: 'scale(1.05)',
                            },
                        }} component={Link} to={`/categories`}>
                            Categories
                        </Button>
                        <Button
                            sx={{
                                my: 2, color: "primary.main", display: "block", textTransform: "capitalize", fontSize: "16px",
                                transition: 'transform 0.3s ease-in-out', fontWeight: "bold",
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            component={Link}
                            to={`/contact-us`}
                        >
                            Contact us
                        </Button>

                    </Box>

                    <Stack sx={{ dispaly: "flex", alignItems: "center", gap: "10px", justifyContent: "end", flexDirection: "row", flexGrow: [1, 1, 1, 0] }}>

                        {
                            user ? (
                                <>
                                    <Notifications />
                                    <Tooltip title="Account settings" >
                                        <IconButton
                                            onClick={handleOpenStack}
                                            size="small"
                                        >
                                            <Avatar
                                                sx={{ textDecoration: "none", }}
                                            >
                                                {user && user.profilePicture ? (
                                                    <img
                                                        src={user.profilePicture}
                                                        alt={user.username || "user"}
                                                        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                                                    />
                                                ) : (
                                                    user ? user.username ? user.username[0].toUpperCase() : null : null
                                                )}
                                            </Avatar>
                                        </IconButton>
                                    </Tooltip>

                                </>
                            ) : (
                                <>
                                    <Button
                                        sx={{ my: 2, color: "secondary.main", display: "block", textTransform: "capitalize", fontSize: "16px", fontWeight: "bold" }}
                                        component={Link}
                                        to={`/signUp`}
                                    >
                                        SignUp
                                    </Button>
                                    <Button
                                        sx={{ my: 2, color: "secondary.main", display: "block", textTransform: "capitalize", fontSize: "16px", fontWeight: "bold" }}
                                        component={Link}
                                        to={`/login`}
                                    >
                                        Login
                                    </Button>

                                </>
                            )
                        }
                    </Stack>

                    {/*menu icon for mobile*/}
                    <Box sx={{ display: ["block", "block", "block", "none"], mx: 2 }}>
                        <img
                            src={menu}
                            alt="menu"
                            style={{ width: "25px", height: "25px", cursor: "pointer" }}
                            onClick={handleOpenDrawer}
                        />
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    )
}
