import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useEffect, useContext } from "react";
import { Box, Paper } from "@mui/material";
import { LoadingMoreProductsAnimation } from "../general/loading/LoadingMoreProductsAnimation";
import { Items } from "./Items";
import { useSelector } from "react-redux";
import { getUser } from "../../redux/slices/AuthUser";
import { UserProductsSearch } from "../../context";
import axiosInstance from "../../axios/axiosInstance";
export const InfinityScrollProducts = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [endProducts, setEndProducts] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const limit = 10;
  const userId = useSelector(getUser)?.id; // get user id from redux store
  // get search keys from context
  const { searchKeys } = useContext(UserProductsSearch);
  // Fetch initial products when the component mounts
  useEffect(() => {
    setLoadingPage(true);
    const fetchInitialProducts = async () => {
      try {
        const response = await axiosInstance.get(
          `/products/user/${userId}?page=${1}&limit=${limit}&available=${searchKeys.status
          }&search=${searchKeys.name}`
        );
        setProducts(response.data.products);

      } catch (_) {/* empty*/ }
      finally {
        setLoadingPage(false);
      }
    };

    fetchInitialProducts();
  }, [searchKeys.status, searchKeys.name, userId]);

  // Fetch additional products when the page number changes depending on the scroll position
  const fetchAdditionalProducts = async (pageNumber) => {
    try {
      if (!loadingPage) {
        // Prevents the loading animation from showing when the page is loading if the initial products are still loading
        setLoading(true);
      }

      const response = await axiosInstance.get(
        `/products/user/${userId}?page=${pageNumber}&limit=${limit}&available=${searchKeys.status}&search=${searchKeys.name}`
      );

      if (response.data.products.length === 0) {
        setEndProducts(true);
      } else {
        setProducts((prevProducts) => {
          // Using the callback form to ensure we work with the latest state
          return [...prevProducts, ...response.data.products];
        });
        setPage(pageNumber);
      }
    } catch (_) {/**/ } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <InfiniteScroll
        style={{
          overflow: "hidden",
          padding: "5px",
          height: products.length > 0 ? "70vh" : "50vh",

        }}
        dataLength={products.length}
        next={() => fetchAdditionalProducts(page + 1)}
        hasMore={!endProducts}
        loader={
          loading && (
            <Box
              sx={{
                position: "absolute",
                bottom: "5px",
                right: "0px",
                width: "100%",
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  width: "100%",
                  backgroundColor: "shadow.main",
                  color: "primary.main",
                  textAlign: "center",
                }}
              >
                <LoadingMoreProductsAnimation loadingText="Loading More Products ..." />
              </Paper>
            </Box>
          )
        }
      >
        <Box sx={{ width: "100%" }}>
          <Items products={products} loading={loadingPage} />
        </Box>
      </InfiniteScroll>
    </>
  );
};
