import {
    Box,
    Card,
    CardMedia,
    Typography,
    CardContent,
    CardActions,
    Stack,
    Avatar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ShareProducts } from "../general/ShareProducts";
import { ControlFavoriteProduct } from "../Favourites/ControlFavoriteProduct";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios/axiosInstance";
// import svg star icon
import StarBorderIcon from '@mui/icons-material/StarBorder';

export const ProductItem = ({ product }) => {
    const [averageRating, setAverageRating] = useState(0)
    const navigate = useNavigate();
    const navigatToPageDetials = (id) => {
        // route to product details page
        navigate(`/product-detail/${id}`);
    };
    useEffect(() => {
        // fetch owner product rating
        const getRatingAvarege = () => {
            axiosInstance.post(`ratings/average-rating`, {
                id: product.userID._id,
            }).then(res => {
                setAverageRating(res.data.averageRating)

            }).catch(err => {
                if (err.response.status === 404) {
                    setAverageRating(0)
                }
            })
        }
        getRatingAvarege()
    }, [product.userID._id]);
    return (
        <Card
            sx={{
                transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)",

                position: "relative",
                borderRadius: "20px"
            }}
        >
            <Stack sx={{ position: "absolute", top: "5px", right: "50px", borderRadius: "50%", width: "30px", height: "30px", justifyContent: "center", alignItems: "center" }}>
                <ControlFavoriteProduct product={product} />
            </Stack>
            <Stack sx={{ p: 2, position: "absolute", top: "5px", right: "5px", backgroundColor: "shadow.main", border: "1px solid gray", borderRadius: "50%", width: "30px", height: "30px", justifyContent: "center", alignItems: "center" }}>
                <ShareProducts shareUrl={`https://directrent-marketplace-web1.vercel.app/product-detail/${product._id}`} title={product.name} />
            </Stack>
            <CardMedia
                onClick={() => navigatToPageDetials(product._id)}
                component="img"
                alt={product.name}
                loading="lazy"
                height="150px"
                width="100%"
                image={product.pictures ? product.pictures[0] : ""}
                sx={{ cursor: "pointer" }}
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant="body1"
                    component="h2"
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {

                        product.name
                    }
                </Typography>
                <Box>
                    <Typography
                        gutterBottom
                        variant="caption"
                        component="span"
                        sx={{ color: "primary.main" }}
                    >
                        <Typography
                            variant="body1"
                            component="span"
                            sx={{ fontWeight: "bold" }}
                        >
                            $
                            {

                                product.price
                            }

                        </Typography>
                        /{" "}
                        {

                            product.per
                        }
                    </Typography>
                </Box>


            </CardContent>
            <CardActions>
                <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between", width: "100%" }}
                >
                    <Stack spacing={2} direction="row" alignItems="center">
                        <StarBorderIcon sx={{ color: "primary.main" }} />
                        <Typography variant="body2" component="span">

                            {averageRating} stars
                        </Typography>
                    </Stack>
                    <Box>
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ alignItem: "center", cursor: "pointer" }}
                        >

                            <Avatar
                                sx={{ textDecoration: "none", width: "30px", height: "30px" }}
                                component={Link}
                                to={`/rent-profile/${product.userID._id}`}
                            >
                                {product.userID.profilePicture ? (
                                    <img
                                        src={product.userID.profilePicture}
                                        alt={product.userID.username || "user"}
                                        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                                        loading="lazy"
                                    />
                                ) : (
                                    product.userID?.username?.[0].toUpperCase() 
                                )}
                            </Avatar>
                            <Typography
                                gutterBottom
                                variant="body2"

                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    lineHeight: "30px",
                                    color: "primary.main",
                                    textDecoration: "none",
                                }}
                                component={Link}
                                to={`/rent-profile/${product.userID._id}`}
                            >
                                {

                                    product.userID.username
                                }
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
            </CardActions>
        </Card>

    );
};
