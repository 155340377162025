import { useState } from 'react';
import { Box, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { setAlertDetails } from '../../../redux/slices/AlertDetailsSlice';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../../axios/axiosInstance';
export const EditStatusTransaction = ({ transactionID }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };

    // handle open menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // handle menu item click action and close menu after click action and save new status
    const handleMenuItemClick = (action) => {

        axiosInstance.put(`/admin/transactions/${transactionID}/status`, { status: action }).then(() => {

            setAlertDetailsToRedux("success", "Status Updated Successfully")

        }).catch((error) => {
            setAlertDetailsToRedux("error", error.response.data.error)
        });
        handleClose();

    };

    // handle close menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <MoreVertIcon
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ cursor: "pointer" }}
            />
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuItem onClick={() => handleMenuItemClick("Pending")} >
                    Pending
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick("Approved")} >
                    Approved
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick("Rejected")} >
                    Rejected
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick("Ongoing")} >
                    Ongoing
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick("Completed")} >
                    Completed
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick("Cancelled")} >
                    Cancelled
                </MenuItem>
            </Menu>
        </Box>
    );
};
