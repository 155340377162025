import { Avatar, Box, Stack, Typography, } from '@mui/material'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/slices/AuthUser';
import axiosInstance from '../../axios/axiosInstance';
import { MessagesSkeleton } from '../general/loading/MessagesSkeleton';
export const MessagesHistory = () => {
    const theme = useTheme();
    const userId = useSelector(getUser)?.id; // get user id from redux
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const getMessages = () => {
            setLoading(true)
            axiosInstance.get(`/messages/${userId}`).then(res => {
                setMessages(res.data.messages)
            }).finally(() => {
                setLoading(false)
            })
        };
        getMessages()
    }, [userId])
    return (
        <Box sx={{
            height: "100vh", width: "100%", overflowY: "scroll", border: "1px solid #ccc", borderRadius: "20px", padding: "20px",
            display: messages.length > 0 ? "block" : "flex",
            alignItems: "center",
            justifyContent: "center",
            "&::-webkit-scrollbar": {
                width: "0.5em"
            },

            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.secondary.main,
                borderRadius: "20px"
            }
        }}>
            <Stack spacing={1} sx={{ width: "100%" }} >
                {
                    loading ? (
                        ["", "", "", "", "", ""].map((_, index) => (
                            <MessagesSkeleton key={index} />
                        ))
                    ) : (

                        messages.length > 0 ? (
                            messages.map((message) => {
                                return (
                                    <Stack
                                        key={message._id}
                                        sx={{
                                            backgroundColor: "text.main",
                                            borderRadius: "10px",
                                            p: 2,
                                            position: "relative",
                                        }}
                                    >

                                        <Stack direction="row" sx={{ alignItems: "center" }}>
                                            <Avatar
                                                sx={{ textDecoration: "none", width: "30px", height: "30px", mr: 1 }}
                                            >
                                                {message.sender.profilePicture ? (
                                                    <img
                                                        src={message.sender.profilePicture}
                                                        alt={message.sender.username || "user"}
                                                        loading="lazy"
                                                        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                                                    />
                                                ) : (
                                                    message.sender.username && message.sender.username[0].toUpperCase()
                                                )}
                                            </Avatar>
                                            <Link
                                                to={`/contactWithUser/${message.sender._id}/${message.sender.username}`}
                                                style={{
                                                    textDecoration: "none",
                                                    color: theme.palette.mode === "dark" ? "white" : theme.palette.primary.main,
                                                    fontSize: "14px",
                                                    fontWeight: "bold"
                                                }}
                                            >
                                                {message.sender.username}
                                            </Link>
                                        </Stack>
                                        <Stack sx={{ pl: 2, mt: 2 }}>
                                            <Typography variant="body1">{message.content}</Typography>
                                        </Stack>
                                    </Stack>
                                );
                            })
                        ) : <Typography variant="h6" component="div" sx={{ color: "primary.main", textAlign: "center" }}>No Messages Yet</Typography>

                    )
                }
            </Stack>
        </Box>
    )
}

