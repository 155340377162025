import { Box, Drawer, List, ListItemButton, ListItemText } from "@mui/material"
import { Link } from "react-router-dom"
import CloseIcon from '@mui/icons-material/Close';

export const AsideMobile = ({ openDrawer, handleCloseDrawer, user }) => {
    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={handleCloseDrawer}
            sx={{
                backgroundColor: "primary",
                "& .MuiPaper-root ": {
                    borderRadius: "50px 0px 0px 50px",
                    "& .MuiBox-root": {
                        backgroundColor: "text.main"

                    }
                }
            }}
        >
            <Box
                sx={{
                    width: "300px",
                    maxWidth: "300px",
                    bgcolor: "background.paper",
                    height: "100%",
                    py: 5,
                }}
            >
                <CloseIcon sx={{ color: "secondary.main", fontSize: "35px", marginLeft: "20px", cursor: "pointer" }} onClick={handleCloseDrawer} />
                <List>

                    {
                        // if login user is admin then show admin button
                        user && user.role === "Admin" ? (
                            <ListItemButton
                                component={Link}
                                to={`/admins`}
                                onClick={handleCloseDrawer}
                                sx={{
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                    },
                                }}
                            >
                                <ListItemText primary="Admin" sx={{ textAlign: "left", px: 3, color: "secondary.main", fontSize: "18px" }} />
                            </ListItemButton>
                        ) : null

                    }

                    <ListItemButton
                        component={Link}
                        to={`/`}
                        onClick={handleCloseDrawer}
                        sx={{
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            },
                        }}
                    >
                        <ListItemText primary="Home" sx={{ textAlign: "left", px: 3, color: "secondary.main", fontSize: "18px" }} />
                    </ListItemButton>
                    <ListItemButton
                        component={Link}
                        to={`/about-us`}
                        onClick={handleCloseDrawer}
                        sx={{
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            },
                        }}
                    >
                        <ListItemText primary="About us" sx={{ textAlign: "left", px: 3, color: "secondary.main", fontSize: "18px" }} />
                    </ListItemButton>
                    <ListItemButton
                        component={Link}
                        to={`/shop`}
                        onClick={handleCloseDrawer}
                        sx={{
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            },
                        }}
                    >
                        <ListItemText primary="Shop" sx={{ textAlign: "left", px: 3, color: "secondary.main", fontSize: "18px" }} />
                    </ListItemButton>
                    {
                        user && (
                            <ListItemButton
                                component={Link}
                                to={`/add-new-product`}
                                onClick={handleCloseDrawer}
                                sx={{
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                    },
                                }}
                            >
                                <ListItemText primary="Rent" sx={{ textAlign: "left", px: 3, color: "secondary.main", fontSize: "18px" }} />
                            </ListItemButton>
                        )
                    }
                    <ListItemButton
                        component={Link}
                        to={`/categories`}
                        onClick={handleCloseDrawer}
                        sx={{
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            },
                        }}
                    >
                        <ListItemText primary="Categories" sx={{ textAlign: "left", px: 3, color: "secondary.main", fontSize: "18px" }} />
                    </ListItemButton>
                    <ListItemButton
                        component={Link}
                        to={`/contact-us`}
                        onClick={handleCloseDrawer}
                        sx={{
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            },
                        }}
                    >
                        <ListItemText primary="Contact us" sx={{ textAlign: "left", px: 3, color: "secondary.main", fontSize: "18px" }} />
                    </ListItemButton>

                </List>
            </Box>
        </Drawer>
    )
}
