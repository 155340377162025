import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button, Stack } from '@mui/material'
import { useState } from 'react';
import { setAlertDetails } from '../../redux/slices/AlertDetailsSlice';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../axios/axiosInstance';
/* import axiosInstance from '../../axios/axiosInstance'; */
export const PaymentForm = ({ transactionID, status }) => {
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch();
    const [paymentMethod, setPaymentMethod] = useState('Credit Card');

    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };

    // handle payment method change
    const handleChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    // sunmit payment method to the server
    const handleSubmit = (event) => {
        event.preventDefault();
        // validate the payment method before sending it to the server
        if (paymentMethod === "") {
            setAlertDetailsToRedux("error", "Please select a payment method")

        }

        if (paymentMethod === "Cash On Delivery") {
            axiosInstance.post("payments/create", { transactionID: transactionID }).then(() => {
                setAlertDetailsToRedux("success", "Transaction created successfully")
                setSuccess(true)
            }).catch((error) => {
                setAlertDetailsToRedux("error", error.response.data.error)
            })

        } else {
            axiosInstance.post("/payments/stripe-payment", { transactionId: transactionID }).then((response) => {
                window.location.href =
                    `${response.data.checkoutURL}`;
            })
        }


    }
    return (
        <Box sx={{ py: 2 }}>
            <form>
                <Stack spacing={2}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Payment Method</FormLabel>
                        <RadioGroup row aria-label="paymentMethod" name="row-radio-buttons-group" value={paymentMethod} onChange={handleChange}>
                            <FormControlLabel value="Credit Card" control={<Radio />} label="Credit Card" />
                            <FormControlLabel value="Cash On Delivery" control={<Radio />} label="Cash On Delivery" />
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ px: 8 }}>
                        <Button fullWidth variant="contained" color="secondary" type="submit" onClick={handleSubmit} disabled={status === "Ongoing" || success}>
                            Rent Now
                        </Button>
                    </Box>
                </Stack>
            </form>

        </Box>
    )
}