import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useEffect } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { LoadingMoreProductsAnimation } from "../general/loading/LoadingMoreProductsAnimation";
import { SkeletonLoadingProducts } from "../general/loading/SkeletonLoadingProducts";
import axiosInstance from "../../axios/axiosInstance";
import { getUser } from "../../redux/slices/AuthUser";
import { useSelector } from "react-redux";
import { ProductItem } from "../general/ProductItem";
const FavoritesProductsList = () => {
    const userId = useSelector(getUser)?.id; // get user id from redux
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [endProducts, setEndProducts] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);
    const limit = 10;
    // Fetch initial products when the component mounts
    useEffect(() => {
        setLoadingPage(true);
        const fetchInitialProducts = async () => {
            try {
                const response = await axiosInstance.get(
                    `/products/user/${userId}/favorite-products?page=${1}&limit=${limit}`
                );
                if (response.status === 200) {

                    setProducts(response.data.favoriteProducts);
                }
            } catch (_) { /* empty */ }
            setLoadingPage(false);
        };

        fetchInitialProducts();
    }, [userId]);

    // Fetch additional products when the page number changes depending on the scroll position
    const fetchAdditionalProducts = async (pageNumber) => {
        try {
            if (!loadingPage) {
                // Prevents the loading animation from showing when the page is loading if the initial products are still loading
                setLoading(true);
            }

            const response = await axiosInstance.get(
                `/products/user/${userId}/favorite-products?page=${pageNumber}&limit=${limit}`
            );

            if (response.status === 404) {
                setEndProducts(true);
            } else {
                setProducts((prevProducts) => {
                    // Using the callback form to ensure we work with the latest state
                    return [...prevProducts, ...response.data.favoriteProducts];
                });
                setPage(pageNumber);
            }
        } catch (error) {
            if (error.response?.status === 404) {
                setEndProducts(true);
            }
        } finally {
            setLoading(false);
        }
    };
    return (
        <InfiniteScroll
            style={{ overflow: "hidden", padding: "5px", height: products.length > 0 ? "auto" : "40vh", display: products.length > 0 ? "block" : "flex", justifyContent: "center", alignItems: "center", position: "relative" }}
            dataLength={products.length}
            next={() => fetchAdditionalProducts(page + 1)}
            hasMore={!endProducts}
            loader={
                loading && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            position: "absolute",
                            bottom: "5px",
                            right: "0px",
                            width: "100%",
                        }}
                    >
                        <Paper
                            sx={{
                                p: 2,
                                width: "100%",
                                backgroundColor: "shadow.main",
                                color: "primary.main",
                                textAlign: "center",
                            }}
                        >
                            <LoadingMoreProductsAnimation loadingText="Loading More Products ..." />
                        </Paper>
                    </Grid>
                )
            }
        >
            <Grid container spacing={5}>
                {loadingPage ? ["", "", "",].map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} >
                        <SkeletonLoadingProducts />
                    </Grid>
                )) : products.length > 0 ? (
                    products.map((product, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <ProductItem product={product} key={index} />
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ textAlign: "center", color: "primary.main" }}
                        >
                            No Products In Your WishList
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </InfiniteScroll>
    );
};

export default FavoritesProductsList;   