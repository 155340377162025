import { Router } from "./router.jsx";
import { createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/store";
import { persistor } from "./redux/store";
import "./index.css";
import { AlertMessage } from "./components/general/Alert.jsx";

const Main = () => {
  // create custom theme
  const theme = createTheme({
    // set font family to use in the project
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),

    },
    palette: {
      mode: "light",
      primary: {
        main: "#FE1F02",
      },
      secondary: {
        main: "rgb(0, 56, 70)",
      },
      text: {
        main: "#F3E9DF",
        secondary: "#828282"

      },
      error: {
        main: "#ff0000",
      },
      warning: {
        main: "#ffbb00",
      },
      info: {
        main: "#00ff55",
      },
      success: {
        main: "#00ff55",
      },
      shadow: {
        main: "#c7bfbf85",
        light: "#c7bfbf26",
      },
      background: {
        default: "#FFFFFF",
        paper: "#FFFFFF",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <>
        <CssBaseline />
        <Router />
        <AlertMessage />
      </>
    </ThemeProvider>
  );
};


export const MainComponent = () => {

  return (

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Main />
      </PersistGate>
    </Provider>
  )
}
