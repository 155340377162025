import { Box, Container, Grid, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import axiosInstance from "../../axios/axiosInstance";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import icons 
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import { CategoriesSkeleton } from "../../components/general/loading/CategoriesSkeleton";
export const ShowAdminCategories = () => {
     const [categories, setCategories] = useState([])
     const [loading, setLoading] = useState(false)
     const dispatch = useDispatch();
     const navigate = useNavigate();
     // set alert details to redux
     const setAlertDetailsToRedux = (type, message) => {
          dispatch(setAlertDetails({ type, message }))
     };

     // get all categories from server
     useEffect(() => {
          setLoading(true);
          axiosInstance.get("/products/categories").then((res) => {
               setCategories(res.data);
               setLoading(false);
          }).catch(() => {
               setCategories([]);
          });

     }, []);

     // delete category
     const deleteCategory = (id) => {
          axiosInstance.delete(`/admin/categories/${id}`).then(() => {
               setAlertDetailsToRedux('success', "Category deleted successfully")

          }).catch((err) => {
               setAlertDetailsToRedux('error', err.response.data.error)

          })
     }
     const goToEditCategory = (id) => {
          navigate(`/admins/edit-categories/${id}`)
     }
     return (
          <Box sx={{ py: 8 }}>
               <Container>
                    <Typography variant="h4" sx={{ color: "primary.main", textAlign: "center", fontWeight: "bold", pb: 8 }}>Categories</Typography>
                    <Grid container spacing={5}>
                         {
                              loading ?
                                   ["", "", "", "", "", "", "", "", "", "", "", ""].map((item, index) => (
                                        <Grid item xs={12} sm={6} md={3} key={index} >
                                          <CategoriesSkeleton/>
                                        </Grid>
                                   )

                                   ) : categories.map((category) => (
                                        <Grid item xs={12} sm={6} md={4} key={category._id} >
                                             <Paper sx={{ textAlign: "center", borderRadius: "20px", position: "relative" }}>
                                                  <Box sx={{ height: "30px", width: "30px", backgroundColor: "white", position: "absolute", top: "5px", right: "50px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                                       <EditIcon sx={{ cursor: "pointer" }} onClick={() => goToEditCategory(category._id)} />
                                                  </Box>
                                                  <Box sx={{ height: "30px", width: "30px", backgroundColor: "white", position: "absolute", top: "5px", right: "5px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                                       <DeleteIcon sx={{ cursor: "pointer", color: "primary.main" }} onClick={() => deleteCategory(category._id)} />
                                                  </Box>
                                                  <img src={category.picture} alt={category.name} style={{ width: "100%", height: "200px", objectFit: "cover", borderRadius: "20px" }} />
                                                  <Typography variant="body1" sx={{ color: "secondary.main", pt: 3 }}>{category.name}</Typography>
                                             </Paper>
                                        </Grid>
                                   ))
                         }
                    </Grid>
               </Container>

          </Box>
     )
}


