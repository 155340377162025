import { Box, Container, Grid, Typography } from "@mui/material"
import back1 from "../../../assets/images/back1.webp"
import back2 from "../../../assets/images/back2.webp"
import back3 from "../../../assets/images/back3.webp"
const items = [
    {
        img: back1,
        title: "Verified renters",
        description: "Our comprehensive process verifies multiple factors, like government ID, to confirm the identity of renters."
    },
    {
        img: back2,
        title: "Fast payouts",
        description: "All payments are easy, secure, and automatically deposited into your account."
    },
    {
        img: back3,
        title: "Hassle-free contracts",
        description: "Easily list your underused items for rent or sale for. What’s more, as a Rental Listing Owner, you have complete control over prices, availability, collection, and who you choose to rent to."
    }
]
export const YourBack = () => {
    return (
        <Box sx={{ py: 4 }}>
            <Container>
                <Typography variant="h4" component={"h5" } align="center" sx={{ color: "primary.main", fontSize: ["30px", "40px"], fontWeight: "bold",fontFamily:"Poppins-Bold" }}>
                    we’ve got your back
                </Typography>
                <Typography variant="h6"   component={"h6" } align="center" sx={{ color: "secondary.main", pt: 2 }}>
                    Everything you need to rent your stuff with confidence
                </Typography>
                <Grid container spacing={"50px"} sx={{ my: 4 }}>
                    {items.map((item) => (
                        <Grid item xs={12} md={4} key={item.title}>
                            <Box sx={{ textAlign: "center" }}>
                                <Box sx={{ width: "100px", height: "80px", margin: "auto", mb: 1 }}>
                                    <img src={item.img} alt="img" loading="lazy" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                </Box>
                                <Typography variant="h5" sx={{ color: "secoundry.main", fontWeight: "bold" }}>{item.title}</Typography>
                                <Typography sx={{ color: "secoundry.main", pt: 2 }}>{item.description}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>

        </Box>
    )
}
