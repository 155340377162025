import { Box, Container, TextField, Button, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axios/axiosInstance";
import { useParams } from "react-router-dom";
import axios from "axios";
import imageCompression from 'browser-image-compression'
import UpgradeIcon from '@mui/icons-material/Upgrade';
export const EditCategories = () => {
    const [category, setCategory] = useState({
        name: "",
        picture: ""
    });
    const [imageUploading, setImageUploading] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();
    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };
    const handleCategoryNameChange = (event) => {
        setCategory({ ...category, name: event.target.value });
    };

    // get presigned url
    const getPresignedUrl = async (image) => {
        try {
            if (!image.type.startsWith("image/")) {
                setAlertDetailsToRedux("error", "Unsupported file format, only images are allowed");
            }
            const response = await axiosInstance.post(
                "admin/categories/upload-image-url",
                {
                    contentType: image.type,
                }
            );
            const presignedUrl = response.data.presignedUrl;
            const imageUrl = response.data.imgUrl;
            if (response.status === 200) {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                }
                const compressedImage = await imageCompression(image, options);
                await axios.put(presignedUrl, compressedImage, {
                    headers: {
                        "Content-Type": image.type,
                    },
                });
                return imageUrl;
            } else {
                setAlertDetailsToRedux("error", "Failed uploading image, please try again later");
            }
        } catch (err) {
            setImageUploading(false);
            setAlertDetailsToRedux('error', "Failed uploading image, please try again later");
        }
    };
    // handle image change
    const handleImageChange = async (event) => {
        try {
            setImageUploading(true);
            const selectedImage = event.target.files[0];
            const imageUrl = await getPresignedUrl(selectedImage);
            setCategory({ ...category, picture: imageUrl });
            setImageUploading(false); // stop uploading image loader
        } catch (err) {
            setImageUploading(false);
            setAlertDetailsToRedux('error', err.message);
        }
    };
    const handleEditCategory = () => {
        axiosInstance.put(`admin/categories/${id}`, category).then(() => {
            setAlertDetailsToRedux('success', "Category edit successfully")

        }).catch((err) => {
            setAlertDetailsToRedux('error', err.response.data.error)

        });

    };
    // get category details
    useEffect(() => {
        axiosInstance.get(`admin/categories/${id}`).then((res) => {
            setCategory({
                name: res.data.category.name,
                picture: res.data.category.picture
            
            })
        }).catch(() => {

        });
    }, [id]);

    return (
        <Box py={8}>
            <Typography variant="h4" sx={{ color: "primary.main", textAlign: "center", fontWeight: "bold", pb: 8 }}>
                Edit Category
            </Typography>


            <Container maxWidth="md" sx={{ py: 3 }}>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Category Name"
                                variant="outlined"
                                value={category.name}
                                onChange={handleCategoryNameChange}
                                fullWidth
                            />
                            <Button variant="contained" color="primary" onClick={handleEditCategory} sx={{ mt: 7, display: ["none", "block"] }} fullWidth>
                                Save Edit
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ width: '100%', height: "150px", border: '1px dashed grey', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                <img src={category.picture} alt={category.name} style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "20px" }} />
                                <label htmlFor="file-upload" style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>

                                    <input id="file-upload" type="file" onChange={handleImageChange} hidden />
                                    {
                                        !imageUploading && (
                                            <Box sx={{backgroundColor: "white", padding: "10px", borderRadius: "10px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                               <UpgradeIcon sx={{ cursor: "pointer", color: "secondary.main", fontSize: "40px" }} />
                                            </Box>
                                        )
                                    }
                                </label>
                                {imageUploading ? (
                                    <Box sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', zIndex: "1000", backgroundColor: "#808080a8", color: "secondary.main", fontWeight: "bold" }}>
                                        Uploading...
                                    </Box>
                                ):null}

                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ display: ["block", "none"] }}>
                            <Button variant="contained" color="primary" onClick={handleEditCategory} fullWidth>
                                Save Edit
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </Container>

        </Box>

    );
};


