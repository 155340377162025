import { Box, Button, Stack, TextField, } from '@mui/material'
import { useEffect, useState } from 'react'
import { setAlertDetails } from '../../../redux/slices/AlertDetailsSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios/axiosInstance';
import { EditAcountInfoSkeleton } from '../../general/loading/EditAcountInfoSkeleton';
import { fetchUser } from '../../../redux/slices/AuthUser';
export const FormAcountInfo = ({ userInfo }) => {
    const dispatch = useDispatch();
    const { userId } = useParams();
    const [user, setUser] = useState(
        {
            username: "",
            displayName: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
        }
    )
    useEffect(() => {
        setUser({
            username: userInfo?.username ? userInfo.username : "",
            displayName: userInfo?.displayName ? userInfo.displayName : "",
            firstName: userInfo?.firstName ? userInfo.firstName : "",
            lastName: userInfo?.lastName ? userInfo.lastName : "",
            phoneNumber: userInfo?.phoneNumber ? userInfo.phoneNumber : "",
            email: userInfo?.email ? userInfo.email : "",

        })
    }, [userInfo])
    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({ ...user, [name]: value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (user.username === "") {
            setAlertDetailsToRedux("error", "username shoud not be empty")

            return;
        }
        axiosInstance.put(`/user/${userId}`, user
        ).then(() => {
            setAlertDetailsToRedux("success", "User updated successfully")

            // fetch new user data after update
            dispatch(fetchUser(userId));

        }).catch(err => {
            setAlertDetailsToRedux("error", err.response.data.error)

        })

    };
    return (

        <>
            {
                userInfo ? (

                    <Box>
                        <form>
                            <Stack spacing={2}>
                                <TextField
                                    name="username"
                                    label="username"
                                    variant="outlined"
                                    size="small"
                                    value={`@${user.username}`}
                                    onChange={handleChange}
                                    disabled
                                />
                                <TextField
                                    name="displayName"
                                    label="Display Name"
                                    variant="outlined"
                                    size="small"
                                    value={user.displayName}
                                    onChange={handleChange}
                                />
                                <TextField
                                    name="firstName"
                                    label="First Name"
                                    variant="outlined"
                                    size="small"
                                    value={user.firstName}
                                    onChange={handleChange}
                                />
                                <TextField
                                    name="lastName"

                                    label="Last Name"
                                    variant="outlined"
                                    size="small"
                                    value={user.lastName}
                                    onChange={handleChange}
                                />
                                <TextField
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    size="small"
                                    value={user.email}
                                    onChange={handleChange}
                                />
                                <TextField
                                    name="phoneNumber"
                                    label="Phone Number"
                                    variant="outlined"
                                    size="small"
                                    value={user.phoneNumber}
                                    onChange={handleChange}
                                />
                                <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>
                                    Save
                                </Button>
                            </Stack>

                        </form>
                    </Box>
                ) : (
                    <EditAcountInfoSkeleton />
                )
            }
        </>

    )
}
