import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import axiosInstance from '../../../axios/axiosInstance'
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from 'react-redux';
import { setAlertDetails } from '../../../redux/slices/AlertDetailsSlice';
import { getUser } from '../../../redux/slices/AuthUser';
import { useNavigate } from 'react-router-dom';
export const CommentItem = ({ comment }) => {
    const authUser = useSelector(getUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [average, setAverage] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }));
    };
    // handle delete comment
    const handleDeleteComment = (ID) => {
        setAnchorEl(null);
        axiosInstance
            .delete(
                `/comments/${ID}`
            )
            .then(() => {

                setAlertDetailsToRedux("success", "Comment deleted successfully");

            })
            .catch((err) => {
                setAlertDetailsToRedux("error", err.response.data.error);

            });
    };

    // handle go to edit comment page 
    const handleEditComment = (CommentId, ProductId) => {
        setAnchorEl(null);
        navigate(`/edit-comment/${ProductId}/${CommentId}`)
    }

    useEffect(() => {
        // get owner of comment rating
        const getRatingAvarege = () => {
            axiosInstance.post(`ratings/average-rating`, {
                id: comment ? comment.sender._id : "",
            }).then(res => {
                setAverage(res.data.averageRating)
            }).catch(err => {
                if (err.response.status === 404) {
                    setAverage(0)
                }
            })
        }
        getRatingAvarege()
    }, [comment])
    return (
        <Box sx={{ backgroundColor: "text.main", p: 1, borderRadius: "5px" }}>
            <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack direction="row" spacing={2}>
                    <Typography variant="h6" component="div">
                        {comment.sender.username}
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center" }}>
                        <StarIcon sx={{ color: "primary.main" }} />
                        <Typography variant="body1" component="div" sx={{ color: "secondary.main" }}>
                            {average} stars
                        </Typography>
                    </Stack>
                </Stack>
                {
                  authUser?.id === comment.sender._id && (
                        <Box >
                            <MoreVertIcon
                                id="demo-positioned-button"
                                aria-controls={
                                    open ? "demo-positioned-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(event) => setAnchorEl(event.currentTarget)}
                                sx={{ cursor: "pointer" }}
                            />

                            <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <MenuItem
                                    onClick={() => handleEditComment(comment._id, comment.product)}
                                >
                                    Edit
                                </MenuItem>
                                <MenuItem
                                    onClick={() => handleDeleteComment(comment._id)}
                                >
                                    Delete
                                </MenuItem>
                            </Menu>
                        </Box>
                    )
                }

            </Stack>
            <Typography variant="body1" component="div">
                {comment.content}
            </Typography>

        </Box>
    )
}
