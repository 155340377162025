import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
// import svg icons
import history from "../../../assets/images/history.webp";
import profile from "../../../assets/images/profile.webp";
import chat from "../../../assets/images/chat.webp";
import settings from "../../../assets/images/settings.webp";
import favourites from "../../../assets/images/favourites.webp";
import { useSelector } from 'react-redux';
import { getUser } from '../../../redux/slices/AuthUser';
export const SettingBar = ({ navbarRef, openStack }) => {
    const userId = useSelector(getUser)?.id; // get user id from redux

    return (
        <Stack sx={{ flexDirection: ["column", "row", "row", "row"], justifyContent: "space-evenly", alignItems: "center", bgcolor: "secondary.main", py: 5, borderBottomLeftRadius: "40px", borderBottomRightRadius: "40px", width: "100%", position: "absolute", top: openStack ? `${navbarRef.current.clientHeight}px` : "-100%", zIndex: 200, transition: "all .5s ease-in-out" }}>
            <Button sx={{
                my: 2, color: "text.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out',fontWeight:"bold",
                '&:hover': {
                    transform: 'scale(1.05)',
                },
            }} component={Link} to={`/transactions`}>
                <img src={history} alt="history" loading='lazy' style={{
                    width: "20px", height: "20px", marginLeft: "10px", transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'scale(1.05)',
                    },
                }} />
                History

            </Button>
            <Button sx={{
                my: 2, color: "text.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out',fontWeight:"bold",
                '&:hover': {
                    transform: 'scale(1.05)',
                },
            }} component={Link} to={`/profile`}>
                <img src={profile} alt="profile" loading='lazy' style={{ width: "20px", height: "20px", marginLeft: "10px", }} />
                Profile
            </Button>
            <Button sx={{
                my: 2, color: "text.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out',fontWeight:"bold",
                '&:hover': {
                    transform: 'scale(1.05)',
                },
            }} component={Link} to={`/chat`}>
                <img src={chat} alt="chat" loading='lazy' style={{ width: "20px", height: "20px", marginLeft: "10px", }} />
                Chat
            </Button>
            <Button sx={{
                my: 2, color: "text.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out',fontWeight:"bold",
                '&:hover': {
                    transform: 'scale(1.05)',
                },
            }} component={Link} to={`/settings`}>
                <img src={settings} alt="settings" loading='lazy' style={{ width: "20px", height: "20px", marginLeft: "10px", }} />
                Settings
            </Button>
            <Button sx={{
                my: 2, color: "text.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out',fontWeight:"bold",
                '&:hover': {
                    transform: 'scale(1.05)',
                },
            }} component={Link} to={`/favorite-products/${userId}`}>
                <img src={favourites} alt="favourites" loading='lazy' style={{ width: "20px", height: "20px", marginLeft: "10px"}} />
                Favourites
            </Button>
            <Button sx={{ my: 2, color: "text.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: "16px",fontWeight:"bold" }} component={Link} to={`/my-products`}>
                Your Products
            </Button>
        </Stack>
    )
}
