import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {  Stack, Typography } from "@mui/material";
import { EditStatusTransaction } from "./EditStatusTransaction";
// format date to be more readable
const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    });
};
const getStatusColor = (status) => {
    switch (status) {
        case "Pending":
            return "orange";
        case "Approved":
            return "green";
        case "Rejected":
            return "red";
        case "Ongoing":
            return "blue";
        case "Completed":
            return "gray";
        case "Cancelled":
            return "red";
        default:
            return "black";
    }
};

export const TransactionsTable = ({ transactions }) => {

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            Name
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            Start At
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            End At
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            Status

                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            Renter
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            Owner
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions
                        ? transactions.map((transaction, i) => {
                            return (
                                <TableRow
                                    key={i}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell align="center">
                                        {transaction.productID.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {formatDate(transaction.startDate)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {formatDate(transaction.endDate)}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ color: getStatusColor(transaction.status) }}
                                    >
                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                            <Typography sx={{ fontSize: ["13px", "16"] }} component="span">  {transaction.status}</Typography>
                                            <Typography component="span" sx={{ fontSize: ["13px",] }}>/</Typography>
                                            <EditStatusTransaction  transactionID={transaction._id}/>
                                        </Stack>

                                    </TableCell>
                                    <TableCell align="center">

                                        {transaction.userID.username}
                                    </TableCell>
                                    <TableCell align="center">
                                        {transaction.ownerID.username}
                                    </TableCell>
                                   
                                </TableRow>
                            );
                        })
                        : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
