import * as yup from "yup";

export const PaymentFormSchema = yup.object({
  PaymentMethod: yup.string().required("Payment method is required"),
  FullName: yup.string().required("Full name is required"),
  Country: yup.string().required("Country is required"),
  State: yup.string().required("State is required"),
  Zip: yup.string().required("Zip is required"),
});

export const signupValidationSchema = yup.object({
  //  username must be between 3 and 30 characters long and can only contain letters and numbers
  username: yup
    .string()
    .required("username is required")
    .min(3, "username must be at least 3 characters")
    .max(30, "username must be at most 30 characters")
    .matches(
      /^[a-zA-Z0-9]+$/,
      "username must contain only letters and numbers"
    ),
  // must be email format.
  identifier: yup.string().required("Email or Phone Number is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/,
      "Password must contain at least one letter and one number"
    ),

});

export const loginValidationSchema = yup.object({
  // identifier may be email or phone number
  identifier: yup.string().required("Email or Phone Number is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/,
      "Password must contain at least one letter and one number"
    ),
});

export const addProductValidationSchema = yup.object({
  category: yup.string().required("Category is required"),
  name: yup.string().required("Title is required"),
  price: yup
    .number()
    .required("Price is required")
    .positive("Price must be positive"),
  per: yup.string().required("Time is required"),
  description: yup.string().required("Description is required"),
  condition: yup.string().required("Product State is required"),
  
  pictures: yup
  .array()
  .min(1, "At least 1 image is required")
  .test(
    "mainImage",
    "Main image is required",
    (value) => value[0] !== null
  )
  

});

export const discussionValidationSchema = yup.object({
  comment: yup.string().required("Comment is required"),
});
// review validation schema
export const reviewValidationSchema = yup.object({
  review: yup.string().required("Review is required"),
});

export const contactWithUserValidationSchema = yup.object({
  message: yup.string().required("Message is required"),
});

export const userDetailsValidationSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  displayName: yup.string().required("Display Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  address: yup.string().required("Address is required"),
  location: yup.object().nullable().required("Location is required"),
});

export const ResetPasswordValidationSchema = yup.object({
  resetToken: yup.string().required("Reset Token is required"),
  newPassword: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/,
      "Password must contain at least one letter and one number"
    ),
});

// signup with referral validation schema

export const signUpWithReferralValidationSchema = yup.object({
  username: yup
    .string()
    .required("username is required")
    .min(3, "username must be at least 3 characters")
    .max(30, "username must be at most 30 characters")
    .matches(
      /^[a-zA-Z0-9]+$/,
      "username must contain only letters and numbers"
    ),
  email: yup
    .string()
    .required("Email is required")
    .email("Email must be a valid email address"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/,
      "Password must contain at least one letter and one number"
    ),
    repeatPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
  referralCode: yup.string().required("Referral code is required"),
});