import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { Form, Formik } from "formik";
import { userDetailsValidationSchema } from "../../../validation/validationSchemas";
import { useSelector, useDispatch } from "react-redux";
import { setOpenDialog } from "../../../redux/slices/userDetailsDialogSlice";
import { setAlertDetails } from "../../../redux/slices/AlertDetailsSlice";
import axiosInstance from "../../../axios/axiosInstance";
import { Maps } from "./Map";
import { InputFiled } from "./InputFiled";
import imageCompression from 'browser-image-compression'
import axios from "axios";
import {  useState } from "react";
import { ProfileImage } from "./ProfileImage";
import { fetchUser, getUser } from "../../../redux/slices/AuthUser";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    minHeight: "90vh",
    borderRadius: "50px",
  },

  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.5) !important",
    backdropFilter: "blur(3px)",
  },
  '@media (max-width: 400px)': {
    "& .MuiPaper-root": {
      minWidth: window.innerWidth - 10 + "px"
    },
  },
  '@media (min-width: 768px)': {
    "& .MuiPaper-root": {
      minWidth: "600px"
    },
  },
}));

const UserDetailsDialog = () => {
  const authUser = useSelector(getUser); // get user id from redux
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const openDialog = useSelector((state) => state.openDialog.open); // get open dialog

  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };
  const handleClose = () => {
    dispatch(setOpenDialog(false));
  };


  const initialValues = {
    firstName:authUser?.user? authUser.user.firstName:"" ,
    lastName: authUser?.user?authUser.user.lastName:"" ,
    displayName: authUser?.user? authUser.user.phoneNumber:"" ,
    location: authUser?.user ? authUser.user.location : { lat: 0, lng: 0 },
    profilePicture: "",
    address: authUser?.user ? authUser.user?.address : "",

  };
  // save user details to server
  const saveUserDetails = (values) => {
    axiosInstance
      .put(
        `/user/${authUser?.id}`,
        values, {
      }
      )
      .then(() => {
        setAlertDetailsToRedux("success", "User Details Updated Successfully");
        handleClose();

      })
      .catch((err) => {
        if (err) {
          setAlertDetailsToRedux("error", err.response.data.error);

        }
      }).finally(() => {
        dispatch(fetchUser(authUser?.id));
      });
  };
  const handleSubmit = async (values,) => {
    setLoading(true);
    try {
      // check if profile picture is uploaded or not 
      if (values.profilePicture) {
        // get presigned url from server
        const response = await axiosInstance.post("/user/upload-image-url", {
          key: values.profilePicture.name,
          contentType: values.profilePicture.type

        });
        const presignedUrl = response.data.presignedUrl;
        const imageUrl = response.data.imgUrl;

        // compress image before uploading
        const options = {
          maxSizeMB: 1,
          useWebWorker: true
        };
        const compressedFile = await imageCompression(values.profilePicture, options).then(compressedFile => {
          return compressedFile;
        })
        const uploaded = await axios.put(presignedUrl, compressedFile, {
          headers: {
            'Content-Type': values.profilePicture.type
          }
        });

        if (uploaded.status === 200) {
          values.profilePicture = imageUrl;
          saveUserDetails(values)
        }
      } else {
        values.profilePicture = authUser?.user.profilePicture;
        saveUserDetails(values)
      }

    } 
    finally {
      setLoading(false);
    }
  };
  return (
    <>
      <CustomDialog
        open={openDialog}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ color: "primary.main", fontSize: ["16px", "25px"] }}>
          Please Complete This Step
        </DialogTitle>
        <DialogContent sx={{
          "&::-webkit-scrollbar": {
            width: "13px",
            boxShadow: "inset 0 0 5px grey",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(128, 128, 128, 0.375)",
            borderRadius: "10px",
          }

        }}>
          <DialogContentText
            sx={{
              mt: 2,
            }}
            id="scroll-dialog-description"
            tabIndex={-1}
            as="div"
          >
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={userDetailsValidationSchema}
              enableReinitialize={true}
            >
              {({ values, errors, touched, setFieldValue }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <InputFiled errors={errors} touched={touched} />
                      <Grid item xs={12}>
                        <Maps values={values} setFieldValue={setFieldValue} />
                      </Grid>
                      <ProfileImage values={values} setFieldValue={setFieldValue} />
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          {loading ? "Loading..." : "Send Details"}
                        </Button>
                      </Grid>
                    </Grid>

                  </Form>
                );
              }}
            </Formik>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>
            Skip Now
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default UserDetailsDialog;
