import { useState } from 'react';
import { Button, Rating, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertDetails } from '../../../redux/slices/AlertDetailsSlice';
import { getUser } from '../../../redux/slices/AuthUser';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios/axiosInstance';
export const AddRating = () => {
    const { userId } = useParams()
    const dispatch = useDispatch();
    const authUser = useSelector(getUser); // get user id from redux
    let [rating, setRating] = useState(2);
    // set alert details to redux
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };
    const handleSaveRating = () => {
        axiosInstance
            .post(
                '/ratings',
                {
                    itemOwnerID: userId,
                    rating: rating,
                    userID: authUser?.id,
                }
            )
            .then(() => {
                setAlertDetailsToRedux("success", "Rating Added Successfully!");

            })
            .catch((err) => {
                setAlertDetailsToRedux("error", err.response.data.error);

            });
    };

    // handle rating change
    const handleRatingUser = (event) => {
        setRating(parseInt(event.target.value));

    };

    return (
        <Stack spacing={2} sx={{ my: 2 }}>
            <Rating
                name="simple-controlled"
                value={rating}
                onChange={handleRatingUser}
                size="large"
                sx={{ color: 'primary.main' }}

            />
            <Button variant="contained" sx={{ mt: 2, width: '200px' }} onClick={handleSaveRating}>
                Add Rate
            </Button>
        </Stack>
    );
};
