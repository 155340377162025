import { useEffect, useState } from "react"
import axiosInstance from "../../../axios/axiosInstance"
import { getUser } from "../../../redux/slices/AuthUser"
import { useSelector } from "react-redux"
import { Grid, Stack } from "@mui/material"
import { SkeletonLoadingProducts } from "../../general/loading/SkeletonLoadingProducts"
import { ProductCard } from "./ProductCard"
export const PastRents = () => {
    const userId = useSelector(getUser)?.id;
    const [pastRent, setPastRent] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {

        const getPastRents = async () => {
            setLoading(true)
            // get past rents
            const pastRents = await axiosInstance.get(`transactions/completed/rents/${userId}`)
            setPastRent(pastRents.data.completedTransactions)
            setLoading(false)
        }
        getPastRents()
    }, [userId])

    return (
        <Grid container spacing={2}>
            {

                loading ? ["", "", "", "", "", ""].map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} >
                        <SkeletonLoadingProducts />
                    </Grid>
                )) : pastRent.length > 0 ? (
                    pastRent.map((rent) => (
                        <Grid item key={rent.id} xs={12} sm={12} md={6}>
                            <ProductCard transaction={rent} />
                        </Grid>
                    ))
                ) : (
                    <Stack direction="row" sx={{ justifyContent: "center", color: "primary.main", width: "100%" }} >
                        No Rent Found
                    </Stack>
                )
            }
        </Grid>
    )
}
