import { Box, Container, TextField, Button, Typography, Grid } from "@mui/material";
import { useState } from "react";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axios/axiosInstance";
import axios from "axios";
import imageCompression from 'browser-image-compression'

export const AddCategories = () => {
    const [category, setCategory] = useState({
        name: "",
        picture: ""
    });
    const [imageUploading, setImageUploading] = useState(false);
    const dispatch = useDispatch();
    const setAlertDetailsToRedux = (type, message) => {
        dispatch(setAlertDetails({ type, message }))
    };

    const handleCategoryNameChange = (event) => {
        setCategory({ ...category, name: event.target.value });
    };

    const getPresignedUrl = async (image) => {
        try {
            if (!image.type.startsWith("image/")) {
                setAlertDetailsToRedux("error", "Unsupported file format, only images are allowed");
            }
            const response = await axiosInstance.post(
                "admin/categories/upload-image-url",
                {
                    contentType: image.type,
                }
            );
            const presignedUrl = response.data.presignedUrl;
            const imageUrl = response.data.imgUrl;
            if (response.status === 200) {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                }
                const compressedImage = await imageCompression(image, options);
                await axios.put(presignedUrl, compressedImage, {
                    headers: {
                        "Content-Type": image.type,
                    },
                });
                return imageUrl;
            } else {
                setAlertDetailsToRedux("error", "Failed uploading image, please try again later");
            }
        } catch (err) {
            setImageUploading(false);
            setAlertDetailsToRedux('error', "Failed uploading image, please try again later");
        }
    };

    const handleImageChange = async (event) => {
        try {
            setImageUploading(true);
            const selectedImage = event.target.files[0];
            const imageUrl = await getPresignedUrl(selectedImage);
            setCategory({ ...category, picture: imageUrl });
            setImageUploading(false);
        } catch (err) {
            setImageUploading(false);
            setAlertDetailsToRedux('error', err.message);
        }
    };

    const handleAddCategory = () => {

        axiosInstance.post("admin/categories/create", category).then(() => {
            setAlertDetailsToRedux('success', "Category added successfully")
        }).catch((err) => {
            setAlertDetailsToRedux('error', err.response.data.error)
        });
    };

    return (
        <Box py={8}>
            <Typography variant="h4" sx={{ color: "primary.main", textAlign: "center", fontWeight: "bold", pb: 8 }}>
                Add Category
            </Typography>
            <Container maxWidth="md" sx={{ py: 3 }}>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Category Name"
                                variant="outlined"
                                value={category.name}
                                onChange={handleCategoryNameChange}
                                fullWidth
                            />
                            <Button variant="contained" color="primary" onClick={handleAddCategory} sx={{ mt: 7, display: ["none", "block"] }} fullWidth>
                                Add Category
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ width: '100%', height: "150px", border: '1px dashed grey', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                {
                                    category.picture ? <img src={category.picture} alt="category" style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} /> : (

                                        <Box variant="outlined" component="label" htmlFor="file-upload" sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                            <input
                                                id="file-upload"
                                                type="file"
                                                onChange={handleImageChange}
                                                hidden
                                            />
                                            {imageUploading ? 'Uploading...' : 'Upload Image'}
                                        </Box>
                                    )
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ display: ["block", "none"] }}>
                            <Button variant="contained" color="primary" onClick={handleAddCategory} fullWidth>
                                Add Category
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </Container>
        </Box>
    );
};

