import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { setActiveCategory, setFilterKeys, setSearchByName } from "../../../redux/slices/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Maps } from "../../general/Map";

export const AllFilterKey = () => {
  const dispatch = useDispatch();
  const filterKeys = useSelector((state) => state.filter.filterKeys);
  const [filtersKey, setFiltersKey] = useState({
    price: [
      filterKeys.priceMin ? filterKeys.priceMin : 0,
      filterKeys.priceMax ? filterKeys.priceMax : 10000,
    ],
    avilapility: {
      start: filterKeys.startDate ? dayjs(filterKeys.startDate) : null,
      end: filterKeys.endDate ? dayjs(filterKeys.endDate) : null,
    },
    condition: filterKeys.condition ? filterKeys.condition : "All",
    location: filterKeys.location ? filterKeys.location : null,
  });

  const handleFieldChange = (fieldName, newValue) => {
    setFiltersKey({
      ...filtersKey,
      [fieldName]: newValue,
    });
  };

  const updateFilterKey = () => {
    // validate the date
    if (filtersKey.avilapility.start > filtersKey.avilapility.end) {
      return;
    }
    const finalFilter = {
      priceMin: filtersKey.price[0],
      priceMax: filtersKey.price[1],
      condition: filtersKey.condition === "All" ? null : filtersKey.condition,
      startDate: filtersKey.avilapility.start
        ? dayjs(filtersKey.avilapility.start).format("ddd, MMM DD, YYYY")
        : null,
      endDate: filtersKey.avilapility.end
        ? dayjs(filtersKey.avilapility.end).format("ddd, MMM DD, YYYY")
        : null,
      location: filtersKey.location,
    };
    dispatch(setFilterKeys(finalFilter));
  };
  // reset filter key
  const clearFilter = () => {
    setFiltersKey({
      price: [0, 10000],
      avilapility: {
        start: null,
        end: null,
      },
      condition: "All",
      location: null,
    });
    // clear filter
    dispatch(setFilterKeys({
      priceMin: null,
      priceMax: null,
      condition: null,
      startDate: null,
      endDate: null,
      location: null,
      name: null,
    }));
    // clear active category
    dispatch(setActiveCategory(null));
    // clear search filter
    dispatch(setSearchByName(null));
  };

  const handleLocationCheckboxChange = (event) => {
    if (event.target.checked) {
      // Get user's current location using Geolocation API
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          handleFieldChange("location", { lat: latitude, lng: longitude });
        },

      );
    } else {

      handleFieldChange("location", null);
    }
  };
  const CustomDatePicker = styled(DatePicker)({
    backgroundColor: "white",
    color: "secondary.main",
    borderRadius: "10px", // To remove round shape
    '& .MuiOutlinedInput-root': { // To remove outline
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& .MuiFormLabel-root': {
      color: 'black', // To change label color
    },

  });

  return (
    <Stack spacing={2} sx={{ py: 2, }}>
      <Box sx={{ pl: 2 }}>
        <Typography variant="h4" component="div" sx={{ pb: 1, color: "primary.main", fontSize: ["25px", "35px"] }}>
          Price
        </Typography>
        <Slider
          getAriaLabel={() => "Range Price"}
          value={filtersKey.price}
          onChange={(event, newValue) => handleFieldChange("price", newValue)}
          valueLabelDisplay="auto"
          max={10000}
          min={0}
          step={10}
          sx={{ width: "100%" }}
        />
        <Stack direction="row" justifyContent="space-between" sx={{ py: 1, color: "secondary.main", fontWeight: "bolder" }}>
          <Typography variant="caption" component="div">
            Min Price: {filtersKey.price[0]}
          </Typography>
          <Typography variant="caption" component="div">
            Max Price: {filtersKey.price[1]}
          </Typography>

        </Stack>
      </Box>

      <Box sx={{ py: 3 }}>
        <Typography variant="h4" component="div" sx={{ pb: 3, color: "primary.main", fontSize: ["25px", "35px"] }}>
          Availability
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={2}>
            <CustomDatePicker
              label="Start date"
              value={filtersKey.avilapility.start}
              minDate={dayjs(new Date())}
              onChange={(newValue) =>
                handleFieldChange("avilapility", {
                  ...filtersKey.avilapility,
                  start: newValue ? dayjs(newValue) : null,
                })
              }
              slotProps={{ // actions
                actionBar: {
                  actions: ["accept", "cancel"],
                },
              }}
              closeOnSelect={false}
            />
            <CustomDatePicker
              label="End date"
              value={filtersKey.avilapility.end}
              minDate={dayjs(new Date())}
              onChange={(newValue) =>
                handleFieldChange("avilapility", {
                  ...filtersKey.avilapility,
                  end: newValue ? dayjs(newValue) : null,
                })
              }
              slotProps={{ // actions
                actionBar: {
                  actions: ["accept", "cancel"],
                },
              }}
              closeOnSelect={false}
            />
            <Typography variant="caption" sx={{ color: "red" }}>
              {filtersKey.avilapility.start &&
                filtersKey.avilapility.end &&
                filtersKey.avilapility.start > filtersKey.avilapility.end &&
                "End date must be after start date"}
            </Typography>
          </Stack>
        </LocalizationProvider>

      </Box>

      <Box>
        <Typography variant="h4" component="div" sx={{ pb: 3, color: "primary.main", fontSize: ["25px", "35px"] }}>
          Condition
        </Typography>
        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={filtersKey.condition}
            onChange={(event) =>
              handleFieldChange("condition", event.target.value)
            }
            sx={{ pb: 2 }}
          >
            <FormControlLabel value="All" control={<Radio sx={{ color: "secondary.main" }} />} label="All" />
            <FormControlLabel value="New" control={<Radio sx={{ color: "secondary.main" }} />} label="Used Item" />
            <FormControlLabel value="Used" control={<Radio sx={{ color: "secondary.main" }} />} label="New Items" />
          </RadioGroup>

        </FormControl>
      </Box>
      <Box>
        <Typography variant="h4" component="div" sx={{ pb: 3, color: "primary.main", fontSize: ["25px", "35px"] }}>
          Location
        </Typography>

        <Maps location={filtersKey.location} />
        <Stack spacing={2} direction="row" sx={{ alignItems: "center" }}>
          <Checkbox
            name="location"
            checked={Boolean(filtersKey.location)}
            onChange={handleLocationCheckboxChange}
            aria-label="location checkbox"
            id="locationCheckbox"
            sx={{ color: "secondary.main", }}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon />}
          />
          <InputLabel htmlFor="locationCheckbox" sx={{ color: "secondary.main" }}>
            Use my current location
          </InputLabel>
        </Stack>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={updateFilterKey}
          sx={{ borderRadius: "10px", py: 2 }}
        >
          Apply Filter
        </Button>

        <Button variant="outlined" color="primary" fullWidth onClick={clearFilter} sx={{ my: 2, borderRadius: "10px", py: 2 }}>
          Clear Filter
        </Button>
      </Box>
    </Stack>
  );
};



