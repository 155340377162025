import { Box, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material'
import { Field } from 'formik'
import { styled } from "@mui/material/styles"
import { useEffect, useState } from 'react';
import axiosInstance from '../../axios/axiosInstance';

const CustomTextFiled = styled(TextField)({
  color: "secondary.main",
  '& .MuiOutlinedInput-root': { // To remove outline
    backgroundColor: "white",
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
      borderRadius: "20px", // To remove round shape
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiFormLabel-root': {
    color: 'black', // To change label color
  },
});

const InputFiled = ({ setFieldValue, errors, touched, values }) => {
  const [categories, setCategories] = useState([]); 
  const time = ["Day", "Week", "Month", "Year"];
      // get all categories from server
      useEffect(() => {
        axiosInstance.get("/products/categories").then((res) => {
         setCategories(res.data);
        }).catch(() => {
         setCategories([]);
        });
       
       }, []);
  
  return (
    <Stack spacing={3}>
      {/* select catigory  */}
      <Box>
        <Typography variant="h6" sx={{ mb: 2, color: "primary.main",fontFamily:"Poppins-Bold" }}>
          Select Category
        </Typography>
        <FormControl fullWidth error={touched.category && Boolean(errors.category)}>
          <InputLabel htmlFor="category" sx={{ color: "secondary.main" }}>Category</InputLabel>
          <Field
            as={Select}
            fullWidth
            name="category"
            value={values.category}
            labelId="category-label"
            onChange={(event) =>
              setFieldValue("category", event.target.value)
            }
            sx={{
              color: "secondary.main", backgroundColor: "rgb(247,247,247)", borderRadius: "10px", ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
            }}
          >

            {categories ? categories.map((category) => (
              <MenuItem key={category._id} value={category._id} >
                {category.name}
              </MenuItem>
            )) : null}
          </Field>
          {touched.category && errors.category && (
            <FormHelperText>{errors.category}</FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box sx={{ backgroundColor: "rgb(247,247,247)", p: 3, borderRadius: "10px" }}>
        <Typography variant="h6" sx={{ my: 2, color: "primary.main",fontFamily:"Poppins-Bold" }} >
          Product Name
        </Typography>
        <Field
          as={CustomTextFiled}
          fullWidth
          name="name"
          placeholder="Product Name"
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}

        />
      </Box>
      <Box sx={{ backgroundColor: "rgb(247,247,247)", p: 3, borderRadius: "10px" }}>
        <Typography variant="h6" sx={{ my: 2, color: "primary.main",fontFamily:"Poppins-Bold"}} >
          Price
        </Typography>
        <Stack spacing={2} direction="row">
          <Field
            as={CustomTextFiled}
            fullWidth
            name="price"
            label="Price"
            error={touched.price && Boolean(errors.price)}
            helperText={touched.price && errors.price}
          />


          <FormControl
            fullWidth
            error={touched.per && Boolean(errors.per)}
          >
            <InputLabel htmlFor="Per" sx={{ color: "secondary.main" }}>Per</InputLabel>
            <Field
              as={Select}
              id="Per"
              name="per"
              label="Per"
              value={values.per}
              fullWidth
              onChange={(event) =>
                setFieldValue("per", event.target.value)
              }
              sx={{
                color: "secondary.main", backgroundColor: "white", borderRadius: "10px",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              }}
            >
              {time.map((time) => (
                <MenuItem key={time} value={time}>
                  {time}
                </MenuItem>
              ))}
            </Field>
            {touched.per && errors.per && (
              <FormHelperText>{errors.per}</FormHelperText>
            )}
          </FormControl>

        </Stack>
      </Box>

      <Box sx={{ backgroundColor: "rgb(247,247,247)", p: 3, borderRadius: "10px" }}>
        <Typography variant="h6" sx={{ my: 2, color: "primary.main",fontFamily:"Poppins-Bold" }} >
          Description
        </Typography>
        <Field
          as={CustomTextFiled}
          fullWidth
          multiline
          rows={4}
          name="description"
          label="Description"
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
        />

      </Box>
      <Box sx={{ backgroundColor: "rgb(247,247,247)", p: 3, borderRadius: "10px" }}>
        <Typography variant='h6' sx={{ my: 2, color: "primary.main",fontFamily:"Poppins-Bold" }} >
          Condition
        </Typography>
        <Stack spacing={2}>
          <FormControl
            fullWidth
            error={touched.condition && Boolean(errors.condition)}
          >

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={values.condition}
              onChange={(event) =>
                setFieldValue("condition", event.target.value)
              }
              sx={{ pb: 2 }}
            >

              <FormControlLabel value="New" control={<Radio sx={{ color: "secondary.main" }} />} label="Used Item" />
              <FormControlLabel value="Used" control={<Radio sx={{ color: "secondary.main" }} />} label="New Items" />
            </RadioGroup>
            {touched.condition && errors.condition && (
              <FormHelperText>{errors.condition}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      </Box>


    </Stack>
  )
}

export default InputFiled