import { Paper } from "@mui/material";
import faceBookLoginIcon from "../../../assets/images/facebookloginIcon.webp";
const API_URL = import.meta.env.VITE_VERCEL_API_URL;
const FacebookLoginButton = () => {
  const handleFacebookLogin = () => {
    window.location.href =
      `${API_URL}/user/auth/facebook`;
  };

  return (
    <Paper
      onClick={handleFacebookLogin}
      style={{
        textTransform: "capitalize",
        width: "content-fit",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor:"white",
        alignItems: "center",
        borderRadius: "5px",
        padding: "5px 10px 5px 10px",
        border: "none",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      Facebook
     <img src={faceBookLoginIcon} alt="facebook login icon"  loading="lazy" style={{width:"30px",height:"30px",marginLeft:"5px"}}/>
    </Paper>
  );
};

export default FacebookLoginButton;
