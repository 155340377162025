import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Avatar, Box, Paper, Stack, TextField, Typography } from '@mui/material';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { fetchUser, getUser } from "../../redux/slices/AuthUser";
import { useState } from "react";
import imageCompression from 'browser-image-compression'
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import axiosInstance from "../../axios/axiosInstance";
import CreateIcon from '@mui/icons-material/Create';
import { Link } from "react-router-dom";
export const UserAvatar = () => {
  const authUser = useSelector(getUser);
  const [successUpload, setSuccessUpload] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }))
  };

  // handle image change
  const handleChangeImage = async (e) => {
    setUploading(true);
    const file = e.target.files[0];
    // validate file type if not image return nothing
    if (!file.type.startsWith("image/")) {
      setSuccessUpload(false);
      setUploading(false);
      setAlertDetailsToRedux("error", "Please choose correct type file");
      return;
    }
    try {
      // get presigned url from server
      const response = await axiosInstance.post("/user/upload-image-url", {
        key: file.name,
        contentType: file.type

      });
      const presignedUrl = response.data.presignedUrl;
      const imageUrl = response.data.imgUrl;
      setUploadedImage(imageUrl);
      // remove Authorization header to upload image to s3
      // compress image before uploading
      const options = {
        maxSizeMB: 1,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(file, options).then(compressedFile => {
        return compressedFile;
      })
      const uploaded = await axios.put(presignedUrl, compressedFile, {
        headers: {
          'Content-Type': file.type
        }
      });

      if (uploaded.status === 200) {
        setSuccessUpload(true);
        setUploading(false);
        setAlertDetailsToRedux("success", "Image uploaded successfully");

      }
      // update user profile picture
      if (uploaded.status === 200) {
        axiosInstance.put(`/user/${authUser?.id}`, {
          profilePicture: imageUrl
        }).then(() => {

          // fetch new user data after updating profile picture
          dispatch(fetchUser(authUser?.id));

        })
      }

    } catch (_) {/**/ }
  };

  return (
    <>

      <Stack
        sx={{ alignItems: "center", flexDirection: ["column", "column", "row"], gap: "10px" }}
      >
        <Box>
          <Paper
            sx={{
              borderRadius: "50%",
              width: "100px",
              height: "100px",
              position: "relative",
            }}
            elevation={5}
          >
            {uploading &&

              <Box sx={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(0,0,0,0.5)", borderRadius: "50%", zIndex: "3" }}>

                <Typography variant="bo" sx={{ color: "white" }}>Uploading...</Typography>
              </Box>

            }
            <Avatar
              sx={{ textDecoration: "none", width: "100px", height: "100px" }}
            >
              {authUser?.user?.profilePicture ? (
                <img
                  src={successUpload ? uploadedImage : authUser?.user?.profilePicture}
                  alt={authUser?.user?.username || "user"}
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                  loading="lazy"
                />
              ) : (
                authUser?.user?.username? authUser?.user?.username[0].toUpperCase() : null
              )}
            </Avatar>
            <Box sx={{ position: "absolute", bottom: "11px", right: "15px" }}>
              <TextField
                type="file"
                variant="outlined"
                size="small"
                sx={{ display: "none" }}
                id="avatar"
                onChange={handleChangeImage}
              />
              <label htmlFor="avatar">
                <Box
                  sx={{
                    backgroundColor: "primary.main",
                    padding: "2px",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "2px solid white",
                    cursor: "pointer",
                  }}
                >
                  <AddAPhotoIcon sx={{ fontSize: "15px", color: "white" }} />
                </Box>
              </label>
            </Box>
          </Paper>
        </Box>

        <Stack spacing={2} direction="row">
          <Box sx={{ flexGrow: 1 }}>
            <Stack spacing={1} >
              <Typography variant="h5" sx={{ flexGrow: [0, 1, 1], fontSize: ["18px", "20px"], fontWeight: "bold" }}>
                {authUser?.user?.username || "User"}
              </Typography>
              <Typography variant="h5" sx={{
                flexGrow: [0, 1, 1],
                fontSize: ["16px", "18px", "20px"],
              }}>
                {
                  authUser?.user?.email || authUser?.user?.phoneNumber
                }
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ backgroundColor: "primary.main", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", width: "30px", height: "30px", }} as={Link} to={`/account-info/${authUser?.id}`}>
            <CreateIcon sx={{ cursor: "pointer", color: "white", textDecoration: "none" }} />
          </Box>
        </Stack>
      </Stack>
    </>
  )
}
