import { Stack, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { clearUser } from '../../redux/slices/AuthUser'; 
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
// handle logout
export const LogOut = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(clearUser());
        // clear the token from local storage
        localStorage.removeItem("token");
        localStorage.removeItem("productSaved");
        navigate("/");
    };
    return (
        <Stack direction="row" justifyContent="space-between" sx={{ backgroundColor: "rgb(247,247,247)", mt: 5, p: 2, cursor: "pointer" }} onClick={handleLogout}>
            <Typography variant="body1" align="center" sx={{ color: "primary.main", fontWeight: "bold" }}>
                Log Out
            </Typography>
            <KeyboardArrowRightIcon sx={{ color: "primary.main", fontSize: "30px", cursor: "pointer" }} />

        </Stack>
    )
}
