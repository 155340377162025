import { Box, Button, FormHelperText, FormLabel, Stack, Typography } from '@mui/material'
import { FieldArray, } from 'formik'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import uploadImg from "../../assets/images/uploadImg.webp"
import DeleteIcon from '@mui/icons-material/Delete';
const UploadImages = ({ setFieldValue, errors, touched, values, handleImageChange, imageLoading }) => {

  // handle delete main image
  const handleDeleteMainImage = () => {
    setFieldValue("pictures", values.pictures.filter((_, index) => index !== 0))
  }
  return (
    <>
      <Box sx={{ backgroundColor: "rgb(247,247,247)", borderRadius: "20px ", p: 2 }}>
        <Stack spacing={2} sx={{ justifyContent: "center", alignItems: "center", position: "relative" }} >
          <Box >
            <input
              type="file"
              id="mainImage"
              onChange={(event) =>
                handleImageChange(event, 0, setFieldValue)
              }
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                opacity: 0
              }}
            />

            {
              values.pictures[0] ? null : (
                <label htmlFor="mainImage" >
                  {(imageLoading[0]) ? <Box sx={{ height: "100px",display:"flex", justifyContent: "center", alignItems: "center" }} > Loading...</Box> :
                    (
                      <>
                        <Box>
                          <img src={uploadImg} alt="upload" style={{ width: "100%", height: "50px", objectFit: "contain", }} loading='lazy' />
                        </Box>
                        <Typography variant="body1" gutterBottom component="div" sx={{ fontSize: ["8px", "16px"], mt: 3 }}>
                          Drag and drop a file here
                        </Typography>
                      </>
                    )
                  }
                </label>
              )
            }

            {
              values.pictures[0] &&
              <Box sx={{ width: "100%" }} >
                <img src={values.pictures[0]} alt="main" style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }} />
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDeleteMainImage}
                  sx={{ position: "absolute", top: "5px", right: "5px", width: "30px", height: "30px", justifyContent: "center", alignItems: "center" }}
                >
                  <DeleteIcon />
                </Button>
              </Box>
            }

          </Box>

        </Stack>
      </Box>
      <Box>
        <Typography variant="h6" sx={{ mt: 2, color: "primary.main", fontFamily: "Poppins-Bold" }}>
          Upload More
        </Typography>
        <FieldArray name="pictures">
          {({ push, remove }) => (
            <div>
              <Stack direction="row" sx={{ mt: 2, flexWrap: "wrap", }} spacing={1}  >
                {values.pictures.slice(1).map((image, index) => (
                  <Stack key={index} spacing={2} sx={{ mx: "3px" }}>
                    <Box>
                      <input
                        type="file"
                        id={`pictures.${index + 1}`}
                        onChange={(event) =>
                          handleImageChange(event, index + 1, setFieldValue)
                        }
                        style={{
                          width: "0px",
                          height: "0px",
                          overflow: "hidden",
                        }}
                      />
                      <FormLabel htmlFor={`pictures.${index + 1}`}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px dashed #ccc",
                            borderRadius: 4,
                            cursor: "pointer",
                            width: "120px",
                            height: "100px",
                            overflow: "hidden",
                          }}
                        >
                          {imageLoading[index + 1] ? 'Loading...' : image ? <img src={image} alt='image' style={{ width: "100%", height: "100%", objectFit: "cover" }} /> : "Upload"}
                        </Box>
                      </FormLabel>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", }}>
                      <Button
                        variant="outlined"
                        sx={{ color: "primary.main", width: "85px" }}
                        onClick={() => remove(index + 1)}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Stack>
                ))}
                {values.pictures.length < 6 && (
                  <Stack spacing={2} pt={3}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px dashed #ccc",
                        borderRadius: 4,
                        cursor: "pointer",
                        width: "85px",
                        height: "100px",
                      }}
                      onClick={() => push(null)}
                    >
                      <AddPhotoAlternateIcon />
                    </Box>
                  </Stack>
                )}
              </Stack>
              {touched.pictures && errors.pictures && (
                <FormHelperText sx={{ color: "red", pt: 1 }}>
                  {errors.pictures}
                </FormHelperText>
              )}
            </div>
          )}
        </FieldArray>
      </Box>
    </>
  )
}

export default UploadImages;

